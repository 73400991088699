import * as React from 'react';
import './forgot.scss';
import { useHistory, Redirect } from 'react-router-dom';
import Lock from '../auth/lock';
import { useSelector } from 'react-redux';

declare const xhook: any;

const Forgot: React.FC<any> = () => {
  const history = useHistory();
  const {isLoggedIn}:any = useSelector<any>((state:any) => state.user);
  React.useEffect(() => {
    if (xhook) {
      xhook.after(function (request: any, response: any) {
        if (
          response.text ===
          "We've just sent you an email to reset your password."
        ) {
          history.push('/landcheck-login');
        }
      });
    }
  }, []);

  return (
    <>
      <div id="forget_page">
        <div className="wrap">
          <div className="innerwrap">
            <div className="login-inner">
              <h2>Forgot password </h2>
              <p> Enter your email and we’ll send you a password reset link.</p>

              <div className="loginfeild">
                <div className="loginemail">
                  {!isLoggedIn ? <Lock /> : <Redirect to="/" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
