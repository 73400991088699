import * as React from 'react';
import './index.scss';
import RiskHomeImage from '../../../assets/images/risk-home.svg';
import { downloadSampleReport } from '../../../shared/utils/services/hooks/reports';
import { generateFile } from '../../../shared/utils/helper';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

const notifySampleDownLoadError = () =>
  toast.error(
    'Something went wrong while downloading sample report. Please try again later!'
  );

const RiskHome: React.FC<any> = () => {
  const [downloadSample, setDownloadSample] = React.useState(false);

  const {
    data: sampleReportData,
    isLoading: downloadSampleLoading,
    isError: downloadSampleError,
  } = downloadSampleReport(downloadSample);

  React.useEffect(() => {
    if (sampleReportData) {
      generateFile(sampleReportData.url, sampleReportData.fileName);
      setDownloadSample(false);
    } else if (downloadSampleError) {
      setDownloadSample(false);
      notifySampleDownLoadError();
    }
  }, [sampleReportData, downloadSampleLoading, downloadSampleError]);
  return (
    <div id="risk-home">
      <div className="wrap">
        <div className="row">
          {/* Left content */}
          <div className="half-width">
            <h2>
              When dealing with property, you need information you can trust.
            </h2>
            <p>
              That’s why New Zealand’s leading Natural Hazard experts from&nbsp;
              <a href="https://www.tonkintaylor.co.nz/" target="_blank">
                Tonkin + Taylor
              </a>
              &nbsp; developed Landcheck. Landcheck applies an algorithm to
              open-sourced council data to generate an easy-to-understand impact
              rating for 10 natural hazards. Landcheck is the only product
              presenting hazards in this way.
            </p>
            <Button
              className="button-primary ml-10"
              onClick={() => setDownloadSample(true)}
            >
              View an example report
            </Button>
          </div>
          <div className="half-width-img">
            <img src={RiskHomeImage} alt="Risk" />
            <div className="mobilewhite-bg"></div>
          </div>
        </div>
      </div>
      <div className="white-bg"></div>
    </div>
  );
};

export default RiskHome;
