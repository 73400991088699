import './index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import RiskTabItem from '../riskTab';
import { getRiskType } from '../../../../../shared/utils/services/hooks/riskType';
import { postRisks } from '../../../../../shared/utils/services/hooks/postRisks';
import Tags from '../../ui/Tags';
import Button from '../../ui/Button';
import VersionDropdown from '../../ui/VersionDropdown';
import Input from '../../../../../shared/ui/components/input';

type Props = {
  className?: string;
  heading: string;
  regionId: string;
};

const notifySuccessRisk = () =>
  toast.success('Risk Management data updated successfully!');
const notifyErrorRisk = () =>
  toast.error('Something went wrong. Please try again later!');

const TabContent: React.FC<Props> = ({ heading, regionId }) => {

  const [tags, setTags] = useState();
  const [severeHazard, setSevereHazard] = useState(false);

  const [data, setData] = useState({
    id: '',
    riskName: '',
    riskDisplayName: '',
    regionId: '',
    version: '',
    cityRisks: tags,
    isSevereHazard: severeHazard,
    riskRatingRanges: [
      {
        actionToTake: '',
        actionToTakeDetails: '',
        financialImpact: '',
        financialImpactDetails: '',
        id: '',
        impact: '',
        likelihood: '',
        maxValue: 10,
        minValue: 0,
        name: '',
        permanentFixes: '',
        permanentFixesDetails: '',
        whatToDoAboutThis: '',
        whyThisRiskRating: '',
        attributionText: '',
        impactRating: 0
      },
    ],
    mapEndpoint: 'NA',
  });
  const {
    data: riskTypeData,
    isLoading: riskTypeLoading,
    mutate: getRiskTypes
  } = getRiskType(heading, regionId);

  const {
    isLoading: postRiskLoading,
    isSuccess: postRiskSuccess,
    isError: postRiskError,
    mutate: postMut
  } = postRisks(data);



  const handleSubmit = () => {
    setData({
      id: riskTypeData?.id,
      riskName: heading,
      riskDisplayName: heading,
      regionId: regionId,
      version: 'v1',
      cityRisks: tags,
      riskRatingRanges: getRatingRisk(),
      mapEndpoint: 'NA',
      isSevereHazard: severeHazard
    });
    setTimeout(() => {
      postMut()
    }, 200)
  };


  const getRatingRisk = (): any => {
    if (riskTabData && riskTabData.length > 0) {
      return riskTabData.map((i: any) => {
        const obj: any = { id: i.id, maxValue: i.maxValue, minValue: i.minValue, name: i.name, whatToDoAboutThis: 'NA', }
        i.questions.map((j: any) => {
          if (j.whyThisRiskRating) {
            obj.whyThisRiskRating = j.whyThisRiskRating
          }
          if (j.actionToTake) {
            obj.actionToTake = j.actionToTake
          }
          if (j.actionToTakeDetails) {
            obj.actionToTakeDetails = j.actionToTakeDetails
          }
          if (j.financialImpact) {
            obj.financialImpact = j.financialImpact
          }
          if (j.financialImpactDetails) {
            obj.financialImpactDetails = j.financialImpactDetails
          }
          if (j.impact) {
            obj.impact = j.impact
          }
          if (j.likelihood) {
            obj.likelihood = j.likelihood
          }

          if (j.permanentFixes) {
            obj.permanentFixes = j.permanentFixes
          }
          if (j.permanentFixesDetails) {
            obj.permanentFixesDetails = j.permanentFixesDetails
          }
          if (j.attributionText) {
            obj.attributionText = j.attributionText
          }
          if (j.impactRating) {
            obj.impactRating = Number(j.impactRating)
          }
        })
        return obj
      })
    }
  }

  useEffect(() => {
    if (postRiskSuccess) {
      notifySuccessRisk();
      getRiskTypes()
    } else if (postRiskError) {
      notifyErrorRisk();
    }
  }, [postRiskSuccess, postRiskError]);

  useEffect(() => { getRiskTypes() }, [])

  useEffect(() => {
    setSevereHazard(riskTypeData?.isSevereHazard)
  }, riskTypeData)

  const [riskTabData, setRiskTabData] = useState([])

  const getRiskTabData = (data: any) => {
    setRiskTabData(data);
  }
  const getTags = (data: any) => {
    setTags(data);
  }

  return (
    <>
      {riskTypeLoading ? (
        <>
          <Loader type="Puff" color="#2f2f60" height={50} width={50} />
        </>
      ) : (
        <div id="tab-content-wrapper">
          <div className="title-wrapper">
            <span className="heading-title">{heading}</span>
            <div className="version-wrapper">
              <VersionDropdown />
            </div>
          </div>
          { riskTypeData?.riskName !== 'Summary' &&
            <div className="tags-main">
              <Tags getTags={getTags} />
              <div>
                <span className="risk-taking-title">Severe hazard</span>
                <div >
                  <input
                    className="hazard-checkbox"
                    checked={severeHazard}
                    name="isSevereHazard"
                    onChange={(e) => setSevereHazard(e.target.checked)}
                    placeholder=""
                    type='checkbox'
                  />
                </div>
              </div>
              <div className="risk-tab-wrapper">
                <RiskTabItem getRiskTabData={getRiskTabData} riskTypeData={riskTypeData} />
                <Button handleSubmit={handleSubmit} />
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default TabContent;


