import { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import './index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'leaflet/dist/leaflet.css';
import '../../../index.scss';
import {
  LayersControl,
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
} from 'react-leaflet';
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Modal } from 'react-bootstrap';
import { config } from '../../../config';
import { suburbLatLong, dropDownData } from '../../../shared/utils/constants';
import { GetMapPointRisk } from '../../../shared/utils/services/hooks/home';
import Loader from '../../../shared/ui/components/loader';

const MapAreaHome = () => {
  const [position, setPosition] = useState([
    -36.840338525613234, 174.7565252806435,
  ]);
  const [map, setMap] = useState(null);
  const [show, setShow] = useState(true);
  const [containerPoint, setContainerPoint] = useState(null);

  const [minWidth, setMinWidth] = useState(1);
  const [minHeight, setMinHeight] = useState(1);

  const geoJsonRef = useRef();
  const [geoJSON, setGeoJSON] = useState(null);
  const [dropDownValue, setDropDownValue] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);

  const mapAreaRef = useRef(null);
  const mapConRef = useRef(null);
  const popUpRef = useRef(null);

  useEffect(() => {
    if (mapConRef && mapAreaRef) {
      const mapAreaWidth = mapAreaRef.current.clientWidth;
      const mapConWidth = mapConRef.current.clientWidth;
      const mapAreaHeight = mapAreaRef.current.clientHeight;
      const mapConHeight = mapConRef.current.clientHeight;
      setMinWidth((mapAreaWidth - mapConWidth) / 2);
      setMinHeight(mapAreaHeight - mapConHeight - 50);
    }
  }, [mapAreaRef, mapConRef]);
  const fetchSuburbData = (x) => {
    fetch(
      config.dataLintzServiceURL +
      `?key=${config.serviceAPIKey}&layer=${config.keyForMapArea}&x=${x.lng}&y=${x.lat}&max_results=100&radius=100000&geometry=true&with_field_names=true`
    )
      .then((response) => response.json())
      .then((response) => {
        var features = response?.vectorQuery?.layers[`${config.keyForMapArea}`].features.filter(function (i,n){
          const suburbName = getDistrictName(i);
          const showTooltip = suburbName !== "Hauraki Gulf" 
            && suburbName !== "Rangitoto Island" 
            && suburbName !== "Hobson Bay" 
            && suburbName !== "Mechanics Bay" 
            && suburbName !== "Lake Pupuke" 
            && suburbName !== "Pollen Island" 
            && suburbName !== "Wesley" 
            && suburbName !== "Westhaven" 
            && suburbName !== "Manukau Harbour"
            && suburbName !== "Pegasus Bay"
            && suburbName !== "Lyttelton Harbour / Whakaraupo"
            && suburbName !== "Estuary Of The Heathcote And Avon Rivers / Ihutai"
            && suburbName !== "Moncks Bay" ? true : false;
          return showTooltip;
        });
        setGeoJSON(features);
        // console.log(
        //   response?.vectorQuery?.layers[`${config.keyForMapArea}`].features
        // );
        setShow(true);
      });
  };

  useEffect(() => {
    if (geoJSON === null) {
      fetchData(dropDownValue);
    }
  }, [map, dropDownValue]);

  const fetchData = (dropDownValue) => {
    if (dropDownValue === 'Auckland') {
      fetchSuburbData(suburbLatLong.auckland);
      setPosition([suburbLatLong.auckland.lat, suburbLatLong.auckland.lng]);
    } else if (dropDownValue === 'Wellington') {
      fetchSuburbData(suburbLatLong.wellington);
      setPosition([suburbLatLong.wellington.lat, suburbLatLong.wellington.lng]);
    } else if (dropDownValue === 'Christchurch') {
      fetchSuburbData(suburbLatLong.christchurch);
      setPosition([
        suburbLatLong.christchurch.lat,
        suburbLatLong.christchurch.lng,
      ]);
    } else {
      fetchSuburbData(suburbLatLong.auckland);
    }
  };

  const handleEachFeature = (feature, layer) => {
    const districtName = getDistrictName(feature);

    const popupContent = ReactDOMServer.renderToString(
      <span>{districtName}</span>
    );

    layer.bindTooltip(popupContent, {
      opacity: 1,
      direction: 'center',
      backgroundColor: 'red',
    });

    const showTooltip = districtName !== "Hauraki Gulf" 
      && districtName !== "Rangitoto Island" 
      && districtName !== "Hobson Bay" 
      && districtName !== "Mechanics Bay" 
      && districtName !== "Lake Pupuke" 
      && districtName !== "Pollen Island" 
      && districtName !== "Wesley" 
      && districtName !== "Westhaven" 
      && districtName !== "Manukau Harbour" ? true : false;

    if (!showTooltip)
      return;

    layer.on('click', async function (e) {
      setCurrentFeature(layer.feature);
      const conPoint = e.containerPoint;
      setContainerPoint(conPoint);
    });
  };

  const onChangeDropDown = (e) => {
    dropDownValue !== e
      ? (setShow(false), setMap(null), setGeoJSON(null))
      : setShow(true);
    const newDistrict = e;
    setDropDownValue(newDistrict);

    if (!newDistrict) return;
  };

  // const options = {
  //   position: 'bottomright', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
  //   title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
  //   titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
  //   content: null, // change the content of the button, can be HTML, default null
  //   forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
  //   forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
  //   fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
  // };

  const maxPopwidth =
    (popUpRef && popUpRef.current && (popUpRef.current.clientWidth / 2) - 10) || 0;

  useEffect(() => {
    document.addEventListener("click", hideMenu)
    return () => document.removeEventListener("click", hideMenu)
  }, [])

  const hideMenu = (event) => {
    const isClickInsideOfElement = mapConRef && mapConRef.current && mapConRef.current.contains(event.target)
    const isClickInsideOfPopup = popUpRef && popUpRef.current && popUpRef.current.contains(event.target)
    if (!isClickInsideOfElement && !isClickInsideOfPopup) setContainerPoint(null)
  }
  return (
    <div id="map-area" style={{ position: 'relative' }} ref={mapAreaRef}>
      {containerPoint && (
        <div
          className="riskmappopover"
          ref={popUpRef}
          style={{
            left: containerPoint.x + minWidth - maxPopwidth,
            top: containerPoint.y + minHeight,
          }}
        >
          <MapRiskComponent
            data={currentFeature}
            containerPoint={containerPoint}
          />
        </div>
      )}
      <div className="wrap-600">
        <div className="row">
          {/* Left content */}
          <div className="full-width">
            <div className="tyu">Don’t be unaware</div>
            <h2>Know your suburb</h2>
            <p>
            Know what to look for when considering a property. The map below shows the key natural hazard risks for each suburb. 
            </p>
          </div>
        </div>
      </div>
      <div className="wrap-1000">
        <div className="row">
          <div className="full-width" ref={mapConRef}>
            {show ? (
              <MapContainer
                className="map-container"
                center={position}
                zoom={13}
                zoomControl={false}
                scrollWheelZoom={false}
                whenCreated={setMap}
              >
                <div className="map-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {dropDownValue === null ? dropDownData[0] : dropDownValue}
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        aria-hidden="true"
                        style={{ fontSize: 16, color: '#5C5CE5' }}
                      ></FontAwesomeIcon>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-item">
                      {dropDownData.map((item, index) => (
                        <Dropdown.Item
                          key={`suburb-${index}`}
                          eventKey={item}
                          onSelect={onChangeDropDown}
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {geoJSON && (
                  <GeoJSON
                    style={{
                      fillColor: '#cdcdff',
                      fillOpacity: 0.9,
                      color: '#5c5ce5',
                      weight: 2,
                    }}
                    data={geoJSON}
                    onEachFeature={handleEachFeature}
                    ref={geoJsonRef}
                  />
                )}
                <ZoomControl position="bottomleft" />
                {/* <Fullscreen
                  eventHandlers={{
                    enterFullscreen: (event) =>
                      console.log('entered fullscreen', event),
                    exitFullscreen: (event) =>
                      console.log('exited fullscreen', event),
                  }}
                  {...options}
                /> */}
                <TileLayer
                  attribution='&copy; <a href="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">Satellite Imagery</a> contributors'
                  url="https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=d01edq7070qb1y31gakkyabawdk"
                />
                <LayersControl.BaseLayer name="Esri.SatelliteImagery">
                  <TileLayer
                    attribution='&copy; <a href="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">World Imagery</a> contributors'
                    url="https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=d01edq7070qb1y31gakkyabawdk"
                  />
                </LayersControl.BaseLayer>
              </MapContainer>
            ) : (
              <div className="map-loader">
                <Loader type="Puff" color="#fff" height={50} width={50} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapAreaHome;

const MapRiskComponent = ({ data, containerPoint }) => {
  const suburbName = getDistrictName(data);

  let cityName = data?.properties?.major_name;
  if (cityName == 'Prebbleton' || cityName == 'Lyttelton' || cityName == 'Lansdowne' || suburbName == 'Tai Tapu' 
      || suburbName == 'Governors Bay' || suburbName == 'Te Rapaki o Te Rakiwhakaputa' ||  suburbName == 'Clarkville')
    cityName = 'Christchurch';

  const {
    data: mapData,
    isLoading,
    mutate,
  } = GetMapPointRisk(suburbName, cityName);

  const showTooltip = suburbName !== "Hauraki Gulf" 
    && suburbName !== "Rangitoto Island" 
    && suburbName !== "Hobson Bay" 
    && suburbName !== "Mechanics Bay" 
    && suburbName !== "Lake Pupuke" 
    && suburbName !== "Pollen Island" 
    && suburbName !== "Wesley" 
    && suburbName !== "Westhaven" 
    && suburbName !== "Manukau Harbour" ? true : false;

  useEffect(() => {
    mutate();
  }, [containerPoint]);

  return (
    <>
    {showTooltip && (<div className="tooltip">
      <h3 className="tooltip-title">{suburbName}</h3>
      <Loader isLoading={isLoading} />
      {mapData && (
        <>
          <span
            className="tooltip-subtitle1"
            dangerouslySetInnerHTML={{ __html: mapData?.commonRisk?.title }}
          />
          <div className="tooltip-risk-container">
            {mapData?.commonRisk?.risks?.length > 0 &&
              mapData?.commonRisk?.risks?.map((item, index) => (
                <div className="tooltip-risk" key={'commonRisks-risk' + index}>
                  <span className="tooltip-risk-text">
                    <img
                      src={`/images/` + item.icon.toLowerCase()}
                      alt="icon"
                    />
                    {item.name}
                  </span>
                </div>
              ))}
          </div>
          <span
            className="tooltip-subtitle1"
            dangerouslySetInnerHTML={{ __html: mapData?.otherRisk?.title }}
          />
          <div className="tooltip-otherrisk-container">
            {mapData?.otherRisk?.risks?.length > 0 &&
              mapData?.otherRisk?.risks?.map((item, index) => (
                <div className="tooltip-risk" key={'otherRisk-risk' + index}>
                  <span className="tooltip-risk-text">
                    <img
                      src={`/images/` + item.icon.toLowerCase()}
                      alt="icon"
                    />
                    {item.name}
                  </span>
                </div>
              ))}
          </div>
          <div className="tooltip-otherrisk-footer-detail">
            <p>{mapData?.description}</p>
          </div>
        </>
      )}
    </div>)}
    </>
  );
};

const getDistrictName = (data) => {
  return data?.properties?.name;
};
