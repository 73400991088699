import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import Loader from "../../shared/ui/components/loader";
import './style.scss'
import { useDispatch, useSelector } from "react-redux";
import { saveReportLink } from "../../store/actions/common-actions";
import { saveSelectedReport } from "../../store/actions/reports-actions";
import { saveIsAnonymousUser } from "../../store/actions/user-actions";

const WithHeaderReport= () => {
  const history = useHistory()
  const { reportId: urlReportId, authKey } = useParams<any>()
  const { reportLink }: any = useSelector<any>((state: any) => state.common);
  const { token }: any = useSelector<any>((state: any) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token && !authKey) {
      dispatch(saveReportLink(urlReportId))
    }
    else {
      if (authKey) {
        dispatch(saveIsAnonymousUser(true));
      }
      dispatch(saveReportLink(""))
      dispatch(saveSelectedReport({ id: urlReportId, authKey }))
      history.push("/view-report")
    }
  }, [urlReportId])

  useEffect(() => {
    if (reportLink && !authKey) {
      setTimeout(() => { 
        window.location.href = "/landcheck-login"
      }, 100);
    }
  }, [reportLink, authKey])


  return (<div className="minmumHeightBox">
    <div>
      <Loader isLoading={true} />
    </div>
  </div>)

}

export default WithHeaderReport
