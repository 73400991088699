import React, { useEffect, useState } from 'react';
import { getRisks } from '../../../shared/utils/services/hooks/riskType';
import DropDownWrapper from './DropDownWrapper';
import './index.scss';
import TabsMain from './tabs';

const AdminSearchMain: React.FC<any> = () => {
  const [show, setShow] = useState(false);
  const [regionId, setRegionId] = useState('');
  const [cityId, setCityId] = useState('');
  const [version, setVersion] = useState('');
  const { data, isLoading, mutate: getRisksById } = getRisks(regionId)

  const [toggleState, setToggleState] = useState('summary');

  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  const getActiveClass = (index: any, className: any) =>
    toggleState === index ? className : '';



  useEffect(() => {
    getRisksById()
  }, [show])


  return (
    <div>
      <div id="search-wrapper-main">
        <div className="wrap">
          <div className="search-page">
            <div className="row">
              <div className="side-nav md-helf-width">
                <div className="stickysidebar">
                  <div className="tab-container tab-container-list ">
                  
                  {!isLoading && data && data.length && <ul className="tab-list">
                      <li 
                        key={"tab-" + "Summary"}
                        className={`tabs ${getActiveClass('summary', 'active-tabs')}`}
                        onClick={() => toggleTab('summary')}
                      >
                        Summary
                      </li>
                    {data.map((i: any, index: number) => <li
                      key={"tab-" + i}
                      className={`tabs ${getActiveClass(index, 'active-tabs')}`}
                      onClick={() => toggleTab(index)}
                    >
                      {i}
                    </li>)
                    }
                  </ul>
                  }
                </div>
                </div>
              </div>
              <div className="half-width-right">
                <DropDownWrapper
                  setShow={setShow}
                  setRegionId={setRegionId}
                  setCityId={setCityId}
                  setVersion={setVersion}
                  show={show}
                />
                {!isLoading && data && data.length &&
                  <TabsMain regionId={regionId} cityId={cityId} version={version}
                    getActiveClass={getActiveClass}
                    data={data}
                    toggleState={toggleState}
                    isLoading={isLoading} />
                }
              </div>
            </div></div>
        </div>
      </div>

    </div>
  );

};

export default AdminSearchMain;
