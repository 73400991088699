import * as React from 'react';
import PageNotFoundPage from '../../components/page-not-found';
import FlushData from '../FlushData';

const PageNotFound: React.FC<any> = () => {
  return (
    <div>
      <FlushData />
      <PageNotFoundPage />
    </div>
  );
};

export default PageNotFound;
