//Modal.js

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import styles from './modal.module.css';

const CustomModal = ({
  modalStyle,
  children,
  show,
  onClose,
  backdropStyle,
}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);
  return (
    <React.Fragment>
      <div
        ref={modalRef}
        style={backdropStyle}
        className={`${styles.modal__wrap}`}
      >
        <div style={modalStyle} className={styles.modal}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomModal;
