import { combineReducers } from 'redux';
import commonReducer from './common-reducer';
import locationReducer from './location-reducer';
import reportsReducer from './reports-reducer';
import screenReducer from './screen-reducer';
import userReducer from './user-reducer';
import postTerms from './postTermsAndConditions';

const rootReducer = combineReducers({
  location: locationReducer,
  common: commonReducer,
  screen: screenReducer,
  reports: reportsReducer,
  user: userReducer,
  termsAndConditions: postTerms,
});

export default rootReducer;
