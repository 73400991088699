import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReportView from '../../components/report-view-non-logged-in';
import ReportPageLoggedIn from '../../components/view-report-logged-in';
import Loader from '../../shared/ui/components/loader';

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { locationCovered } from '../../shared/utils/constants';
import { getLatLong } from '../../shared/utils/helper';
import { saveLocation } from '../../store/actions/location-action';
import { Location } from '../../shared/utils/types';

const ViewReport: React.FC<any> = () => {
  const [showLoading, setShowLoading] = React.useState(true);
  const [reportType, setReportType] = React.useState('');
  const { selectedReport }: any = useSelector<any>(
    (state: any) => state.reports
  );
  const dispatch = useDispatch();

  //Get report address from query params if present
  const [reportAddress, setReportAddress] = React.useState('');
  const { search } = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const address = searchParams.get('address') ?? '';
    setReportAddress(address);
  }, [search]);

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({});

  const setAddress = async (address: Location) => {
    const addressLatLong = await getLatLong(address.formatted_address);
    dispatch(saveLocation(address, addressLatLong));
  };

  const formatAddress = (pred: any, re: any) => {
    const city =
      re?.address_components[re?.address_components?.length - 3]?.short_name;
    const res = {
      formatted_address: pred.description,
      city: pred.structured_formatting.main_text,
      city1: city,
      country: pred.structured_formatting.secondary_text,
      place_id: pred.place_id,
      terms: pred?.terms ? pred?.terms?.map((term: any) => term.value) : [],
      coveredLocation:
        locationCovered.findIndex((c) => c === city) >= 0 ? true : false,
    };

    setAddress(res);
  };

  React.useEffect(() => {
    if (reportAddress) {
      getPlacePredictions({
        input: reportAddress,
        componentRestrictions: { country: 'nz' },
      });
    }
    if (selectedReport && (selectedReport.id || selectedReport.reportId)) {
      setReportType('myReport');
      setShowLoading(false);
    } else {
      setReportType('none');
      setShowLoading(false);
    }
  }, [selectedReport, reportAddress]);

  React.useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
          fields: ['address_components'],
        },
        (placeDetails) => formatAddress(placePredictions[0], placeDetails)
      );
  }, [placePredictions]);

  if (showLoading) {
    return (
      <div className="minmumHeightBox">
        <Loader title="Loading..." isLoading={true} />
      </div>
    );
  }
  return (
    <div>{reportType === 'none' ? <ReportView /> : <ReportPageLoggedIn />}</div>
  );
};

export default ViewReport;
