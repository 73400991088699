import { useMutation } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const GetMapPointRisk = (suburbName: string, cityName: string) => {
  const adminCallApi = adminUseAxios();
  return useMutation(() =>
    adminCallApi({
      method: 'GET',
      url: `/Risks/suburb/${suburbName}/${cityName}`,
    }).then((data: any) => {
      // debugger;
      return data ? data : {};
    })
  );
};
