import { FC } from "react";
import ReactQuill from "react-quill";
import { formats } from "../../../../common/EditorToolbar";

interface IriskTakingQuestion {
  question: any
}

const RiskTakingQuestion: FC<IriskTakingQuestion> = ({ question }) => {

  return (
    <>
      {question && question.length > 0 && question.map((item: any) => (
        <div className="risk-taking-wrapper" key={item.question} >
          <div className="risk-title-wrapper">
            <span className="risk-taking-title">{item.question}</span>
            {item?.toolbar}
          </div>
          {item.plainText && (
            <div className="risk-taking-field">
              <textarea onChange={item?.handleChange} value={item?.text} />
            </div>
          )
          }
          {item.checkbox && (
            <div className="risk-taking-field">
              <input type="checkbox" onChange={item?.handleChange} value={item?.value} />
            </div>
          )
          }
          {item.number && (
            <div className="risk-taking-field">
              <input type="number" min="0" max="4" onChange={item?.handleChange} value={item?.value} />
            </div>
          )
          }
          {(item.plainText === false) && (
            <ReactQuill
              style={{ height: item.height ? item.height : 100 }}
              theme="snow"
              value={item?.text}
              modules={item?.modules}
              formats={formats}
              onChange={item.handleChange}
            />
          )
          }
          {/* {item?.plainText ? (
            <div className="risk-taking-field">
              <textarea onChange={item?.handleChange} value={item?.text} />
            </div>
          ) : (
            <ReactQuill
              style={{ height: item.height ? item.height : 100 }}
              theme="snow"
              value={item?.text}
              modules={item?.modules}
              formats={formats}
              onChange={item.handleChange}
            />
          )} */}
        </div>
      ))}</>
  )
}

export default RiskTakingQuestion