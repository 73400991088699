import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EditorToolbar.scss';

// Do not remove this, quill-image-resize-module-react throws TypeError: Cannot read property 'imports' of undefined
declare global {
  interface Window {
    Quill?: any;
  }
}
 
window.Quill = Quill;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ImageResize = require('quill-image-resize-module-react').default
Quill.register('modules/imageResize', ImageResize);
const Parchment = Quill.import('parchment');

// Add sizes to whitelist and register them
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['10px', '15px', '18px', '20px', '32px', '54px'];
Quill.register(SizeStyle, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'sans-serif',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];

Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};

export const modules1 = {
  toolbar: {
    container: '#toolbar1',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};

export const modules2 = {
  toolbar: {
    container: '#toolbar2',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};

export const modules3 = {
  toolbar: {
    container: '#toolbar3',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};
export const modules4 = {
  toolbar: {
    container: '#toolbar4',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};
export const modules5 = {
  toolbar: {
    container: '#toolbar5',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};

export const modules6 = {
  toolbar: {
    container: '#toolbar1',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};

export const modules7 = {
  toolbar: {
    container: '#toolbar7',
    handlers: {
      // undo: undoChange,
      // redo: redoChange,
    },
  },
  imageResize: {
    parchment: Parchment,
    modules: ['Resize', 'DisplaySize']
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <QuillToolBarComp />
  </div>
);

export const QuillToolbar6 = () => (
  <div id="toolbar1">
    <QuillToolBarComp />
  </div>
);

export const QuillToolbar2 = () => (
  <div id="toolbar2">
    <QuillToolBarComp />
  </div>
);

export const QuillToolbar3 = () => (
  <div id="toolbar3">
    <QuillToolBarComp />
  </div>
);

export const QuillToolbar4 = () => (
  <div id="toolbar4">
    <QuillToolBarComp />
  </div>
);

export const QuillToolbar5 = () => (
  <div id="toolbar5">
    <QuillToolBarComp />
  </div>
);

export const QuillToolbar7 = () => (
  <div id="toolbar7">
    <QuillToolBarComp />
  </div>
);

export default QuillToolbar;


const QuillToolBarComp = () => <>
  <span className="ql-formats">
    <select className="ql-font">
      <option value="sans-serif" selected>
        Sans Serif
      </option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
    </select>
    <select className="ql-size">
      <option value="10px">10px</option>
      <option value="15px">15px</option>
      <option value="18px" selected>
        18px
      </option>
      <option value="20px">20px</option>
      <option value="32px">32px</option>
      <option value="54px">54px</option>
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />

  </span>
  <button className="ql-align" />
  <button className="ql-background" />
  <button className="ql-color" />
  <button className="ql-link" />
  <button className="ql-list" value="bullet" type="button" />

  <button className="ql-image" />
</>