import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../shared/ui/components/button/index';

import { savePlaceName, saveReportImage } from '../../../store/actions/common-actions';
import { toast } from 'react-toastify';
import { getReportImage } from '../../../shared/utils/services/hooks/reports';


const notifyAddressSearchError = () =>
  toast.error('Something went wrong. Please try again later!');

const RiskPreview: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { location }: any = useSelector<any>((state: any) => state.location);
  const { isLoggedIn }: any = useSelector<any>((state: any) => state.user);
  const { propertyId }: any = useSelector<any>((state: any) => state.common);
  const [reportImageInput, setReportImageInput] = React.useState<any>(null);
  const [isDisabled, setDisabled] = React.useState(false);

  const {
    data: reportImageData,
    isLoading: reportImageLoading,
    isError: reportImageError,
  } = getReportImage(reportImageInput);

  const handleBuyReport = () => {
    setDisabled(true);
    const data = {
      streetAddress: location?.location.formatted_address.split(',')[0],
      suburb: location?.location.terms[location?.location.terms.length - 3],
      city: location?.location.city1,
      propertyId: propertyId?.toString()
    }
    setReportImageInput(data);
  };

  React.useEffect(() => {
    if (reportImageData) {
      dispatch(saveReportImage(`data:image/jpeg;base64,${reportImageData.fileContent}`));
      dispatch(savePlaceName(location?.location));
      if (isLoggedIn) {
        history.push('/payment');
      } else {
        history.push('/signup');
      }
    } else if (reportImageError) {
      console.log(reportImageError);
      notifyAddressSearchError();
      setDisabled(true);
    }
  }, [reportImageData, reportImageLoading, reportImageError]);
  
  return (
    <>
      <div id="riskpreview">
        <div className="wrap">
          <div className="row">
            <div className="half-width">
              <div>
                <img src="/images/plock.svg" />
                <h3>
                  Unlock report for <strong> $49</strong>
                </h3>
              </div>
            </div>
            <div className="half-width">
              <div className="reportbtn">
                <Button
                  onClick={() => handleBuyReport()}
                  className="button-primary"
                  disabled={!location?.location?.formatted_address || !propertyId || isDisabled || reportImageLoading}
                >
                  Buy report
                </Button>
              </div>
            </div>
          </div>

          <div className="report-priview">
            <div className='rep-prev'>Your report preview:</div>
            <h2 className='view-report-header'>Flooding</h2>
          </div>
          <div className="flooding">
            <div className="f-tooltip">
              <ul>
                <li>Your hazard, and how you compare to the city average</li>
              </ul>
            </div>
            <div className="flodingMain">
              <div className="not_assessed">
                <p>Not assessed</p>
              </div>
              <div className="lowf">
                <p>Low</p>
              </div>
              <div className="lowMf">
                <p>Low-med</p>
              </div>
              <div className="MedHf">
                <p>Med-high</p>
              </div>
              <div className="medlowf">
                <p>High</p>
              </div>
            </div>

            <div className="floodingrow">
              <img src="/images/disblerow.png" />
            </div>
            <div className="riskRow riskdesktop">
              <h3>Hazard summary </h3>
              <img src="/images/risk.png" />
            </div>
            <div className="riskRow riskmobile">
              <div className="f-tooltip">
                <ul>
                  <li>
                    Detailed Summary helps you understand the hazard better
                  </li>
                </ul>
              </div>
              <h2>Hazard summary </h2>
              <img src="/images/rmob.png" />
            </div>
            <div className="mapRow">
              <div className="f-tooltip">
                <ul>
                  <li>Detailed maps of your area</li>
                </ul>
              </div>
              <h3>Hazard map</h3>
              <img src="/images/map-preview.jpg" />
            </div>
            <div className="takeaction">
              <div className="f-tooltip">
                <ul>
                  <li>Actions to take</li>
                </ul>
              </div>
              <img src="/images/action.png" className="actiond" />
              <img src="/images/mobilrisk.png" className="actionm" />
            </div>
          </div>
          <div className="acmobile">
            <div className="report-priview ">
              <h2 className='view-report-header'>Coastal erosion</h2>
            </div>
            <div className="flooding">
              <div className="f-tooltip">
                <ul>
                  <li>Your hazard, and how you compare to the city average</li>
                </ul>
              </div>
              <div className="flodingMain">
                <div className="not_assessed">
                  <p>Not assessed</p>
                </div>
                <div className="lowf">
                  <p>Low</p>
                </div>
                <div className="lowMf">
                  <p>Low-med</p>
                </div>
                <div className="MedHf">
                  <p>Med-high</p>
                </div>
                <div className="medlowf">
                  <p>High</p>
                </div>
              </div>

              <div className="floodingrow">
                <img src="/images/disblerow.png" />
              </div>
              <div className="riskRow">
                <h3>Hazard summary </h3>
                <img src="/images/risk.png" />
              </div>
              <div className="mapRow">
                <div className="f-tooltip">
                  <ul>
                    <li>Detailed maps of your area</li>
                  </ul>
                </div>
                <h3>Hazard map</h3>
                <img src="/images/map-preview.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskPreview;
