const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
};

export default function postTerms(
  state = initialState,
  // eslint-disable-next-line
  { type, payload }: any
): any {
  switch (type) {
    case 'LOADING': {
      return { ...state, loading: payload };
    }
    case 'TERMS_SAVE_SUCCESS': {
      return { ...state, success: payload };
    }
    case 'TERMS_SAVE_ERROR': {
      return { ...state, error: true, message: payload };
    }
    default:
      return state;
  }
}
