import * as React from 'react';
import NotFound from '../../components/not-found-page';
import FlushData from '../FlushData';
const NotFoundPage: React.FC<any> = () => {
  return (
    <div>
      <FlushData />
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
