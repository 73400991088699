import * as React from 'react';
import './index.scss';

import {
  LayersControl,
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
} from 'react-leaflet';
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/ui/components/button/index';
import Search from '../../../shared/ui/components/search';
import { useHistory } from 'react-router';
import { config } from '../../../config';
import {
  savePlaceName,
  saveReportImage,
  savePropertyId,
} from '../../../store/actions/common-actions';
import { downloadSampleReport } from '../../../shared/utils/services/hooks/reports';
import { getReportImage } from '../../../shared/utils/services/hooks/reports';
import { generateFile } from '../../../shared/utils/helper';
import { toast } from 'react-toastify';
import { UserSearchAddressID } from '../../../shared/utils/services/hooks/user';
const notifySampleDownLoadError = () =>
  toast.error(
    'Something went wrong while downloading sample report. Please try again later!'
  );

const notifyAddressSearchError = () =>
  toast.error('Something went wrong. Please try again later!');

const MainBanner = () => {
  const { location } = useSelector((state) => state.location);
  const { searchAddress } = useSelector((state) => state.common);
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const [map, setMap] = React.useState(null);
  const geoJsonRef = React.useRef();
  const [geoJSON, setGeoJSON] = React.useState(null);
  const [neighborGeoJSON, setNeighborGeoJSON] = React.useState(null);
  const [downloadSample, setDownloadSample] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState();
  const [searchText, setSearchText] = React.useState('');
  const [reportImageInput, setReportImageInput] = React.useState(null);
  const [propertyId, setPropertyId] = React.useState(null);
  const [isDisabled, setDisabled] = React.useState(true);
  const [buyNowTouched, setBuyNowTouched] = React.useState(false);
  const {
    data: sampleReportData,
    isLoading: downloadSampleLoading,
    isError: downloadSampleError,
  } = downloadSampleReport(downloadSample);
  const { isSuccess: selectedSearchAddSuc } = UserSearchAddressID(
    location?.location?.formatted_address,
    searchAddress?.addressSearchId,
    'Payment',
    buyNowTouched
  );

  const {
    data: reportImageData,
    isLoading: reportImageLoading,
    isError: reportImageError,
  } = getReportImage(reportImageInput);

  const fetchSuburbData = async () => {
    setGeoJSON(null);
    setNeighborGeoJSON(null);
    if (location?.geometry?.lng && location?.geometry?.lat) {
      fetch(
        config.dataLintzServiceURL +
          `?key=${config.serviceAPIKey}&layer=${config.parcelKeyForReport}&x=${location?.geometry?.lng}&y=${location?.geometry?.lat}&max_results=1&radius=10000&geometry=true&with_field_names=true`
      )
        .then((response) => response.json())
        .then((response) => {
          setGeoJSON(
            response?.vectorQuery?.layers[`${config.parcelKeyForReport}`]
              .features
          );
          if (geoJsonRef.current && map) {
            map.fitBounds(geoJsonRef.current.getBounds());
            // setLoading(false);
          }
          setLoading(false);
          setPropertyId(
            response?.vectorQuery?.layers[`${config.parcelKeyForReport}`]
              .features[0].properties.id
          );
          dispatch(
            savePropertyId(
              response?.vectorQuery?.layers[`${config.parcelKeyForReport}`]
                .features[0].properties.id
            )
          );
          setDisabled(false);
          // setTimeout(() => setLoading(false), 2000);
        })
        .catch((err) => {
          setLoading(false);
          setDisabled(false);
          notifyAddressSearchError();
        });
    }
  };

  const handleBuyReport = () => {
    setDisabled(true);
    setBuyNowTouched(true);
    const data = {
      streetAddress: location?.location.formatted_address.split(',')[0],
      suburb: location?.location.terms[location?.location.terms.length - 3],
      city: location?.location.city1,
      propertyId: propertyId?.toString(),
    };
    setReportImageInput(data);
  };

  const handleDownloadSample = () => {
    setDownloadSample(true);
  };

  React.useEffect(() => {
    if (sampleReportData) {
      generateFile(sampleReportData.url, sampleReportData.fileName);
      setDownloadSample(false);
    } else if (downloadSampleError) {
      setDownloadSample(false);
      notifySampleDownLoadError();
    }
  }, [sampleReportData, downloadSampleLoading, downloadSampleError]);

  React.useEffect(() => {
    if (reportImageData) {
      dispatch(
        saveReportImage(`data:image/jpeg;base64,${reportImageData.fileContent}`)
      );
      dispatch(savePlaceName(location?.location));
      if (isLoggedIn) {
        history.push('/payment');
      } else {
        history.push('/signup');
      }
    } else if (reportImageError) {
      console.log(reportImageError);
      notifyAddressSearchError();
      setDisabled(false);
    }
  }, [reportImageData, reportImageLoading, reportImageError]);

  React.useEffect(() => {
    dispatch(savePropertyId(null));
    fetchSuburbData();
  }, [map, location]);
  return (
    <>
      <div id="rpbanner">
        <div className="wrap">
          <div className="innerwrap">
            <div className="rpsearch">
              <Search
                isBanner
                setLoading={setLoading}
                placeholder={
                  location?.location?.formatted_address
                    ? location?.location?.formatted_address
                    : 'Search location'
                }
                searchText={searchText}
                setSearchText={setSearchText}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
              />
            </div>
            <div id="map-wrapper">
              {/* {loading ? ( */}
              {loading && (
                <div className="loading-wrapper">
                  <Loader type="Puff" color="#fff" />
                </div>
              )}
              {/* ) : ( */}
              <MapContainer
                id="map-container"
                className="map-container"
                center={[-36.840338525613234, 174.7565252806435]}
                zoom={14}
                zoomControl={false}
                whenCreated={setMap}
              >
                {geoJSON && (
                  <GeoJSON
                    style={{
                      fillColor: '#5C5CE5',
                      fillOpacity: 0.5,
                      color: '#5c5ce5',
                      weight: 1,
                    }}
                    data={geoJSON}
                    ref={geoJsonRef}
                  />
                )}

                {neighborGeoJSON && (
                  <GeoJSON
                    style={{
                      fillColor: '#fff',
                      fillOpacity: 0.2,
                      color: '#fff',
                      weight: 1,
                    }}
                    data={neighborGeoJSON}
                    ref={geoJsonRef}
                  />
                )}

                <ZoomControl position="bottomleft" />

                <TileLayer
                  url="https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=d01edq7070qb1y31gakkyabawdk"
                  attribution='&copy; <a href="Esri &mdash"></a> contributors'
                />
                <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=d01edq7070qb1y31gakkyabawdk"
                  />
                </LayersControl.BaseLayer>
              </MapContainer>
              {/* )} */}
            </div>

            <h1>{location?.location?.city}</h1>
            <p>{location?.location?.country}</p>
            {/* <div className="batch-icon">
              <img
                src="/images/floodrelief.svg"
               
              />
            </div> */}

            <div className="button-reports">
              <Button
                className="button-primary"
                onClick={() => handleBuyReport()}
                disabled={
                  !location?.location?.formatted_address ||
                  isDisabled ||
                  loading ||
                  reportImageLoading
                }
              >
                Buy report - $49
              </Button>
              <Button
                onClick={() => handleDownloadSample()}
                className="button-secondary"
              >
                Demo PDF
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainBanner;
