import { useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const postTermsAndConditions = (data: any, submitForm: boolean) => {
  const adminCallApi = adminUseAxios();
  return useQuery(
    ['POST_TERMS_AND_CONDITIONS'],
    () =>
      adminCallApi({
        method: 'POST',
        url: '/TermConditions',
        data: { terms: data },
      }).then((data: any) => data),
    {
      enabled: Boolean(data) && Boolean(submitForm),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const getTermsAndCondition = () => {
  const adminCallApi = adminUseAxios();
  return useQuery(['GET_TERMS_AND_CONDITION'], () =>
    adminCallApi({
      method: 'GET',
      url: `/TermConditions`,
    }).then((data: any) => (data ? data.termCondition : []))
  );
};
