import * as React from 'react';
import './index.scss';

const PageNotFoundPage: React.FC<any> = () => {
  return (
    <div>
      <div id="pageNotFound">
        <div className="wrap">
          <div className="innerwrap">
            <h1>404</h1>
            <h2>Page not found</h2>

            <p>Try going back to the previous page <br/> or return home to start again.</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundPage;
