import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
    createPdf = (html: any) => {
        savePDF(html, {
            paperSize: 'A3',
            fileName: 'form.pdf',
            margin: 3
        })
    }
}

const Doc = new DocService();
export default Doc;