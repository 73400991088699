export interface IScreen {
  location: boolean;
}

const initialState = {
  menu: false,
};

export default function screenReducer(
  state = initialState,
  // eslint-disable-next-line
  { type, payload }: any
): any {
  switch (type) {
    case 'MAIN_MENU': {
      return { ...state, menu: payload };
    }
    default:
      return state;
  }
}
