import * as React from 'react';
import './index.scss';
import MindRestImage from '../../../assets/images/mind-ease.png';
const MindRest: React.FC<any> = () => {
  return (
    <div id="mind-rest">
      <div className="wrap">
        <div className="row">
          {/* Left content */}
          <div className="half-width-img">
            <img src={MindRestImage} alt="mindrest-image" />
          </div>
          <div className="half-width">
            <div className="steps">Step 3</div>
            <h3>Put your mind at ease</h3>
            <p className="stepcontant">
              Purchase your new Landcheck report that explains natural hazards
              in a way that’s simple to understand without skimping on the
              details.
            </p>
            <br />
            <p className='question'>What if there is an issue?</p>
            <p>
              We’ll clearly highlight any risks to the property so you can
              understand what these mean. We also provide extra information on
              what to do about these and how you could investigate them further.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MindRest;
