import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import './loader.scss';

interface ILoader {
  title?: any
  subTitle?: any
  description?: any
  isLoading?: any
}


const Loader: React.FC<ILoader> = ({ title, subTitle, description, isLoading }) => {
  return (
    <div className="loading">
      {
        isLoading &&
        <div className="reportloder">
          <div className="loadingspinner"></div>
        </div>
      }
      <h2>{title}</h2>
      <h3>{subTitle}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Loader;
