import { config } from '../../../config';

export const mainMenu = [
  {
    label: 'About us',
    path: config.landcheckWebApp + 'landcheck-about/',
  },
  {
    label: 'Why Landcheck?',
    path: config.landcheckWebApp + 'why-landcheck/',
  },
  {
    label: 'Home owners',
    path: config.landcheckWebApp + 'home-owners/',
  },
  {
    label: 'First home buyers',
    path: config.landcheckWebApp + 'first-home-buyers/',
  },
  {
    label: 'Contact Us',
    path: config.landcheckWebApp + 'landcheck-contact-support/',
  },
];
export const oAuthClientKey = 'CaU0uYmdqI74oivVzf5m4TMvPAUWG3SH';
export const oAuthDomain = 'landcheck-dev.au.auth0.com';
export const defaultMapCenterPoint = [36.8509, 174.7645];
export const googleMapAPiKey = 'AIzaSyDfPTLC4Hcp_JmntubMOWhn3G5ZOnOB5Cc';
export const googleCaptchaSiteKey = '6Lc_s0EdAAAAAB49t8AIYpZm2LFV28ErnCug90Us';
export const locationCovered = [
  'Auckland',
  // 'Waikato',
  // 'Bay of Plenty',
  'Wellington',
  'Canterbury',
  'Christchurch',
];
//eslint-disable-next-line
export const validEmailRegex = RegExp(
  //eslint-disable-next-line
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
//eslint-disable-next-line
export const validPhoneNumber = RegExp(/^[1-9][0-9]*$/i);
export const dummyLocations = [
  {
    id: 1,
    name: 'test1',
    lat: 36.8509,
    lan: 174.7645,
  },
  {
    id: 2,
    name: 'test2',
    lat: 37.8509,
    lan: 174.7645,
  },
  {
    id: 3,
    name: 'test3',
    lat: 38.8509,
    lan: 174.7645,
  },
  {
    id: 4,
    name: 'test4',
    lat: 36.8709,
    lan: 174.7645,
  },
  {
    id: 5,
    name: 'test5',
    lat: 36.9909,
    lan: 174.7645,
  },
  {
    id: 6,
    name: 'test6',
    lat: 32.8509,
    lan: 174.7645,
  },
];

export const suburbLatLong = {
  auckland: { lat: -36.846668084255306, lng: 174.76978047227803 },
  hamilton: { lat: -37.773728, lng: 175.2716393 },
  tauranga: { lat: -37.709615, lng: 176.143767 },
  wellington: { lat: -41.178263, lng: 175.416903 },
  christchurch: { lat: -43.524766, lng: 172.628469 },
};

export const dropDownData = [
  'Auckland',
  // 'Hamilton',
  // 'Tauranga',
  'Wellington',
  'Christchurch',
];
export const countryData = [
  { label: 'New Zealand', name: 'New Zealand' },
  // { label: 'United States of America', name: 'United States of America' },
  // { label: 'Australia', name: 'Australia' },
  // { label: 'Brazil', name: 'Brazil' },
];

export const cityData = [
  { label: 'Auckland', value: 'Auckland' },
  { label: 'Hamilton', value: 'Hamilton' },
  { label: 'Tauranga', value: 'Tauranga' },
  { label: 'Wellington', value: 'Wellington' },
  { label: 'Christchurch', value: 'Christchurch' },
];

export const riskTab = [
  {
    label: 'Low',
  },
  {
    label: 'Low-med',
  },
  {
    label: 'Med-high',
  },
  {
    label: 'High',
  },
  {
    label: 'Information',
  },
];
