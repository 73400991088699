import React from 'react';
import RiskReport from '../../risk-reports';
import './print.scss';
import wave from '../../../../assets/images/Contours.png';
import { getRatingValue, RiskRange } from '../../report-preview';
import AboutYourReport from '../../../about-your-report';
import RiskRating from '../../../rating/riskrating';
import RiskDefinitions from '../../../risk-definitions';

const PrintComponent: React.FC<any> = ({ componentRef, data }) => {
  return (
    <div
      ref={componentRef}
      className="repor_page report-print report-print-row"
    >
      <body>
        <div>
          <div>
            <div>
              <div>
                <div className="page-header-space"></div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div className='pagebreak'
                style={{
                  height: '100vh',
                  padding: 10,
                  margin: '0 24px',
                  position: 'relative',
                }}
              >
                <div className="page-index-header">
                  <p>
                    Residential Property <br />
                    Review
                  </p>
                </div>
                <div className="page-index-footer" style={{ paddingBottom: 0 }}>
                  <div className="footerpdf">
                    <div className="pdffooterleft">
                      <img src="/images/footrpdf.svg" />
                    </div>
                    <div className="pdffooterright">
                      <p>
                        © Copyright 2023 Landcheck Ltd. <br /> All rights reserved.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <img
                    src={wave}
                    alt="wave"
                    style={{
                      width: 600,
                      height: 452,
                      position: 'absolute',
                      right: 0,
                      top: 80,
                    }}
                  />
                  <div className="reporttitlepdf">
                    <div className="pdfmrg-1">
                      <h2
                        className="report-title1"
                        style={{
                          fontSize: 40,
                          fontWeight: 700,
                          color: '#343473',
                        }}
                      >
                        {data && data[0] && data[0].address.streetAddress}
                      </h2>
                      <h3
                        style={{
                          fontSize: 32,
                          fontWeight: 500,
                          color: '#343473',
                        }}
                      >
                        {data && data[0] && data[0].address.city}
                      </h3>
                      <p>
                        <strong>Report date:</strong>{' '}
                        {data && data[0] && data[0].assessmentDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='print-page-header'>
                <div>
                  <img src="/images/footrpdf.svg" />
                </div>
                <div>
                  {data && data[0] && data[0].address.streetAddress}, {data && data[0] && data[0].address.city}
                </div>
              </div>

              <div className='print-page-footer'>
                <div></div>
                <div>
                  Report date:{' '} {data && data[0] && data[0].assessmentDate}
                </div>
              </div>

              <table>

                <thead>
                  <tr>
                    <td>
                      <div className='print-page-header-space'></div>
                    </td>
                  </tr>
                </thead>


                <tbody>
                  <tr>
                    <td>

                      <div className='pagebreak'>
                        <div>
                          <h1>Natural hazard summary   </h1>
                          <p>
                            Landcheck provides a clear, concise summary of natural hazards
                            that could affect your selected property. Natural Hazard
                            experts collated the information for ten natural hazards from
                            various sources including regulatory authorities such as
                            Councils. An algorithm is then applied to the data to
                            establish a hazard impact rating for the property. To provide
                            context to the assessment, Landcheck reviews each natural
                            hazard on a suburb basis to provide comparison between the
                            selected property and the typical risk exposure within the
                            suburb.
                          </p>
                          <p>
                            Landcheck makes natural hazard data accessible, easy to
                            understand and saves time needed to collect and digest the
                            information. Landcheck also provides recommendations in terms
                            of next steps and possible solutions to mitigate the effects
                            of natural hazard risk on the selected property.
                          </p>
                        </div>
                        <div>
                          <div className="rp-banner">
                            <img
                              src={data ? data[0].coverImage : '/images/reportbanner.jpg'}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='pagebreak risk-rating'>
                            <RiskRating ratingSummary={data?.[0]?.riskRatingSummary} cityRiskSummary={data?.[0]?.cityRiskSummaryDetails} />
                            <div className="risktable" style={{ marginTop: 32 }}>
                              <RiskReport reportData={data} />
                            </div>
                      </div>

                      <div className='pagebreak'>
                        <RiskDefinitions cityRiskSummary={data?.[0]?.cityRiskSummaryDetails} />
                      </div>

                      { data &&
                        data[0].risks &&
                        data[0].risks.length > 0 &&
                        data[0].risks.map((item: any, index: boolean) => {
                          return (
                            <div className="page riskp printwrap" key={item.id}>
                              <div
                                className="wrap wrap-risk-level"
                                id={item.riskType.replace(' ', '')}
                              >
                                <div
                                  className={
                                    item.riskValue?.rating?.name
                                      ? 'report-priview ' +
                                      item.riskValue.rating.name
                                        .replace('-', '')
                                        .replace(' ', '')
                                        .toLowerCase()
                                      : 'report-priview'
                                  }
                                >
                                  <h3>{item.riskValue.rating.name}</h3>
                                  <h2>{item.riskName}</h2>
                                  <div className="rp-contant">
                                    <p>{item.riskValue.rating.riskText}</p>
                                    <div className="rp-lbtn">
                                      {item.riskValue.rating.propertyValue !== -1 && (
                                        <div className="property">
                                          <img
                                            src="/images/pricon.svg"
                                            alt=" This property"
                                          />
                                          This property
                                        </div>
                                      )}
                                      {item.riskValue.rating.areaValue !== -1 && (
                                        <div className="pr-l">
                                          <img
                                            src="/images/location.svg"
                                            alt="location"
                                          />
                                          {data[0].address.suburb}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flooding">
                                  <div className="flodingMain">
                                    <img
                                      src="/images/location.svg"
                                      style={{
                                        display: !getRatingValue(
                                          item.riskValue.rating.areaValue,
                                          item?.riskValue?.rating?.riskRange?.length
                                        )
                                          ? 'none'
                                          : '',
                                        left: getRatingValue(
                                          item.riskValue.rating.areaValue,
                                          item?.riskValue?.rating?.riskRange?.length
                                        )
                                          ? getRatingValue(
                                            item.riskValue.rating.areaValue,
                                            item?.riskValue?.rating?.riskRange?.length
                                          )
                                          : 0,
                                      }}
                                      alt="location"
                                      className="suborg"
                                    />
                                    <img
                                      src="/images/pricon.svg"
                                      style={{
                                        display: !getRatingValue(
                                          item.riskValue.rating.propertyValue,
                                          item?.riskValue?.rating?.riskRange?.length
                                        )
                                          ? 'none'
                                          : '',
                                        left: getRatingValue(
                                          item.riskValue.rating.propertyValue,
                                          item?.riskValue?.rating?.riskRange?.length
                                        )
                                          ? getRatingValue(
                                            item.riskValue.rating.propertyValue,
                                            item?.riskValue?.rating?.riskRange?.length
                                          )
                                          : 0,
                                      }}
                                      alt=" This property"
                                    />
                                    <RiskRange
                                      items={item?.riskValue?.rating?.riskRange || []}
                                    />
                                  </div>
                                </div>
                                <div className="pr-rating">
                                  <div className="pr-rating-heading pagebreak">
                                    <h2>Hazard rating</h2>
                                    <p>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.riskValue.whyThisRiskRating,
                                        }}
                                      ></div>
                                    </p>
                                    <h3>Likelihood:</h3>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.riskValue.likelihood,
                                      }}
                                    ></p>
                                    <h3>Impact:</h3>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.riskValue.impact,
                                      }}
                                    ></p>
                                  </div>
                                  <div className="maparea">
                                    <div className="maphead">
                                      <div className="rp-contant">
                                        <div>
                                          <h3>Hazard map</h3>
                                          <div>
                                            <p>
                                              {!item.riskValue?.hazardOverlay
                                                ? 'No hazard overlay to display'
                                                : ''}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="rp-lbtn">
                                          {item.reportRiskLegends &&
                                            item.reportRiskLegends.length > 0 &&
                                            item.reportRiskLegends.map(
                                              (legend: any) => (
                                                <React.Fragment
                                                  key={legend.reportRiskLegendId}
                                                >
                                                  <div className="property">
                                                    <img
                                                      src={
                                                        'data:' +
                                                        legend.contentType +
                                                        ';base64,' +
                                                        legend.imageData
                                                      }
                                                      alt=" This property"
                                                    />
                                                    {legend.label}
                                                  </div>
                                                </React.Fragment>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mainmap">
                                      <img src={item.riskValue.imageUrl} alt=" map" />
                                    </div>
                                    <div
                                      className="mainmap-caption"
                                      dangerouslySetInnerHTML={{
                                        __html: item.riskValue.attributionText,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="mapcontant">
                                    <div className="row">
                                      <table className="tablemap">
                                        <tr>
                                          <td className='mapAction'>
                                            {' '}
                                            <img src="/images/icon4.svg" alt=" icon" />
                                          </td>
                                          <td className='mapAction'>
                                            {' '}
                                            <img src="/images/icon2.svg" alt=" icon" />
                                          </td>
                                          <td className='mapAction'>
                                            {' '}
                                            <img src="/images/icon3.svg" alt=" icon" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className='mapAction'>
                                            {' '}
                                            <h3>Further Technical Information</h3>
                                          </td>
                                          <td className='mapAction'>
                                            {' '}
                                            <h3>Action to take</h3>
                                          </td>
                                          <td className='mapAction'>
                                            {' '}
                                            <h3>Mitigation Measures</h3>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className='mapAction'>
                                            {' '}
                                            <p>{item.riskValue.financialImpact}</p>
                                          </td>
                                          <td className='mapAction'>
                                            {' '}
                                            <p>{item.riskValue.actionToTake}</p>
                                          </td>
                                          <td className='mapAction'>
                                            {' '}
                                            <p>{item.riskValue.permanentFixes}</p>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="mapdetails">
                            <div className="maplocation">
                              <p>Source: {item.riskValue.source}</p>
                            </div>
                          </div>
                                  <div className="rp-disclaimer">
                                    <p>
                                      Please note: Occasionally, Auckland Council, and
                                      our other sources will update their data. We
                                      regularly check that the data Landcheck uses is up
                                      to date and carry out a comprehensive review every
                                      6 months. Any report that was purchased prior to
                                      an update may include outdated information.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })} 

                      <div className="page">
                        <div className='pdfAbout'>
                          <AboutYourReport />
                        </div>
                      </div>

                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td>
                      <div className='print-page-footer-space'></div>
                    </td>
                  </tr>
                </tfoot>

              </table>

            </div>
          </div>

          <div>
            <div>
              <div>
                <div className="page-footer-space"></div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default PrintComponent;
