import * as React from 'react';
import { useLocation } from 'react-router-dom';
import './index.scss';

const RiskDefinitions: React.FC<any> = ({cityRiskSummary}) => {
  const location = useLocation();
  const riskSummary: any = location.state || cityRiskSummary;

  return (
    <div className='riskdefs'>
      <h2> What your risk rating means:</h2>
      <div className="riskdefinitiontopbar">
        <div className="riskdefinitionlow"><p>Low</p></div>
        <div className="riskdefinitionmedium"><p>Low-medium</p></div>
        <div className="riskdefinitionmedhigh"><p>Medium-high</p></div>
        <div className="riskdefinitionheigh"><p>High</p></div>
      </div>

      <div className="definitionsection">
        <div className="definitionleftsection">
          <div className="definitioncircle definitioncirclelow">
            <p>1</p>
          </div>
        </div>
        <div className="definitionrightsection">
          <h2>Low risk</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: riskSummary?.lowRatingDescription,
            }}
          ></div>
        </div>
      </div>
      <div className="definitionsection">
        <div className="definitionleftsection">
          <div className="definitioncircle definitioncirclemedium ">
            <p>2</p>
          </div>
        </div>
        <div className="definitionrightsection">
          <h2>Low-medium risk</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: riskSummary?.lowMedRatingDescription,
            }}
          ></div>
        </div>

      </div>
      <div className="definitionsection">
        <div className="definitionleftsection">
          <div className="definitioncircle definitioncirclemediumhigh">
            <p>3</p>
          </div>
        </div>
        <div className="definitionrightsection">
          <h2>Medium-high risk</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: riskSummary?.medHighRatingDescription,
            }}
          ></div>
        </div>

      </div>
      <div className="definitionsection">
        <div className="definitionleftsection">
          <div className="definitioncircle definitioncircleheigh ">
            <p>4</p>
          </div>
        </div>
        <div className="definitionrightsection">
          <h2>High risk</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: riskSummary?.highRatingDescription,
            }}
          ></div>
        </div>

      </div>
    </div>
  );
};

export default RiskDefinitions;
