import * as React from 'react';
import './index.scss';

const VerifyEmail: React.FC<any> = () => {
    return (
        <div>
            <div id="verifyEmail">
                <div className="wrap">
                    <div className="innerwrap">
                        <div className="verifyEmail-inner">
                            <h2>Please verify your email address</h2>
                            <p>You'll need to verify your email address before using Landcheck.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
