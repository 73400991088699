import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Container, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { saveLocation } from '../../../../store/actions/location-action';

import { useHistory } from 'react-router-dom';
import { Location } from '../../../utils/types';
import { locationCovered } from '../../../utils/constants';

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { getLatLong } from '../../../utils/helper';
import { UserSearchAddress, UserSearchAddressID } from '../../../utils/services/hooks/user';
import { saveSearchAddressInfo } from '../../../../store/actions/common-actions';

interface Ilocation {
  address_components: any;
}

const SearchAddress: React.FC<any> = ({
  className,
  placeholder,
  id,
  setLoading,
  isBanner,
  searchText,
  setSearchText,
  searchResults,
  setSearchResults
}) => {
  const dispatch = useDispatch();
  const parentClass = className
    ? `${className} wrap serch-drp`
    : `wrap serch-drp`;
  const history = useHistory();
  // const { common }: any = useSelector<any>((state: any) => state);
  const [add, setAdd] = useState<any>("");
  const [selectedAddress, setSelectedAddress] = useState<Location>();
  const { placesAutocompleteService, placesService } = usePlacesService({});
  const searchTimeout = useRef<any>();
  // const { data: getSearchAddressDataID, isSuccess: searchAddDataFetchSuc, refetch } = UserSearchAddress(searchText);
  const { data: getSearchAddressDataID, isSuccess: searchAddDataFetchSuc} = UserSearchAddress(add, 'Selected');

  useEffect(() => {
    if (searchText !== '') {
      clearTimeout(searchTimeout.current);
      // set searchTimeout to 500ms
      searchTimeout.current = setTimeout(function () {
        onPlacesSearch();
      }, 500);
    } else {
      setSearchResults([]);
    }
  }, [searchText]);

  useEffect(() => {
    if (searchAddDataFetchSuc) {
      dispatch(saveSearchAddressInfo({addresstext: searchText, addressSearchId: getSearchAddressDataID }))
    }
  },[searchAddDataFetchSuc])
  function onPlacesSearch() {
    placesAutocompleteService?.getPlacePredictions(
      {
        input: searchText,
        componentRestrictions: { country: 'nz' },
      },
      (res) => {
        if (res && placesService) {
          const cities = ['AUCKLAND','CHRISTCHURCH','WELLINGTON','HAMILTON','TAURANGA'];
          const cityArray1 = res.filter(city => !cities.includes(city?.terms[city?.terms?.length - 2]?.value.toUpperCase()));
          const updatedArray: any[] = cityArray1.map(async (pred: any) => {
            function checkandreturn(re: any, pred: any, resolve: any) {
              if (re) {
                const city =
                  re?.address_components[re?.address_components?.length - 3]
                    ?.short_name;
                resolve({
                  formatted_address: pred.description,
                  city: pred.structured_formatting.main_text,
                  city1: city,
                  country: pred.structured_formatting.secondary_text,
                  place_id: pred.place_id,
                  terms: pred?.terms
                    ? pred?.terms?.map((term: any) => term.value)
                    : [],
                  coveredLocation:
                    locationCovered.findIndex((c) => c === city) >= 0
                      ? true
                      : false,
                });
              } else {
                resolve(null);
              }
            }
            return new Promise((resolve) => {
              if (pred && pred.place_id) {
                placesService.getDetails(
                  {
                    placeId: pred.place_id,
                    fields: ['address_components'],
                  },
                  (e) => checkandreturn(e, pred, resolve)
                );
              }
            });
          });

          const cityArray2 = res.filter(city => cities.includes(city?.terms[city?.terms?.length - 2]?.value.toUpperCase()));

          cityArray2.forEach((pred) => {
            const city = pred?.terms[pred?.terms?.length - 2]?.value;
            const tempObj = {
              formatted_address: pred.description,
              city: pred.structured_formatting.main_text,
              city1: city,
              country: pred.structured_formatting.secondary_text,
              place_id: pred.place_id,
              terms: pred?.terms
                ? pred?.terms?.map((term: any) => term.value)
                : [],
              coveredLocation:
                locationCovered.findIndex((c) => c === city) >= 0
                  ? true
                  : false,
            };
            updatedArray.push(tempObj);
          })

          Promise.all(updatedArray).then((res: any) => {
            if (res.length > 0) {
              res = res.filter((i: any) => i !== null);
              res.sort((a: { coveredLocation: any; }, b: { coveredLocation: any; }) => b.coveredLocation - a.coveredLocation);
              setSearchResults(res);
            } else {
              setSearchResults([]);
            }
          });
        } else {
          setSearchResults([]);
        }
      }
    );
  }

  const onAddressSelect = async (address: Location) => {
    setAdd(address.formatted_address);
    if (address.coveredLocation) {
      const addressLatLong = await getLatLong(address.formatted_address);
      dispatch(saveLocation(address, addressLatLong));
      setSearchResults([]);
      setSearchText('');
      if (address.coveredLocation) {
        history.push(`/view-report`);
      } else {
        history.push(`/not-found-page`);
      }
      isBanner && setLoading(true);
    }
  };

  const searchResultPopover = (
    <div className={parentClass}>
      <div className="address-list">
        <ListGroup>
          {searchResults &&
            searchResults.map((result: any) => (
              <ListGroup.Item
                className={result.coveredLocation ? 'enabled-search-item' : 'disabled-search-item'}
                key={result.place_id}
                onClick={() => { onAddressSelect(result) }}>
                <div>
                  <div className='search-city'>{result.city}</div>
                  <div className='search-des'>
                    {result.terms && result.terms.length > 3
                      ? `${result.terms?.[1]}  ${result.terms?.[2]}  ${result.terms?.[3]}`
                      : result.terms?.[1]}
                  </div>
                </div>
                <div>
                  {!result.coveredLocation && (
                    <div className="unavailable-address">Unavailable</div>
                  )}
                </div>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </div>
    </div>
  );

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.classList.remove('active');
  };

  const bindNoResult = () => {
    <div className="searchlist">
      <ListGroup>
        <ListGroup.Item>No Result Found</ListGroup.Item>
      </ListGroup>
    </div>;
  };

  return (
    <Form className="search">
      <Container fluid className="p-0">
        <Row>
          <Col xs={12} className="addresh_search">
            <div className="rpsearch">
              <Form.Control
                value={
                  selectedAddress?.formatted_address
                    ? selectedAddress?.formatted_address
                    : searchText
                }
                className="search-field"
                id={id ? id : 'inlineFormInputName'}
                placeholder={
                  placeholder ? placeholder : 'Search for an address'
                }
                autoComplete={'off'}
                // defaultValue={selectedAddress?.formatted_address ? selectedAddress.formatted_address : ''}
                onChange={(event) => {
                  if (event?.target.value) {
                    event.target.classList.add('active');
                  } else {
                    event.target.classList.remove('active');
                  }
                  if (selectedAddress?.formatted_address) {
                    setSelectedAddress(undefined);
                  }
                  setSearchText(event?.target.value);
                }}
                onBlur={handleBlur}
              />
              {searchResults && searchResults.length > 0 ? (
                <div className="searchlist"> {searchResultPopover} </div>
              ) : searchText !== '' ? (
                bindNoResult()
              ) : (
                <></>
              )}
              <img src="/images/search.png" />
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default SearchAddress;
