import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { generateFile } from '../../../shared/utils/helper';
import { downloadReportByID } from '../../../shared/utils/services/hooks/reports';
import PrintReport from '../print-report';
import './banner.scss';
import moment from 'moment';

const Rpbanner: React.FC<any> = ({
  componentRef,
  showDownload,
  bannerData,
  isDownloadDisabled
}) => {
  const [downLoadReport, setDownLoadReport] = React.useState(false);
  const { selectedReport }: any = useSelector<any>(
    (state: any) => state.reports
  );
  const {
    data: reportData,
    isLoading,
    isError,
  } = downloadReportByID((selectedReport.id || selectedReport.reportId), downLoadReport, selectedReport.authKey);

  React.useEffect(() => {
    if (reportData) {
      generateFile(reportData.url, reportData.fileName);
      setDownLoadReport(false);
    } else if (isError) {
      setDownLoadReport(false);
    }
  }, [reportData, isLoading, isError]);

  const handleReportDownLoad = () => {
    setDownLoadReport(true);
  };
  return (
    <>
      <div className="rp-banner">
        <img
          src={bannerData ? bannerData[0].coverImage : '/images/reportbanner.jpg'}
        />
      </div>
      <div className="rp-btnsection">
        <div className="row">
          <div className="rightcontant">
            <h2>{bannerData ? bannerData[0].address.streetAddress : selectedReport.streetAddress}</h2>
            <p>
              {bannerData ? bannerData[0].address.suburb : selectedReport.suburb}, {bannerData ? bannerData[0].address.city : selectedReport.city}
            </p>
          </div>
          <div className="leftcontant">
            <div className="reportbtn">
              <div className="mbmsg">
                <p>
                  <img src="/images/errormsg.svg" /> This property has a shared
                  driveway, some risk estimates may be affected.
                </p>
              </div>
              {showDownload && (
                <>
                  {
                    isDownloadDisabled ?
                      <p>PDF is being prepared. Please wait...</p>
                      : <Button
                        className="button-secondary"
                        disabled={isDownloadDisabled}
                        onClick={() => handleReportDownLoad()}
                      >
                        Download report
                      </Button>
                  }
                  {/* <PrintReport componentRef={componentRef} /> */}
                </>
              )}
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default Rpbanner;
