import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { validEmailRegex } from '../../shared/utils/constants';
import { lock } from '../../shared/utils/helper';
import {
  emailUpdate,
  passwordUpdate,
} from '../../shared/utils/services/hooks/account';
import { saveAccessToken, saveGuestEmail, saveIsAnonymousUser, saveIsLoggedIn, saveToken } from '../../store/actions/user-actions';
import './setting.scss';
const notifySuccessEmail = () => toast.success('Email updated successfully!');

const notifySuccessPassword = () =>
  toast.success('Password updated successfully!');

const notifyErrorInvalidEmail = () => toast.error('Enter a valid Email!');
const notifyErrorRequiredEmail = () => toast.error('Email is required!');
const notifyErrorRequiredPassword = () => toast.error('Password is required!');
const notifyErrorRequiredOldPassword = () =>
  toast.error('New password is required!');
const Setting: React.FC<any> = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState('');

  const [emailSubmited, setEmailSubmited] = React.useState(false);
  const [passwordSubmited, setPasswordSubmited] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');

  const {
    data: emailData,
    isLoading: emailLoading,
    isError: emailUpdateEror,
  } = emailUpdate(emailSubmited, { newEmail: email });
  const {
    data: passwordData,
    isLoading,
    isError,
  } = passwordUpdate(passwordSubmited, {
    confirmPassword: oldPassword,
    newPassword: password,
  });

  React.useEffect(() => {
    if (emailData) {
      setEmailSubmited(false);
      setEmail('');
      notifySuccessEmail();
      dispatch(saveIsLoggedIn(false));
      dispatch(saveToken(''));
      dispatch(saveAccessToken(''));
      dispatch(saveIsAnonymousUser(false));
      dispatch(saveGuestEmail(''));
      lock.logout({
        returnTo: window.location.origin,
      });
    } else if (emailUpdateEror) {
      setEmailSubmited(false);
      // notifyErrorEmail();
    }
  }, [emailData, emailUpdateEror]);

  React.useEffect(() => {
    if (passwordData) {
      setPassword('');
      setOldPassword('');
      notifySuccessPassword();
      setPasswordSubmited(false);
    } else if (isError) {
      // notifyErrorPassword();
      setPasswordSubmited(false);
    }
  }, [passwordData]);

  const updateEmailData = () => {
    if (email && validEmailRegex.test(email)) {
      setEmailSubmited(true);
    } else if (email === '') {
      notifyErrorRequiredEmail();
    } else {
      notifyErrorInvalidEmail();
    }
  };
  const updatePassword = () => {
    if (password && oldPassword) {
      setPasswordSubmited(true);
    } else if (password === '') {
      notifyErrorRequiredPassword();
    } else {
      notifyErrorRequiredOldPassword();
    }
  };

  return (
    <>
      <div id="settingpage">
        <div className="wrap">
          <div className="innerwrap">
            <div className="login-inner">
              <h1>Setting </h1>
              <div className="settingtop">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) =>{setEmail(e.target.value); if(emailSubmited) setEmailSubmited(false)}}
                />
                <Button
                  disabled={emailLoading}
                  onClick={() => updateEmailData()}
                >
                  Update email
                </Button>
              </div>
              <div className="devider"></div>
              <div className="settingpassword">
                <h1> Change password </h1>
                <div className="settingoldpws">
                  <label>New Password</label>
                  <input
                    type="password"
                    value={oldPassword}
                    onChange={(e) =>{setOldPassword(e.target.value); if(passwordSubmited) setPasswordSubmited(false)}}
                  />
                </div>
                <div className="settingnewpws">
                  <label>Confirm password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => {setPassword(e.target.value);if(passwordSubmited) setPasswordSubmited(false)}}
                  />
                  <span>At least 8 characters, 1 lowercase, 1 upper case</span>
                </div>
              </div>
              <div className="settingfooter">
                <Button disabled={isLoading} onClick={() => updatePassword()}>
                  Update password
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
