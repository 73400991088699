import * as React from 'react';
import './index.scss';
import ClarityRiskImage from '../../../assets/images/clarity-risk.png';
import RiskIcon from '../../../assets/images/RiskIcon.png';
import LowCostIcon from '../../../assets/images/low-cost.png';
import Button from '../../../shared/ui/components/button/index';
import SearchAddress from '../../../shared/ui/components/search';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Location } from '../../../shared/utils/types';
import { getLatLong } from '../../../shared/utils/helper';
import { saveLocation } from '../../../store/actions/location-action';

const notifyError = () => toast.error('Please select a valid address!');

const UnsurePoints: React.FC<any> = () => { 
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<Location[]>();

  const { location }: any = useSelector<Location>(
    (state: any) => state.location
  );
  const handleSearchClick = async (e: any) => {
    e.preventDefault();
    if (searchResults?.length === 1 && searchResults[0].formatted_address?.toLowerCase().trim() === searchText.toLowerCase().trim()) {
      if (searchResults[0].coveredLocation) {
        const addressLatLong = await getLatLong(searchResults[0].formatted_address);
        dispatch(saveLocation(searchResults[0], addressLatLong));
        history.push(`/view-report`);
      } else {
        history.push(`/not-found-page`);
      }
    } else {
      notifyError();
    }
  };
  return (
    <div id="unsure-points">
      <div className="wrap">
        <div className="unsure-header">
          <h2>Still unsure? It’s simple</h2>
        </div>
        <div className="row">
          <div className="four-tag">
            <div className="icon">
              <img src={ClarityRiskImage} />
              <h3>Get clarity on your risks</h3>
              <p>
              An easy to understand report that will inform you of 
              the known natural hazards risks your property is exposed to.
              </p>
            </div>
          </div>
          <div className="four-tag">
            <img src={RiskIcon} />
            <h3>Know what the risks mean</h3>
            <p>
            Not all risks are equal. Better understand your property’s natural hazard risk profile with Landcheck. 
            </p>
          </div>
          <div className="four-tag">
            <img src={LowCostIcon} />
            <h3>Low cost and fast</h3>
            <p>
              No long wait times. No massive cost. Just clear hazard information
              to help you get the property you love.
            </p>
          </div>
        </div>
        <div className="divder"></div>
      </div>
      {/* Search */}
      <div className=" unsure-search">
        <div className="full-width">
          <p className="wrap-600">
          Don't waste your money early on in your property journey. Landcheck is the first check you should make.
          </p>
          <div className="search-home homefootersearch">
            <SearchAddress page="home" className="main-banner-search" searchText={searchText} setSearchText={setSearchText} 
              searchResults={searchResults} setSearchResults={setSearchResults} id="homeSearch3"/>
            <Button className="button-primary ml-16" onClick={handleSearchClick} disabled={!searchText}>Search</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsurePoints;
