import { FC, useEffect, useState } from "react";

interface ITags {
  getTags?: any

}
const Tags: FC<ITags> = ({ getTags }) => {

  const [tags, setTags] = useState<any>([]);
  const removeTagData = (indexToRemove: any) => {
    setTags([...tags.filter((_: any, index: any) => index !== indexToRemove)]);
  };
  const addTagData = (event: any) => {
    if (event.target.value !== '') {
      setTags([...tags, { id: event.target.value, url: event.target.value }]);
      event.target.value = '';
    }
  };
  useEffect(() => {
    getTags(tags)
  }, [tags])

  return (
    <>
      <div className="tags-wrapper">
        {tags.map(
          (tag: any, index: number) =>
            tag.url && (
              <li key={index} className="tag">
                <span
                  className="tag-close-icon"
                  onClick={() => removeTagData(index)}
                >
                  x
                </span>
                <span className="tag-title">{tag?.url}</span>
              </li>
            )
        )}
      </div>
      <input
        className="tags-input"
        type="text"
        onKeyUp={(event) =>
          event.key === 'Enter' ? addTagData(event) : null
        }
        placeholder="Add feature layer endpoint e.g. https://awsgispoc.tonkin.co.nz/server/rest/services/Hosted/Wind_Area/FeatureServer/0"
      />
    </>
  )
}

export default Tags