import adminUseAxios from '../adminUseAxios';
import { useQuery } from 'react-query';

export const SubmitContactForm = (data: any, submitForm: boolean) => {
  const callApi = adminUseAxios();
  return useQuery(
    ['SUBMIT_CONTACT_FORM'],
    () =>
      callApi({
        method: 'POST',
        url: `/ContactUs`,
        data,
      }).then((data: any) => data),
    {
      enabled: Boolean(data) && Boolean(submitForm),
      cacheTime: 0,
    }
  );
};


export const UserSearchAddress = (payload: any, stage: any) => {
    const callApi = adminUseAxios();
    return useQuery(
      ['SUBMIT_SEARCH_ADDRESS_CAPTURE'],
      () => callApi({
        method: 'POST',
        url: `/AddressRequest`,
        data: JSON.stringify({ address: payload, stage: stage })
      }).then((data: any) => data),
      {enabled: Boolean(payload)}
    )
}

export const UserSearchAddressID = (address: any, id: any, stage: any, buyNow: any) => {
  const callApi = adminUseAxios();
  return useQuery(
    ['SUBMIT_SEARCH_ADDRESS_CAPTURE_SELCTED_NEXT', buyNow],
    () => callApi({
      method: 'PUT',
      url: `/AddressRequest/${id}`,
      data: JSON.stringify({ Address: address, Stage: stage, Id: id })
    }).then((data: any) => data),
    {enabled: Boolean(buyNow) && Boolean(address) && Boolean(id)}
  )
}