import { useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const fetchIssuedReports = (formData: any) => {
  const adminCallApi = adminUseAxios();

  const buyDate = {BuyDate: formData?.BuyDate === '' ? null: formData?.BuyDate};
  formData = { ...formData, ...buyDate};

  return useQuery(
    ['FETCH_ISSUED_REPORTS', formData],
    ({ queryKey }) => {
      const [, reqData] = queryKey;
      return adminCallApi({
        method: 'POST',
        url: '/Reports',
        data: reqData,
      }).then((data: any) => data);
    },

    {
      // enabled: Boolean(formData),
      cacheTime: 0,
      staleTime: 2000,
    }
  );
};
