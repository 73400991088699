import React, { useEffect, useState } from 'react';

import './index.scss';
import DropDownItem from './DropDownItem';
import { countryData } from '../../../../shared/utils/constants';
import { getCities } from '../../../../shared/utils/services/hooks/cities';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { getRegion } from '../../../../shared/utils/services/hooks/region';
import { getVersion } from '../../../../shared/utils/services/hooks/version';

const DropDownWrapper: React.FC<any> = ({ setShow, setRegionId, show }) => {
  const [selectedCity, setSelectedCity] = useState('Select a City');
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('Select a Country');
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedCouncil, setSelectedCouncil] = useState('Select a Council');
  const [selectedCouncilId, setSelectedCouncilId] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState('Select a Version');
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const { data: cityData, isLoading, isSuccess: cityDataSuccess } = getCities();
  const { data: regionData, isLoading: isLoadingRegionData } = getRegion();
  const { data: versionData, isLoading: isLoadingVersionData } = getVersion(
    selectedCityId,
    selectedCouncilId
  );

  useEffect(() => {
    if (selectedCouncilId) {
      setRegionId(selectedCouncilId)
    }
  }, [selectedCouncilId])
  return (
    <div id="dropdown-wrapper">
      {isLoading ? (
        <div className="map-loader">
          <Loader type="Puff" color="#2f2f60" height={50} width={50} />
        </div>
      ) : (
        <div className="menu-wrapper">
          <DropDownItem
            city
            label="Country"
            selectedValue={selectedCountry}
            setSelectedValue={setSelectedCountry}
            setSelectedId={setSelectedCountryId}
            listItems={countryData}
          />
          <DropDownItem
            city
            label="City"
            listItems={cityData}
            selectedValue={selectedCity}
            setSelectedValue={setSelectedCity}
            setSelectedId={setSelectedCityId}
          />
          <DropDownItem
            city
            label="Council"
            selectedValue={selectedCouncil}
            setSelectedValue={setSelectedCouncil}
            setSelectedId={setSelectedCouncilId}
            listItems={regionData}
          />
          <DropDownItem
            city={false}
            label="Version"
            selectedValue={selectedVersion}
            setSelectedValue={setSelectedVersion}
            setSelectedId={setSelectedVersionId}
            listItems={versionData}
          />
          <div className="menu-go" onClick={() => setShow(!show)}>
            <img src="/images/right_arrow.svg" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownWrapper;
