import './index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { getRiskSummary } from '../../../../../shared/utils/services/hooks/riskType';
import { postRisksSummary } from '../../../../../shared/utils/services/hooks/postRisks';
import Button from '../../ui/Button';
import VersionDropdown from '../../ui/VersionDropdown';
import SummaryRiskTabItem from '../summaryRiskTab';

type Props = {
  className?: string;
  heading: string;
  regionId: string;
};

const notifySuccessRisk = () =>
  toast.success('Risk Management data updated successfully!');
const notifyErrorRisk = () =>
  toast.error('Something went wrong. Please try again later!');

const SummaryTabContent: React.FC<Props> = ({ heading, regionId }) => {

  const [data, setData] = useState({
    highRatingDescription: '',
    lowMedRatingDescription: '',
    lowRatingDescription: '',
    medHighRatingDescription: ''
  });
  const {
    data: riskSummaryData,
    isLoading: riskSummaryLoading,
    mutate: getRiskSummaryMut
  } = getRiskSummary(regionId);

  const {
    isLoading: postRiskLoading,
    isSuccess: postRiskSuccess,
    isError: postRiskError,
    mutate: postMut
  } = postRisksSummary(data, regionId);



  const handleSubmit = () => {
    setData({
        highRatingDescription: getSummaryByType().highRatingDescription,
        lowMedRatingDescription: getSummaryByType().lowMedRatingDescription,
        lowRatingDescription: getSummaryByType().lowRatingDescription,
        medHighRatingDescription: getSummaryByType().medHighRatingDescription,
    });
    setTimeout(() => {
      postMut()
    }, 200)
  };

  const getSummaryByType = () => {
    if (riskTabData && riskTabData.length > 0) {
        const obj: any = { }
        riskTabData.map((i: any) => {
            if (i.lowRatingDescription) {
                obj.lowRatingDescription = i.lowRatingDescription;
            }
            if (i.lowMedRatingDescription) {
                obj.lowMedRatingDescription = i.lowMedRatingDescription;
            }
            if (i.medHighRatingDescription) {
                obj.medHighRatingDescription = i.medHighRatingDescription;
            }
            if (i.highRatingDescription) {
                obj.highRatingDescription = i.highRatingDescription;
            }
        })
        return obj; 
    }
  } 


  const getRatingRisk = (): any => {
    if (riskTabData && riskTabData.length > 0) {
      return riskTabData.map((i: any) => {
        const obj: any = { id: i.id, maxValue: i.maxValue, minValue: i.minValue, name: i.name, whatToDoAboutThis: 'NA', }
        i.questions.map((j: any) => {
          if (j.whyThisRiskRating) {
            obj.whyThisRiskRating = j.whyThisRiskRating
          }
          if (j.actionToTake) {
            obj.actionToTake = j.actionToTake
          }
          if (j.actionToTakeDetails) {
            obj.actionToTakeDetails = j.actionToTakeDetails
          }
          if (j.financialImpact) {
            obj.financialImpact = j.financialImpact
          }
          if (j.financialImpactDetails) {
            obj.financialImpactDetails = j.financialImpactDetails
          }
          if (j.impact) {
            obj.impact = j.impact
          }
          if (j.likelihood) {
            obj.likelihood = j.likelihood
          }

          if (j.permanentFixes) {
            obj.permanentFixes = j.permanentFixes
          }
          if (j.permanentFixesDetails) {
            obj.permanentFixesDetails = j.permanentFixesDetails
          }
          if (j.attributionText) {
            obj.attributionText = j.attributionText
          }
          if (j.impactRating) {
            obj.impactRating = j.impactRating
          }
        })
        return obj
      })
    }
  }

  useEffect(() => {
    if (postRiskSuccess) {
      notifySuccessRisk();
      getRiskSummaryMut()
    } else if (postRiskError) {
      notifyErrorRisk();
    }
  }, [postRiskSuccess, postRiskError]);

  useEffect(() => { getRiskSummaryMut() }, [])

  const [riskTabData, setRiskTabData] = useState([])

  const getRiskTabData = (data: any) => {
    setRiskTabData(data);
  }

  return (
    <>
      {riskSummaryLoading ? (
        <>
          <Loader type="Puff" color="#2f2f60" height={50} width={50} />
        </>
      ) : (
        <div id="tab-content-wrapper">
          <div className="title-wrapper">
            <span className="heading-title">{heading}</span>
            <div className="version-wrapper">
              <VersionDropdown />
            </div>
          </div>
          { riskSummaryData?.riskName !== 'Summary' &&
            <div className="tags-main">
              <div className="risk-tab-wrapper">
                <SummaryRiskTabItem getRiskTabData={getRiskTabData} riskTypeData={riskSummaryData} />
                <Button handleSubmit={handleSubmit} />
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default SummaryTabContent;


