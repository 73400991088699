import * as React from 'react';
import { ToastContainer} from 'react-toastify';
import './toast.scss';
import 'react-toastify/dist/ReactToastify.css';

const Toast: React.FC<any> = () => {
  return (
    <div className="toast">
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};

export default Toast;
