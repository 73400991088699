import { FC } from "react"
import RiskInput from "../RiskInput"


interface IRiskLabel {
  handleChangeMin: any;
  handleChangeMax: any;
  maxVlaue: any,
  minVlaue: any
}

const RiskLabel: FC<IRiskLabel> = ({
  handleChangeMin,
  handleChangeMax,
  maxVlaue,
  minVlaue
}) => {
  return (
    <div className="risk-scale-input">
      <RiskInput type="number" value={minVlaue} onTextChange={(e: any) => handleChangeMin(Number(e.target.value))} />
      <RiskInput type="number" value={maxVlaue} onTextChange={(e: any) => handleChangeMax(Number(e.target.value))} />
    </div>
  )
}

export default RiskLabel