import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';

import { fetchIssuedReports } from '../../shared/utils/services/hooks/issued-reports';

import './IssuedReports.scss';
import { useQueryClient } from 'react-query';
import {
  downloadReportByID,
  generateReportById,
} from '../../shared/utils/services/hooks/reports';
import { generateFile } from '../../shared/utils/helper';
import { toast } from 'react-toastify';

const notifySuccessTerms = () =>
  toast.success('Report generation triggered successfully!');
const notifyErrorTerms = () =>
  toast.error('Something went wrong. Please try again later!');

const IssuedReports: React.FC<any> = () => {
  const [formValues, setFormValues] = useState(null);
  const [downloadReport, setDownloadReport] = useState('');
  const [generateReport, setGenerateReport] = useState('');
  const clientQuery = useQueryClient();
  const { data, isLoading, isError, refetch } = fetchIssuedReports(formValues);

  const {
    data: reportData,
    isLoading: reportDownloadLoading,
    isError: reportDownloadError,
  } = downloadReportByID(downloadReport, !!downloadReport);

  const {
    data: generateReportData,
    isLoading: reportGenerateLoading,
    isSuccess: reportGenerateSuccess,
    isError: reportGenerateError,
  } = generateReportById(generateReport);

  const initialValues = {
    Address: '',
    User: '',
    BuyDate: '',
  };

  React.useEffect(() => {
    if (reportGenerateSuccess) {
      notifySuccessTerms();
    } else if (reportGenerateError) {
      notifyErrorTerms();
    }
  }, [reportGenerateError, reportGenerateSuccess]);

  const onSearch = async (values: any) => {
    setFormValues(values);
  };

  useEffect(() => {
    clientQuery.invalidateQueries('FETCH_ISSUED_REPORTS');
  }, [
    (formValues as any)?.Address,
    (formValues as any)?.User,
    (formValues as any)?.BuyDate,
  ]);

  useEffect(() => {
    if (reportData) {
      generateFile(reportData.url, reportData.fileName);
      setDownloadReport('');
    } else if (reportDownloadError) {
      setDownloadReport('');
    }
  }, [reportData, reportDownloadLoading, reportDownloadError]);

  useEffect(() => {
    if (generateReportData) {
      console.log(generateReportData);
      setGenerateReport('');
    } else if (reportGenerateError) {
      setGenerateReport('');
    }
  }, [generateReportData, reportGenerateLoading, reportGenerateError]);

  return (
    <div className="wrap issued-reports" id="issued-reports">
      <div className="ir-form-container">
        <div className="form-wrapper">
          <Formik initialValues={initialValues} onSubmit={onSearch}>
            {({ handleSubmit, handleChange }) => (
              <Form className="ir-form" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      className="ir-form-input"
                      name="Address"
                      type="text"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <Form.Label>User Email</Form.Label>
                    <Form.Control
                      name="User"
                      type="text"
                      onChange={handleChange}
                      className="ir-form-input"
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <Form.Label>Buy Date</Form.Label>
                    <input
                      type="date"
                      name="BuyDate"
                      onChange={handleChange}
                      className="ir-form-input"
                    />
                    {/* <Form.Control
                      name="BuyDate"
                      type="text"
                      onChange={handleChange}
                      className="ir-form-input"
                    /> */}
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="button-primary search-btn"
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div>
        {data?.length > 0 && (
          <Table className="reports-table" striped bordered hover>
            <thead>
              <tr>
                <th>Report Address</th>
                <th className="date-col">BuyDate</th>
                <th className="email-col">User Email</th>
                <th className="action-col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map(
                ({
                  buyDate,
                  pdfDownloadLink,
                  reportAddress,
                  userEmail,
                  id,
                }: any) => (
                  <tr key={id}>
                    <td>{reportAddress}</td>
                    <td>{new Date(buyDate).toDateString()}</td>
                    <td>{userEmail}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="button-primary generate-btn"
                        onClick={() => setGenerateReport(id)}
                      >
                        Generate
                      </Button>
                      <Button
                        variant="primary"
                        className="button-primary"
                        onClick={() => setDownloadReport(id)}
                      >
                        Download
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default IssuedReports;
