import About from '../pages/About';
import Support from '../pages/Support';
import Login from '../pages/Login';
import Home from '../pages/Home';
import TermsAndConditions from '../pages/TermsAndConditions';
import Contact from '../pages/contact';
import NotFoundPage from '../pages/not-found-page';
import ForgotPage from '../pages/Forgot-password';
import SignPage from '../pages/Signup';
import AdminTermsAndCondition from '../pages/Admin/TermsAndConditions';
import AdminSearch from '../pages/Admin/Search';
import SettingPage from '../pages/Setting';
import MyReports from '../pages/MyReports';
import PaymentPage from '../components/payment';
import ViewReport from '../pages/ViewReport';
import Success from '../pages/Success';
import ReportHome from '../pages/ReportDownload/home';
import DownloadPDF from '../pages/ReportDownload/downloadPDF';
import WithHeaderReport from '../pages/ReportDownload';
import WithOutHeaderReport from '../pages/ReportDownload/withOutHeaderReport';
import EmailVerified from '../components/emailverified';
import PageNotFound from '../pages/PageNotFound';
import Version from '../pages/Version';
import VerifyEmail from '../components/verify-email';
import IssuedReportsPage from '../pages/IssuedReports';
import RiskDefinitionspage from '../pages/RiskDefinitions';
import Ratingpage from '../pages/Rating';
import SearchAddressReports from '../components/SearchedAddressReports';
import MapAreaHome from '../components/home/map-area';

export const routes = {
  staticroutes: [
    {
      path: '/landcheck-login',
      component: Login,
      exact: true,
    },
    {
      path: '/',
      component: Home,
      exact: true,
    },
    {
      path: '/verify-email',
      component: VerifyEmail,
      exact: true,
    },
    {
      path: '/email-verified',
      component: EmailVerified,
      exact: true,
    },
    {
      path: '/support',
      component: Support,
      exact: true,
    },
    {
      path: '/view-report',
      component: ViewReport,
      exact: true,
    },
    {
      path: '/view-report/:reportAddress',
      component: ViewReport,
      exact: true,
    },
    {
      path: '/suburb-rating',
      component: MapAreaHome,
      exact: true,
    },
    {
      path: '/landcheck-about',
      component: About,
      exact: true,
    },
    {
      path: '/terms',
      component: TermsAndConditions,
      exact: true,
    },
    {
      path: '/landcheck-contact-support',
      component: Contact,
      exact: true,
    },
    {
      path: '/not-found-page',
      component: NotFoundPage,
      exact: true,
    },
    {
      path: '/forgot-password',
      component: ForgotPage,
      exact: true,
    },
    {
      path: '/signup',
      component: SignPage,
      exact: true,
    },
    {
      path: '/setting',
      component: SettingPage,
      exact: true,
    },
    {
      path: '/my-reports',
      component: MyReports,
      exact: true,
    },
    {
      path: '/payment',
      component: PaymentPage,
      exact: true,
    },
    {
      path: '/admin/search',
      component: AdminSearch,
      exact: true,
    },
    {
      path: '/admin/terms-and-condition',
      component: AdminTermsAndCondition,
      exact: true,
    },
    {
      path: '/success',
      component: Success,
      exact: true,
    },
    {
      path: '/version',
      component: Version,
      exact: true,
    },
    {
      path: '/reports/view/:reportId',
      component: WithHeaderReport,
      exact: true,
    },
    {
      path: '/reports/view/:reportId/:authKey',
      component: WithHeaderReport,
      exact: true,
    },
    {
      path: '/reports/:reportId/:authkey',
      component: WithOutHeaderReport,
      exact: true,
    },
    {
      path: '/reports/home/:reportId/:authkey',
      component: ReportHome,
      exact: true,
    },
    {
      path: '/reports/pdf/:reportId/:authkey',
      component: DownloadPDF,
      exact: true,
    },
    {
      path: '/admin/issuedReports',
      component: IssuedReportsPage,
      exact: true,
    },
    {
      path: '/admin/searchReports',
      component: SearchAddressReports,
      exact: true,
    },
    {
      path: '/riskdefinitionspage',
      component: RiskDefinitionspage,
      exact: true,
    },
    // {
    //   path: '/rating',
    //   component: Ratingpage,
    //   exact: true,
    // },
    {
      path: '*',
      component: PageNotFound,
    },
  ],
};
