import * as React from 'react';
import MetaTags from 'react-meta-tags';
import AboutPage  from '../../components/about';
import FlushData from '../FlushData';
const About: React.FC<any> = () => {
  return (
    <div>
      <MetaTags>
        <title>Landcheck – Natural hazard</title>
        <meta name="description" content="We help homebuyers and homeowners make sound financial decisions regarding residential properties in New Zealand." />
      </MetaTags>
      <FlushData />
      <AboutPage />
    </div>
  );
};

export default About;
