import * as React from 'react';
import { useDispatch } from 'react-redux';
import { flushReportImage } from '../../store/actions/common-actions';
const FlushData: React.FC<any> = () => {
  const dispatch = useDispatch();
  React.useEffect(()=>{
    dispatch(flushReportImage());
  },[])
  return (
    <>
    </>
  );
};

export default FlushData;
