import * as React from 'react';
import { Link } from 'react-router-dom';
type Props = {
  label: string;
  path: string;
  currentPath: string;
  isAbsolutePath?: boolean;
};

const HeaderMenuItem: React.FC<Props> = ({
  path,
  label,
  currentPath,
  isAbsolutePath,
}) => {
  return (
    <li>
      <a href={path} className={currentPath === path ? 'active' : ''}>
        {label}
      </a>
    </li>
  );
};

export default HeaderMenuItem;
