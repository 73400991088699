import * as React from 'react';
import { Link } from 'react-router-dom';
import Input from '../../../shared/ui/components/input';
import Button from '../../../shared/ui/components/button/index';
import TextArea from '../../../shared/ui/components/textarea';
import {
  googleCaptchaSiteKey,
  validEmailRegex,
} from '../../../shared/utils/constants';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactForm } from '../../../shared/utils/types';
import { SubmitContactForm } from '../../../shared/utils/services/hooks/user';
import { toast } from 'react-toastify';
const notifySuccess = () => toast.success('Form submitted successfully!', {autoClose: 6000});
const notifyError = () =>
  toast.error('Something went wrong. Please try again later!');
const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .matches(validEmailRegex, '* Please enter a valid email.')
    .required('Email is required'),
  message: Yup.string().required('Message is required')
});
const Contact: React.FC<any> = () => {
  const [submitForm, setSubmitForm] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>(null);
  const [formValue, setFormValue] = React.useState<any>({
    name: '',
    email: '',
    message: '',
    captcha: '',
  });
  const { data, isLoading, isError } = SubmitContactForm(formValue, submitForm);
  const recaptchaRef = React.useRef(null);
  const formRef = React.useRef(null);

  const [formErros, setFormErros] = React.useState<any>({
    name: '',
    email: '',
    captcha: '',
  });

  const resetForm = () => {
    (formRef.current as any)?.resetForm();
    (recaptchaRef.current as any)?.reset();
  }

  React.useEffect(() => {
    if (data) {
      notifySuccess();
    } else if (isError) {
      notifyError();
    }
  }, [data, isError]);

  const handleSubmit = async (value: ContactForm) => {
    if (formValue.captcha) {
      const formData = value;
      formData.captcha = formValue.captcha
      setFormData(formData);
      setSubmitForm(true);
      resetForm();
    } else {
      setFormErros({ ...formErros, ['captcha']: 'Captcha is required' });
    }
  };

  const updateChange = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    setFormValue({ ...formValue, [targetName]: targetValue });
  };
  return (
    <div>
      <div id="contactinput">
        <div className="wrap">
          <div className="contactpage">
            <h2>Contact us</h2>
            <p>
              If you haven’t been able to solve your issues contact us at
              <a href="mailto:support@landcheck.co.nz"> support@landcheck.co.nz </a>
              Or using the form below.
            </p>
            <div className="contactform">
              <Formik
                validationSchema={ContactSchema}
                onSubmit={(e: any) => handleSubmit(e)}
                initialValues={formValue}
                initialErrors={formErros}
                validateOnBlur={false}
                innerRef={formRef}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form
                    id="legal-information-form"
                    noValidate
                    onSubmit={handleSubmit}
                    onChange={(e) => updateChange(e)}
                    autoComplete="off"
                  >
                    <label>Name</label>
                    <Input
                      className="inpit-search"
                      placeholder="Full Name"
                      value={values.name}
                      name="name"
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="error">{errors.name}</p>
                    <label>Email</label>
                    <Input
                      className="inpit-search"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="error">{errors.email}</p>
                    <label>Message</label>
                    <div>
                      <TextArea
                        value={values.message}
                        name="message"
                        onChange={(e) => handleChange(e)}
                        placeholder="Please include address of your report if relevant."
                      />
                      <p className="error">{errors.message}</p>
                    </div>
                    <div className="recptcha">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        onChange={(e) => {
                          if (e) {
                            setFormValue({ ...formValue, captcha: e });
                          }
                        }}
                        sitekey={googleCaptchaSiteKey}
                      />
                    </div>
                    <p className="error">{formErros.captcha}</p>
                    <Button
                      disabled={isLoading}
                      type="submit"
                      className="button-primary"
                    >
                      Send
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
