import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FinancialModal: React.FC<any> = ({ financialImpact, isPdf }) => {
  return (
    <div>
      {isPdf ? (
        <>
          <div className='risk-header'>
            <img src="/images/icon4.svg" alt=" icon" />
            <h2>Further Technical Information</h2>
          </div>
          <FinancialModalBody financialImpact={financialImpact}></FinancialModalBody>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Further Technical Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FinancialModalBody financialImpact={financialImpact}></FinancialModalBody>
          </Modal.Body>
        </>
      )}
    </div>
  );
};

const FinancialModalBody: React.FC<any> = ({ financialImpact }) => {
  return (
    <>
      {financialImpact && financialImpact.financialImpactDetails ? (
        <div
          dangerouslySetInnerHTML={{
            __html: financialImpact.financialImpactDetails,
          }}
        ></div>
      ) : (
        <>
          <h2>Insurance </h2>
          <p>
            Most insurers in NZ now use{' '}
            <Link to=""> risk based pricing </Link> which means you will
            likely pay higher premiums for this property. You’ll need to
            discuss with your broker to understand the difference.
          </p>
          <h2>Risk based pricing </h2>
          <p>
            In the past, the different environmental risks across the country
            haven’t been fully reflected in the price you pay for your cover.
            Recently though, insurers have changed their pricing. If you live
            in a part of the country that’s more prone to earthquakes,
            flooding or storms your premium may go up. But if you live in a
            region where natural hazards and severe weather events are less
            likely to occur, then it could go down.
          </p>
          <h2>More resources </h2>
          <div className="linkmodal">
            <p>
              <Link to=""> Risk based pricing → </Link>
            </p>
            <p>
              <Link to=""> Insurance calculator → </Link>
            </p>
            <p>
              <Link to=""> Rebuild cost estimator → </Link>
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default FinancialModal;
