import * as React from 'react';
import './index.scss';
import ContactSection from './contact';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
const Contact: React.FC<any> = () => {
  return (
    <div>
      <div id="contact">
        <div className="wrap">
          <div className="contactpage">
            <h1>Frequently asked questions</h1>
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <p>I never received a report email </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Can't find the email in your inbox? Please try:</p>
                  <div className="topul">
                    <div className="listp">
                      <p>
                        1. Checking your junk folder (this will be the case most
                        of the time)
                      </p>
                      <p>2. Check you used the correct email address?</p>
                      <p>
                        3. If neither of those work, we can look it up for you.
                        Please email{' '}
                        <a href="mailto: support@landcheck.co.nz">
                          support@landcheck.co.nz
                        </a>{' '}
                        with your name, email, and the address of the report and we’ll get that fixed for you. 
                      </p>
                    </div>
                  </div>
                  {/* <div className="secondul">
                    <p>
                      Try viewing the report Online at the address you used to make your
                      account. If this doesn’t work, please email {' '}
                      <a href="mailto: support@landcheck.co.nz">
                        support@landcheck.co.nz
                      </a>{' '}
                      with your name, email, and the address of the report and we’ll sort it for you.
                    </p>
                  </div> */}
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <p> My report was blank/missing information </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="second-question">
                    <p>Let's fix that right away. Can you please try:</p>
                    <div className="topul">
                      <div className="listp">
                        <p>1. Refreshing your browser </p>
                        <p>2. Check you have the most recent PDF software</p>
                        <p>3. Try viewing the report online at the address you used to make your account</p>
                        <p>
                          4. If neither of those work, we can look it up for you.
                          Please email{' '}
                          <a href="mailto: support@landcheck.co.nz">
                            support@landcheck.co.nz
                          </a>{' '}
                          with your name, email, and the address of the report and we’ll get that fixed for you. 
                        </p>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <p> How long does a report take to create?</p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                  In times of heavy demand, it may take a few minutes to come through. 
                  If it doesn’t appear in your inbox, please let us know at{' '}
                    <a href="mailto: support@landcheck.co.nz">
                      support@landcheck.co.nz
                    </a>{' '}
                    and we’ll resolve it for you as soon as we can.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <p> If you are unable to find an address</p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Please contact us at{' '}
                    <a href="mailto: support@landcheck.co.nz">
                      support@landcheck.co.nz
                    </a>{' '}
                    with your name, email and the address you’re looking
                    for and we’ll get back to you as soon as we can.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
          <ContactSection />
        </div>
      </div>
    </div>
  );
};

export default Contact;
