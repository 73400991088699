export const config = {
  _API: process.env.REACT_APP_API,
  _adminAPI: process.env.REACT_APP_ADMIN_API,
  auth0_domain: process.env.REACT_APP_AUTH0_DOMIAN,
  auth0_clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  dataLintzServiceURL: process.env.REACT_APP_DATA_LINT_SERVICE_URL,
  serviceAPIKey: process.env.REACT_APP_SERVICE_API_KEY,
  stripKey: process.env.REACT_APP_STRIPE_KEY,
  parcelKeyForReport: 50804,
  keyForMapArea: 113764,
  landcheckWebApp: process.env.REACT_APP_LANDCHECK_WEB_APP,
};

export const editorModules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    // [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    // ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    // ['clean'],
  ],
};

export const editorFormats = [
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'color',
  'background',
];
