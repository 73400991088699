import * as React from 'react';
import './index.scss';
import SearchPropertyImage from '../../../assets/images/search-image.png';
const SearchProperty: React.FC<any> = () => {
  return (
    <div id="search-property">
      <div className="wrap">
        <div className="row">
          {/* Left content */}
          <div className="half-width-img">
            <img src={SearchPropertyImage} alt="search-image" />
          </div>
          <div className="half-width">
            <div className="steps">Step 1</div>
            <h3>Search your property</h3>
            <p className="stepcontant">
              Search and select the property you’re interested in.
            </p>
            <br />
            <p className='question'>What parts of New Zealand do we cover?</p>
            <p>
              Landcheck is available for Auckland and we are working towards
              releasing Hamilton, Tauranga, Wellington and Christchurch soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchProperty;
