import * as React from 'react';
import Search from '../../shared/ui/components/search';
import Button from '../../shared/ui/components/button/index';
import { useState } from 'react';
import { getLatLong } from '../../shared/utils/helper';
import { flushReportImage } from '../../store/actions/common-actions';
import { Location } from '../../shared/utils/types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  flushLocationSearch,
  saveLocation,
} from '../../store/actions/location-action';
import './index.scss';
import SearchAddress from '../../shared/ui/components/search';
import AboutIcon from '../../assets/images/AboutIcon.svg';


const notifyError = () => toast.error('Please select a valid address!');

const About: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<Location[]>();
  console.log(searchResults, searchText);
  const handleSearchClick = async (e: any) => {
    e.preventDefault();
    if (
      searchResults?.length === 1 &&
      searchResults[0].formatted_address?.toLowerCase().trim() ===
      searchText.toLowerCase().trim()
    ) {
      if (searchResults[0].coveredLocation) {
        const addressLatLong = await getLatLong(
          searchResults[0].formatted_address
        );
        dispatch(saveLocation(searchResults[0], addressLatLong));
        history.push(`/view-report`);
      } else {
        history.push(`/not-found-page`);
      }
    } else {
      notifyError();
    }
  };
  React.useEffect(() => {
    if (!history.location.hash.includes('access_token')) {
      dispatch(flushLocationSearch());
      dispatch(flushReportImage());
    }
  }, []);
  return (
    <div>
      <div id="About">
        <div className="wrap">
          <div className="termspage">
            <div className='aboutHeader'>
              <h1>About Landcheck</h1>
              <a href="https://www.tonkintaylor.co.nz/" target="_blank">
                <img src={AboutIcon} alt="logo" />
              </a>
            </div>


            <div className="termpera">
              <p className="about-para">
                The idea of Landcheck came from wanting to help New Zealanders
                better understand the natural hazard risk their properties are
                exposed to.
              </p>
            </div>
            <div className="termpera">
              <p>
                With more than 40 years’ experience in natural hazard response
                and risk management, Tonkin + Taylor has a proven capability at
                supporting clients and communities through all stages of
                disaster resilience for events of almost every size. Through
                this work, Tonkin + Taylor experts identified how difficult it
                is for New Zealanders to access information about natural hazard
                risks.
              </p>
            </div>
            <div className="termpera">
              <p>
                Worldwide, the insurance industry is moving more towards
                risk-based pricing for home and contents insurance, based on
                exposure to natural hazard risk. As extreme weather events
                become more frequent and the consequences of these events more
                severe, the financial impact on New Zealanders could be
                significant.
              </p>
            </div>
            <div className="termpera">
              <p>
                Prior to the development of Landcheck, the only way for kiwis to
                access natural hazard information was through complex LIM
                reports, or by engaging natural hazard expertise to undertake a
                site-specific assessment. Landcheck does the hard work for you
                as an affordable, more accessible way of understanding natural
                hazard risks for the residential property market.
              </p>
            </div>
            <div className="termpera">
              <p>
                The database that underpins Landcheck covers the New Zealand
                property market. This data comes from diverse public information
                that is then clarified through smart algorithms developed by
                some of the most respected natural hazard experts in New
                Zealand. The results are explained in an automated PDF Landcheck
                report to provide understandable and fast natural hazard
                information to buyers.
              </p>
            </div>
            <div className="termpera">
              <p>
                We believe that informing people of the risks pertaining to
                their properties will help them make informed decisions around
                property ownership and its ongoing risks and costs. By
                understanding the risks up front, people can avoid nasty
                financial surprises later.
              </p>
            </div>
          </div>

          <div className=" unsure-search">
            <div className="full-width">
              <div className="search-home homefootersearch">
                <SearchAddress
                  page="home"
                  className="main-banner-search"
                  searchText={searchText}
                  setSearchText={setSearchText}
                  searchResults={searchResults}
                  setSearchResults={setSearchResults}
                  id="homeSearch3"
                />
                <Button
                  className="button-primary ml-10"
                  onClick={handleSearchClick}
                  disabled={!searchText}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
