import * as React from 'react';
import AdminTermsAndConditions from '../../../components/admin/termsAndCondition';
import RestrictedRoute from '../../../shared/wrapper/restricted';

const AdminTermsAndCondition: React.FC<any> = () => {
  return (
    <div>
      <RestrictedRoute>
        <AdminTermsAndConditions />
      </RestrictedRoute>
    </div>
  );
};

export default AdminTermsAndCondition;
