// eslint-disable-next-line
export const saveSelectedReport = (report: any) => ({
  type: 'SAVE_SELECTED_REPORT',
  payload: report,
});





