import * as React from 'react';
import MetaTags from 'react-meta-tags';
import BannerHome from '../../components/home/banner/index';
import RiskHome from '../../components/home/risk';
import TrustedHome from '../../components/home/trusted-report-info';
import HazardReport from '../../components/home/hazard-report';
import SearchProperty from '../../components/home/search-property';
import PreviewProperty from '../../components/home/preview-property';
import MindRest from '../../components/home/mind-rest';
import StressProperty from '../../components/home/save-stress';
import TestimonialsProperty from '../../components/home/testimonials';
import UnsurePoints from '../../components/home/unsure-points';
import MapAreaHome from '../../components/home/map-area';
import { useDispatch, useSelector } from 'react-redux';
import { saveSelectedReport } from '../../store/actions/reports-actions';
import { flushReportImage } from '../../store/actions/common-actions';
import { getAccount } from '../../shared/utils/services/hooks/account';
import { useHistory } from 'react-router';
const Home: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(saveSelectedReport({}));
    // dispatch(flushReportImage());
  }, []);
  return (
    <>
      <MetaTags>
        <title>Landcheck – Is your property at risk from natural hazards?</title>
        <meta name="description" content="The affordable way to better understand the natural hazards that a property is exposed to in the Auckland region." />
      </MetaTags>
      <div>
        <BannerHome />
        <RiskHome />
        <TrustedHome />
        <MapAreaHome /> 
        <HazardReport />
        <SearchProperty />
        <PreviewProperty />
        <MindRest />
        <StressProperty />
        <TestimonialsProperty />
        <UnsurePoints />
      </div>
    </>
  );
};

export default Home;
