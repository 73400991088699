import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PermantFixesModal: React.FC<any> = ({ permanentDetails, isPdf }) => {
  return (
    <div>
      {isPdf ? (
        <>
          <div className='risk-header'>
            <img src="/images/icon3.svg" alt=" icon" />
            <h2>Mitigation Measures</h2>
          </div>
          <PermantFixesModalBody permanentDetails={permanentDetails}></PermantFixesModalBody>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Mitigation Measures</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PermantFixesModalBody permanentDetails={permanentDetails}></PermantFixesModalBody>
          </Modal.Body>
        </>
      )}
    </div>
  );
};

const PermantFixesModalBody: React.FC<any> = ({ permanentDetails }) => {
  return (
    <>
      {permanentDetails && permanentDetails.permanentFixesDetails ? (
        <div
          dangerouslySetInnerHTML={{
            __html: permanentDetails.permanentFixesDetails,
          }}
        ></div>
      ) : (
        <>
          <ul>
            <li>
              Sandbagging edges of building, blocking doors and vents,
              assuming sufficient warning of flood: $1000+
            </li>
            <li>
              Earth bund: A landscaped wall around perimeter to prevent water
              entry onto property $20,000+
            </li>
            <li>
              Dry flood proofing: A membrane cover that can be raised manually
              if flood is expected $25,000+
            </li>
          </ul>
          <h2>More resources</h2>
          <div className="linkmodal">
            <p>
              <Link to=""> EQC flood mitigation guide →</Link>
            </p>
            <p>
              <Link to=""> Statement from Insurance council → </Link>
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default PermantFixesModal;
