import * as React from 'react';
import './index.scss';
import RiskImage from '../../../assets/images/risk-image.png';
import InfoImage from '../../../assets/images/Info-image.png';
import Source1 from '../../../assets/images/Source1.svg';
import Source2 from '../../../assets/images/Source2.svg';
import Source3 from '../../../assets/images/Source3.svg';
import Source4 from '../../../assets/images/Source4.svg';


const TrustedHome: React.FC<any> = () => {
  return (
    <div id="trusted-home">
      <div className="wrap">
        <div className="row">
          {/* Left content */}
          <div className="half-width">
            <img src={RiskImage} alt="risk-image" />
            <h3>Know the natural hazard risks your property is exposed to.</h3>
            <p>
              Due to its geography and location, New Zealand is significantly
              exposed to a range of natural hazards and the effects of climate
              change will exacerbate a number of these. Make yourself aware of
              these risks for <b>only $49.</b>
            </p>
            <div className='sources'>
              Our sources: 
            </div>
            <div className='source-img'>
            <img src={Source1} alt="source" />
            <img src={Source2} alt="source" />
            <img src={Source3} alt="source" />
            <img src={Source4} alt="source" />
            </div>
          </div>
          <div className="half-width-img">
            <img src={InfoImage} alt="info" />
            <h3>Information you can understand</h3>
            <p>
              Council LIM reports can be hard to understand and don't have
              Landcheck's crystal clear focus on natural hazard risk.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedHome;
