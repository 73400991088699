import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ActionModal from '../modals/action-modal';
import FinancialModal from '../modals/financial-modal';
import PermantFixesModal from '../modals/permant-fixes-modal';
import { riskRangeClassNames } from './helper';
import './riskp.scss';

const pageBreak = {
  'page-break-after': 'always',
} as React.CSSProperties;

const RiskPreview: React.FC<any> = ({
  handleModal,
  data,
  height,
  isPdf,
  setScroll,
}) => {
  const hazardRefs: any = useRef([]);

  useEffect(() => {
    const onScroll = () => {
      hazardRefs.current.forEach((section: any) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollY >= sectionTop - 300) {
          setScroll(section.getAttribute('id'));
        } else if (scrollY < 900) {
          setScroll('Summary');
        }
      });
    };

    if (setScroll) {
      document.addEventListener('scroll', onScroll);
      return () => {
        document.removeEventListener('scroll', onScroll);
      };
    }
  }, [setScroll]);

  return (
    <>
      {data &&
        data[0].risks &&
        data[0].risks.length > 0 &&
        data[0].risks.map((item: any, index: number) => {
          return (
            <div key={item.id} id="riskp" style={{ height: height && height }}>
              <div
                className="wrap wrap-risk-level"
                id={item.riskType.replace(' ', '')}
                ref={(el) => (hazardRefs.current[index] = el)}
              >
                <div
                  className={
                    item.riskValue?.rating?.name
                      ? 'report-priview ' +
                        item.riskValue.rating.name
                          .replace('-', '')
                          .replace(' ', '')
                          .toLowerCase()
                      : 'report-priview'
                  }
                >
                  <h3>
                    {item.riskValue.rating.name == 'Low-M'
                      ? 'Low'
                      : item.riskValue.rating.name}
                  </h3>
                  <h2>{item.riskName}</h2>
                  <div className="rp-contant">
                    <p>{item.riskValue.rating.riskText}</p>
                    <div className="rp-lbtn">
                      {item.riskValue.rating.propertyValue !== -1 && (
                        <div className="property">
                          <img src="/images/pricon.svg" alt=" This property" />
                          This property
                        </div>
                      )}
                      {item.riskValue.rating.areaValue !== -1 && (
                        <div className="pr-l">
                          <img src="/images/location.svg" alt="location" />
                          {data[0].address.suburb}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flooding">
                  <div className="flodingMain">
                    {
                      <img
                        src="/images/location.svg"
                        style={{
                          display: !getRatingValue(
                            item.riskValue.rating.areaValue,
                            item?.riskValue?.rating?.riskRange?.length
                          )
                            ? 'none'
                            : '',
                          left: getRatingValue(
                            item.riskValue.rating.areaValue,
                            item?.riskValue?.rating?.riskRange?.length
                          )
                            ? getRatingValue(
                                item.riskValue.rating.areaValue,
                                item?.riskValue?.rating?.riskRange?.length
                              )
                            : 0,
                        }}
                        alt="location"
                        className="suborg"
                      />
                    }
                    <img
                      src="/images/pricon.svg"
                      style={{
                        display: !getRatingValue(
                          item.riskValue.rating.propertyValue,
                          item?.riskValue?.rating?.riskRange?.length
                        )
                          ? 'none'
                          : '',
                        left: getRatingValue(
                          item.riskValue.rating.propertyValue,
                          item?.riskValue?.rating?.riskRange?.length
                        )
                          ? getRatingValue(
                              item.riskValue.rating.propertyValue,
                              item?.riskValue?.rating?.riskRange?.length
                            )
                          : 0,
                      }}
                      alt=" This property"
                    />
                    <RiskRange
                      items={item?.riskValue?.rating?.riskRange || []}
                    />
                  </div>
                </div>
                <div className="pr-rating">
                  <div className="pr-rating-heading">
                    <h2>Hazard rating</h2>
                    {/* <p>{item.riskValue.whyThisRiskRating.split(':')[0]}: </p>
                    <ul>
                      <li>{item.riskValue.whyThisRiskRating.split(':')[1]}</li>
                    </ul> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.riskValue.whyThisRiskRating,
                      }}
                    ></div>
                    <h3>Likelihood:</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.riskValue.likelihood,
                      }}
                    ></div>
                    <h3>Impact:</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.riskValue.impact,
                      }}
                    ></div>
                  </div>
                  <div className="maparea">
                    <div className="maphead">
                      <div className="rp-contant">
                        <div>
                          <h3>Hazard map</h3>
                          <div>
                            <p>
                              {!item.riskValue?.hazardOverlay
                                ? 'No hazard overlay to display'
                                : ''}
                            </p>
                          </div>
                        </div>
                        <div className="rp-lbtn">
                          {item.reportRiskLegends &&
                            item.reportRiskLegends.length > 0 &&
                            item.reportRiskLegends.map((legend: any) => (
                              <React.Fragment key={legend.reportRiskLegendId}>
                                <div className="property">
                                  <img
                                    src={
                                      'data:' +
                                      legend.contentType +
                                      ';base64,' +
                                      legend.imageData
                                    }
                                    alt=" This property"
                                  />
                                  {legend.label}
                                </div>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="mainmap">
                      <img src={item.riskValue.imageUrl} alt=" map" />
                    </div>
                    <div
                      className="mainmap-caption"
                      dangerouslySetInnerHTML={{
                        __html: item.riskValue.attributionText,
                      }}
                    ></div>
                  </div>
                  <div className="mapcontant">
                    <div className="row">
                      {!isPdf && (
                        <div className="map1">
                          <img src="/images/icon4.svg" alt=" icon" />
                          <div>
                            <h3>Further Technical Information</h3>
                            <p>{item.riskValue.financialImpact}</p>
                          </div>
                          <Link
                            to="#"
                            onClick={() =>
                              handleModal(true, 'financialModal', index)
                            }
                          >
                            More Detail
                            <img src="/images/chevron-right.svg" alt=" icon" />
                          </Link>
                        </div>
                      )}
                      {!isPdf && (
                        <div className="map1">
                          <img src="/images/icon2.svg" alt=" icon" />
                          <div>
                            <h3>Action to take</h3>
                            <p>{item.riskValue.actionToTake}</p>
                          </div>
                          <Link
                            to="#"
                            onClick={() =>
                              handleModal(true, 'actionsModal', index)
                            }
                          >
                            More Detail
                            <img src="/images/chevron-right.svg" alt=" icon" />
                          </Link>
                        </div>
                      )}
                      {!isPdf && (
                        <div className="map1">
                          <img src="/images/icon3.svg" alt=" icon" />
                          <div>
                            <h3>Mitigation Measures</h3>
                            <p>{item.riskValue.permanentFixes}</p>
                          </div>
                          <Link
                            to="#"
                            onClick={() =>
                              handleModal(true, 'permantfixesModal', index)
                            }
                          >
                            More Detail
                            <img src="/images/chevron-right.svg" alt=" icon" />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  {isPdf && (
                    <div className="mapcontantDetails">
                      <div style={pageBreak}> </div>
                      <div>
                        <FinancialModal
                          financialImpact={item.riskValue}
                          isPdf={true}
                        ></FinancialModal>
                      </div>
                      <div className="mapcontantModal">
                        <ActionModal
                          actionDetails={item.riskValue}
                          isPdf={true}
                        ></ActionModal>
                      </div>
                      <div className="mapcontantModal">
                        <PermantFixesModal
                          permanentDetails={item.riskValue}
                          isPdf={true}
                        ></PermantFixesModal>
                      </div>
                    </div>
                  )}
                  {/* <div className="mapdetails">
                    <div className="maplocation">
                      <p>Source: {item.riskValue.source}</p>
                    </div>
                  </div> */}
                  <div className="rp-disclaimer">
                    <p>
                      Please note: Occasionally, Auckland Council, and our other
                      sources will update their data. We regularly check that
                      the data Landcheck uses is up to date and carry out a
                      comprehensive review every 6 months. Any report that was
                      purchased prior to an update may include outdated
                      information.
                    </p>
                  </div>
                </div>
              </div>
              {/* Page break for pdf generation */}
              <div style={pageBreak}> </div>
            </div>
          );
        })}
    </>
  );
};

export default RiskPreview;
interface IRiskRange {
  items: any;
}

export const RiskRange: React.FC<IRiskRange> = ({ items }) => {
  const [riskItems, setRiskItems] = React.useState([]);

  React.useEffect(() => {
    const array: any = [];
    if (items.length > 0) {
      riskRangeClassNames.map((i: any) => {
        array.push({
          ...i,
          name: getNameOfRisk(i),
        });
      });
      setRiskItems(array);
    }
  }, [items]);

  const getNameOfRisk = (item: any) => {
    let classname = '';
    items.map((i: any) => {
      if (i.name === item.key) {
        classname = i.name;
      }
      if (i.name == 'Low-M' && item.key == 'Low-Med') {
        classname = 'Low';
      }
    });
    return classname;
  };

  return (
    <>
      {riskItems.map(
        (item: any) =>
          item.name && (
            <div key={item.key} className={item.className}>
              <p>{item.name}</p>
            </div>
          )
      )}
    </>
  );
};
export const getRatingValue = (ratingValue: any, riskRangelength: any) => {
  const totalOfRiskRangelength: number = Number(riskRangelength) * 10;
  const leftValue = (ratingValue / totalOfRiskRangelength) * 100;

  if (leftValue < 0) {
    return '';
  }
  if (leftValue === 0 || leftValue < 15) {
    return `calc(${leftValue}%)`;
  }
  return `calc(${leftValue}% - 22px)`;
};
