import StripCard from '../../shared/ui/components/stripe-card';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../shared/ui/components/loader';
import { useEffect, useState } from 'react';
import { buyReport } from '../../shared/utils/services/hooks/reports';
import { flushReportImage } from '../../store/actions/common-actions';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { saveSelectedReport } from '../../store/actions/reports-actions';

const notifyErrorBuy = () =>
  toast.error('Something went wrong while processing payment!');
const notifySuccess = () => toast.success('Payment processed successfully!');

const PaymentPage: React.FC<any> = () => {


  const {userInfo, isAnonymousUser, guestEmail}: any = useSelector<any>(
    (state: any) => state.user
  );

  const [loadingReport, setLoadingReport] = useState(false);
  const { reportImage, propertyId, placeName }: any = useSelector<any>(
    (state: any) => state.common
  );

  if (!placeName) {
    return (
      <Redirect to="/" />
    )
  }

  const placeData = placeName.formatted_address.split(',');
  const title = placeData[0];
  const dispatch = useDispatch();
  const history = useHistory();

  const [paymentId, setPaymentId] = useState('');
  const [saveReport, setSaveReport] = useState(false);

  useEffect(() => {
    setSaveReport(true);
  }, [paymentId]);
  const {
    data: buyReportData,
    isLoading,
    isError,
  } = buyReport(
    saveReport,
    {
      streetAddress: placeName.formatted_address.split(',')[0],
      suburb: placeName.terms[placeName.terms.length - 3],
      city: placeName.city1,//terms[placeName.terms.length - 2],
      paymentMethodId: paymentId,
      //coverImage: reportImage,
      propertyId: propertyId.toString(),
      ...isAnonymousUser && {
        isAnonymous: isAnonymousUser,
        emailId: guestEmail
      }
      
    },
    paymentId
  );

  useEffect(() => {
    let timeoutPeriod = 4000;
    if (isAnonymousUser)
      timeoutPeriod = 25000;
    if (buyReportData) {
      notifySuccess()
      setTimeout(() => {
        if (isAnonymousUser) {
            dispatch(saveSelectedReport(buyReportData))
            history.push('/view-report')
          // history.push(`/reports/${buyReportData.reportId}/${buyReportData.authKey}`);
        } else {
          history.push('/my-reports', { isNewReport: true });
        }
        dispatch(flushReportImage());
      }, timeoutPeriod);
    } else if (isError) {
      notifyErrorBuy();
      setTimeout(() => {
        history.push('/payment');
      }, 4000);
      setSaveReport(false);
      setLoadingReport(false)
    }
  }, [buyReportData, isLoading, isError]);

  const Loadering = () => {

    const dis = () => (<span>We are processing your report, it may take up to
      5 min. We'll send it to your email {isAnonymousUser ? <b>{guestEmail}</b> : <b>{userInfo?.user?.email}</b>}
      {" "} when it's ready.</span>)

    return (
      <div className="loaderCenter">
        <Loader
          isLoading={loadingReport}
          title={' '}
          subTitle={title}
          description={dis()}
        >
        </Loader>
      </div>
    )

  }
  return (
    <>
      {loadingReport ? (
        <Loadering />
      ) : (
        <div id="payment">
          <div className="wrap">
            <div className="innerwrap">
              <div className="login-inner">
                <h1>Buy report </h1>
                <div className="row">
                  <div className="half-width">
                    <div className="paymentmain">
                      <div className="head">
                        <h2>Payment details</h2>
                      </div>
                      <StripCard
                        reportImage={reportImage}
                        placeName={placeName}
                        setLoadingReport={() => setLoadingReport(true)}
                        setPaymentId={setPaymentId}
                      />
                    </div>
                    <div className='payment-secure'>
                      <img src="/images/secure_payments.svg" />
                    </div>
                  </div>

                  <div className="half-width-img">
                    <div className="sign-img">
                      {reportImage ? (
                        <img src={reportImage} />
                      ) : (
                        <img src="/images/signup.jpg" />
                      )}
                        <div className="payment-batch-icon">
                          {/* <img
                            src="/images/floodrelief.svg"

                          /> */}
                        </div>
                      <div className="sign-img-contant">
                        <p>Purchase report</p>
                        <div className="b-img-contant">
                          <h3>{placeName.formatted_address}</h3>
                          <p>$49</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentPage;
