import { useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const fetchSearchAddressReports = (address: any) => {
  const adminCallApi = adminUseAxios();

  return useQuery(
    ['FETCH_ADDRESS_SEARCH_ISSUED_REPORTS', address],
    ({ queryKey }) => {
      const [, address] = queryKey;
      return adminCallApi({
        method: 'GET',
        url: `/AddressRequest?searchText=${address}`,
      }).then((data: any) => data);
    },
    {
      // enabled: Boolean(address),
      cacheTime: 0,
      staleTime: 2000,
    }
  );
};

