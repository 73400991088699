import * as React from 'react';
import './index.scss';
import StressImage from '../../../assets/images/stress-money.png';
import CheckMark from '../../../assets/images/check.png';
import Button from '../../../shared/ui/components/button/index';
import SearchAddress from '../../../shared/ui/components/search';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Location } from '../../../shared/utils/types';
import { getLatLong } from '../../../shared/utils/helper';
import { saveLocation } from '../../../store/actions/location-action';

const notifyError = () => toast.error('Please select a valid address!');

const StressProperty: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { location }: any = useSelector<Location>(
    (state: any) => state.location
  );
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<Location[]>();

  const handleSearchClick = async (e: any) => {
    e.preventDefault();
    if (
      searchResults?.length === 1 &&
      searchResults[0].formatted_address?.toLowerCase().trim() ===
        searchText.toLowerCase().trim()
    ) {
      if (searchResults[0].coveredLocation) {
        const addressLatLong = await getLatLong(
          searchResults[0].formatted_address
        );
        dispatch(saveLocation(searchResults[0], addressLatLong));
        history.push(`/view-report`);
      } else {
        history.push(`/not-found-page`);
      }
    } else {
      notifyError();
    }
  };

  return (
    <div id="stress-property">
      <div className="wrap-full">
        <div className="row">
          {/* Left content */}
          <div className="half-width-img">
            <img src={StressImage} alt="search-image" />
          </div>
          <div className="half-width">
            <div>
              <p className="steps"> Why purchase </p>
            </div>
            <h2>Save stress and money</h2>
            <p>
              Failing to clearly understand your property’s risk from natural
              hazards could lead to nasty surprises like higher insurance
              premiums, and repair and rebuild costs. Not to mention the stress
              these losses can bring. Avoid these and other unexpected costs
              down the line by understanding things up front.
            </p>
            <div className="batch-img">
            <br />
            <ul>
              <li>
                <span>$</span>Insurance claim excess
              </li>
              <li>
                <span>$$</span>Non-EQC cover excess e.g. landscape damage
              </li>
              <li>
                <span>$$$</span>Insurance premium increases
              </li>
            </ul>
            <br />
            <ul>
              <li>
                <span>
                  <img src={CheckMark} alt="check" />
                </span>
                <b>Landcheck Report: $49</b>
              </li>
            </ul>
            </div>
            <div className="search-home homefootersearch">
              <SearchAddress
                page="home"
                className="main-banner-search"
                searchText={searchText}
                setSearchText={setSearchText}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                id="homeSearch2"
              />
              <Button
                className="button-primary ml-10"
                onClick={handleSearchClick}
                disabled={!searchText}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StressProperty;
