import * as React from 'react';
import MyReportsPage from '../../components/my-reports';
import ProtectRoute from '../../shared/wrapper';
const MyReports: React.FC<any> = () => {
  return (
    <div>
      <ProtectRoute>
        <MyReportsPage />
      </ProtectRoute>
    </div>
  );
};

export default MyReports;
