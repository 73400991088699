import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../ui/components/loader';
import { getAccount } from '../../utils/services/hooks/account';

const RestrictedRoute: React.FC<any> = ({ children }) => {
  const history = useHistory();
  const { authUser, isAnonymousUser }: any = useSelector<any>((state: any) => state.user);
  const {
    data: getAccounTData,
    isLoading: getAccounbtLoading,
    isError: getAccountError,
  } = getAccount(authUser, !isAnonymousUser);
  
if(getAccounbtLoading){
  return<Loader isLoading={true}/>
}
  return <>{getAccounTData?.user?.isAdmin ? children : history.push('/')}</>;
};

export default RestrictedRoute;
