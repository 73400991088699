import { useEffect, useState } from 'react';
import EditorToolbar, {
  modules,
  modules2,
  modules3,
  modules4,
  modules5,
  modules6,
  modules7,
  QuillToolbar2,
  QuillToolbar3,
  QuillToolbar4,
  QuillToolbar5,
  QuillToolbar6,
  QuillToolbar7,
} from '../../../../common/EditorToolbar';
import RiskTabContentBox from './Content';
import './index.scss';

type Props = {
  label: string;
  currentPath: string;
  onclick: () => void;
};

const RiskTabItem: React.FC<any> = (props) => {

  const [toggleState, setToggleState] = useState<string>('Low');

  const [idLow, setIdLow] = useState<string>('00000000-0000-0000-0000-000000000000');
  const [idLowMed, setIdLowMed] = useState<string>('00000000-0000-0000-0000-000000000000');
  const [idMedium, setIdMedium] = useState<string>('00000000-0000-0000-0000-000000000000');
  const [idMedHigh, setIdMedHigh] = useState<string>('00000000-0000-0000-0000-000000000000');
  const [idHigh, setIdHigh] = useState<string>('00000000-0000-0000-0000-000000000000');

  const [whyRiskTakingLowMed, setWhyRiskTakingLowMed] = useState<string>('');
  const [whyRiskTaking, setWhyRiskTaking] = useState<string>('');
  const [whyRiskTakingMedium, setWhyRiskTakingMedium] = useState<string>('');
  const [whyRiskTakingMedHigh, setWhyRiskTakingMedHigh] = useState<string>('');
  const [whyRiskTakingHigh, setWhyRiskTakingHigh] = useState<string>('');

  const [likelihood, setLikelihood] = useState<string>('');
  const [likelihoodLowMed, setLikelihoodLowMed] = useState<string>('');
  const [likelihoodMedium, setLikelihoodMedium] = useState<string>('');
  const [likelihoodMedHigh, setLikelihoodMedHigh] = useState<string>('');
  const [likelihoodHigh, setLikelihoodHigh] = useState<string>('');

  const [impact, setImpact] = useState<string>('');
  const [impactLowMed, setImpactLowMed] = useState<string>('');
  const [impactMedium, setImpactMedium] = useState<string>('');
  const [impactMedHigh, setImpactMedHigh] = useState<string>('');
  const [impactHigh, setImpactHigh] = useState<string>('');

  const [financialImpact, setFinancialImpact] = useState<string>('');
  const [financialImpactLowMed, setFinancialImpactLowMed] = useState<string>('');
  const [financialImpactMedium, setFinancialImpactMedium] = useState<string>('');
  const [financialImpactMedHigh, setFinancialImpactMedHigh] = useState<string>('');
  const [financialImpactHigh, setFinancialImpactHigh] = useState<string>('');

  const [actionToTake, setActionToTake] = useState<string>('');
  const [actionToTakeLowMed, setActionToTakeLowMed] = useState<string>('');
  const [actionToTakeMedium, setActionToTakeMedium] = useState<string>('');
  const [actionToTakeMedHigh, setActionToTakeMedHigh] = useState<string>('');
  const [actionToTakeHigh, setActionToTakeHigh] = useState<string>('');

  const [permanentFixes, setPermanentFixes] = useState<string>('');
  const [permanentFixesLowMed, setPermanentFixesLowMed] = useState<string>('');
  const [permanentFixesMedium, setPermanentFixesMedium] = useState<string>('');
  const [permanentFixesMedHigh, setPermanentFixesMedHigh] = useState<string>('');
  const [permanentFixesHigh, setPermanentFixesHigh] = useState<string>('');

  const [financialImpactDetails, setFinancialImpactDetails] = useState<string>('');
  const [financialImpactDetailsLowMed, setFinancialImpactDetailsLowMed] = useState<string>('');
  const [financialImpactDetailsMedium, setFinancialImpactDetailsMedium] = useState<string>('');
  const [financialImpactDetailsMedHigh, setFinancialImpactDetailsMedHigh] = useState<string>('');
  const [financialImpactDetailsHigh, setFinancialImpactDetailsHigh] = useState<string>('');

  const [actionToTakeDetails, setActionToTakeDetails] = useState<string>('');
  const [actionToTakeDetailsLowMed, setActionToTakeDetailsLowMed] = useState<string>('');
  const [actionToTakeDetailsMedium, setActionToTakeDetailsMedium] = useState<string>('');
  const [actionToTakeDetailsMedHigh, setActionToTakeDetailsMedHigh] = useState<string>('');
  const [actionToTakeDetailsHigh, setActionToTakeDetailsHigh] = useState<string>('');

  const [permanentFixesDetails, setPermanentFixesDetails] = useState<string>('');
  const [permanentFixesDetailsLowMed, setPermanentFixesDetailsLowMed] = useState<string>('');
  const [permanentFixesDetailsMedium, setPermanentFixesDetailsMedium] = useState<string>('');
  const [permanentFixesDetailsMedHigh, setPermanentFixesDetailsMedHigh] = useState<string>('');
  const [permanentFixesDetailsHigh, setPermanentFixesDetailsHigh] = useState<string>('');

  const [attributionText, setAttributionText] = useState<string>('');
  const [attributionTextLowMed, setAttributionTextLowMed] = useState<string>('');
  const [attributionTextMedium, setAttributionTextMedium] = useState<string>('');
  const [attributionTextMedHigh, setAttributionTextMedHigh] = useState<string>('');
  const [attributionTextHigh, setAttributionTextHigh] = useState<string>('');

  const [impactRating, setImpactRating] = useState<number>(0);
  const [impactRatingLowMed, setImpactRatingLowMed] = useState<number>(0);
  const [impactRatingMedium, setImpactRatingMedium] = useState<number>(0);
  const [impactRatingMedHigh, setImpactRatingMedHigh] = useState<number>(0);
  const [impactRatingHigh, setImpactRatingHigh] = useState<number>(0);

  const [lowMin, setLowMin] = useState(0);
  const [lowMax, setLowMax] = useState(10);
  const [lowMidMin, setLowMidMin] = useState(11);
  const [lowMidMax, setLowMidMax] = useState(20);
  const [midMin, setMidMin] = useState(21);
  const [midMax, setMidMax] = useState(30);
  const [midHighMin, setMidHighMin] = useState(31);
  const [midHighMax, setMidHighMax] = useState(40);
  const [highMin, setHighMin] = useState(41);
  const [highMax, setHighMax] = useState(50);

  useEffect(() => {
    const { riskTypeData } = props
    if (riskTypeData && riskTypeData.riskRange && riskTypeData.riskRange.length > 0) {  
      riskTypeData.riskRange.map((item: any) => {
        if (item.name === "Low") {
          setIdLow(item.id)
          setLowMin(item.minValue)
          setLowMax(item.maxValue)
          setWhyRiskTaking(item.whyThisRiskRating)
          setLikelihood(item.likelihood)
          setImpact(item.impact)
          setActionToTake(item.actionToTake)
          setPermanentFixes(item.permanentFixes)
          setFinancialImpact(item.financialImpact)
          setFinancialImpactDetails(item.financialImpactDetails)
          setActionToTakeDetails(item.actionToTakeDetails)
          setPermanentFixesDetails(item.permanentFixesDetails)
          setAttributionText(item.attributionText || '')
          setImpactRating(item.impactRating)
        }
        if (item.name === "Low-Med") {
          setIdLowMed(item.id)
          setLowMidMin(item.minValue)
          setLowMidMax(item.maxValue)
          setWhyRiskTakingLowMed(item.whyThisRiskRating)
          setLikelihoodLowMed(item.likelihood)
          setImpactLowMed(item.impact)
          setActionToTakeLowMed(item.actionToTake)
          setPermanentFixesLowMed(item.permanentFixes)
          setFinancialImpactLowMed(item.financialImpact)
          setFinancialImpactDetailsLowMed(item.financialImpactDetails)
          setActionToTakeDetailsLowMed(item.actionToTakeDetails)
          setPermanentFixesDetailsLowMed(item.permanentFixesDetails)
          setAttributionTextLowMed(item.attributionText)
          setImpactRatingLowMed(item.impactRating)
        }
        if (item.name === "Medium") {
          setIdMedium(item.id)
          setMidMin(item.minValue)
          setMidMax(item.maxValue)
          setWhyRiskTakingMedium(item.whyThisRiskRating)
          setLikelihoodMedium(item.likelihood)
          setImpactMedium(item.impact)
          setActionToTakeMedium(item.actionToTake)
          setPermanentFixesMedium(item.permanentFixes)
          setFinancialImpactMedium(item.financialImpact)
          setFinancialImpactDetailsMedium(item.financialImpactDetails)
          setActionToTakeDetailsMedium(item.actionToTakeDetails)
          setPermanentFixesDetailsMedium(item.permanentFixesDetails)
          setAttributionTextMedium(item.attributionText)
          setImpactRatingMedium(item.impactRating)
        }
        if (item.name === "Med-High") {
          setIdMedHigh(item.id)
          setMidHighMin(item.minValue)
          setMidHighMax(item.maxValue)
          setWhyRiskTakingMedHigh(item.whyThisRiskRating)
          setLikelihoodMedHigh(item.likelihood)
          setImpactMedHigh(item.impact)
          setActionToTakeMedHigh(item.actionToTake)
          setPermanentFixesMedHigh(item.permanentFixes)
          setFinancialImpactMedHigh(item.financialImpact)
          setFinancialImpactDetailsMedHigh(item.financialImpactDetails)
          setActionToTakeDetailsMedHigh(item.actionToTakeDetails)
          setPermanentFixesDetailsMedHigh(item.permanentFixesDetails)
          setAttributionTextMedHigh(item.attributionText)
          setImpactRatingMedHigh(item.impactRating)
        }
        if (item.name === "High") {
          setIdHigh(item.id)
          setHighMin(item.minValue)
          setHighMax(item.maxValue)
          setWhyRiskTakingHigh(item.whyThisRiskRating)
          setLikelihoodHigh(item.likelihood)
          setImpactHigh(item.impact)
          setActionToTakeHigh(item.actionToTake)
          setPermanentFixesHigh(item.permanentFixes)
          setFinancialImpactHigh(item.financialImpact)
          setFinancialImpactDetailsHigh(item.financialImpactDetails)
          setActionToTakeDetailsHigh(item.actionToTakeDetails)
          setPermanentFixesDetailsHigh(item.permanentFixesDetails)
          setAttributionTextHigh(item.attributionText)
          setImpactRatingHigh(item.impactRating)
        }
      })
    }
  }, [props.riskTypeData])


  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  const getActiveClass = (index: any, className: any) =>
    toggleState === index ? className : '';

  const CreateQus = (riskType: any) => {
    const { riskTypeData } = props;
    if(riskTypeData){
      return [
        {
          question: 'Impact rating',
          point: false,
          number: true,
          value:
            riskType === 'Low'
              ? impactRating
              : riskType === 'Low-Med'
              ? impactRatingLowMed
              : riskType === 'Medium'
              ? impactRatingMedium
              : riskType === 'Med-High'
              ? impactRatingMedHigh
              : riskType === 'High'
              ? impactRatingHigh
              : null,
          handleChange: (e: any) => { 
            if (riskType === 'Low') {
              setImpactRating(e.target.value);
            } else if (riskType === 'Low-Med') {
              setImpactRatingLowMed(e.target.value);
            } else if (riskType === 'Medium') {
              setImpactRatingMedium(e.target.value);
            } else if (riskType === 'Med-High') {
              setImpactRatingMedHigh(e.target.value);
            } else if (riskType === 'High') {
              setImpactRatingHigh(e.target.value);
            }
          },
          impactRating:
            riskType === 'Low'
              ? impactRating
              : riskType === 'Low-Med'
              ? impactRatingLowMed
              : riskType === 'Medium'
              ? impactRatingMedium
              : riskType === 'Med-High'
              ? impactRatingMedHigh
              : riskType === 'High'
              ? impactRatingHigh
              : null
        },
        {
          question: 'Why this risk rating',
          point: true,
          plainText: false,
          toolbar: <EditorToolbar />,
          modules: modules,
          text:
            riskType === 'Low'
              ? whyRiskTaking
              : riskType === 'Low-Med'
              ? whyRiskTakingLowMed
              : riskType === 'Medium'
              ? whyRiskTakingMedium
              : riskType === 'Med-High'
              ? whyRiskTakingMedHigh
              : riskType === 'High'
              ? whyRiskTakingHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setWhyRiskTaking(e);
            } else if (riskType === 'Low-Med') {
              setWhyRiskTakingLowMed(e);
            } else if (riskType === 'Medium') {
              setWhyRiskTakingMedium(e);
            } else if (riskType === 'Med-High') {
              setWhyRiskTakingMedHigh(e);
            } else if (riskType === 'High') {
              setWhyRiskTakingHigh(e);
            }
          },
          whyThisRiskRating:
            riskType === 'Low'
              ? whyRiskTaking
              : riskType === 'Low-Med'
              ? whyRiskTakingLowMed
              : riskType === 'Medium'
              ? whyRiskTakingMedium
              : riskType === 'Med-High'
              ? whyRiskTakingMedHigh
              : riskType === 'High'
              ? whyRiskTakingHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Likelihood:',
          plainText: false,
          point: false,
          toolbar: <QuillToolbar6 />,
          modules: modules6,
          text:
            riskType === 'Low'
              ? likelihood
              : riskType === 'Low-Med'
              ? likelihoodLowMed
              : riskType === 'Medium'
              ? likelihoodMedium
              : riskType === 'Med-High'
              ? likelihoodMedHigh
              : riskType === 'High'
              ? likelihoodHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setLikelihood(e);
            } else if (riskType === 'Low-Med') {
              setLikelihoodLowMed(e);
            } else if (riskType === 'Medium') {
              setLikelihoodMedium(e);
            } else if (riskType === 'Med-High') {
              setLikelihoodMedHigh(e);
            } else if (riskType === 'High') {
              setLikelihoodHigh(e);
            }
          },
          likelihood:
            riskType === 'Low'
              ? likelihood
              : riskType === 'Low-Med'
              ? likelihoodLowMed
              : riskType === 'Medium'
              ? likelihoodMedium
              : riskType === 'Med-High'
              ? likelihoodMedHigh
              : riskType === 'High'
              ? likelihoodHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Impact:',
          plainText: false,
          point: false,
          toolbar: <QuillToolbar2 />,
          modules: modules2,
          height: 300,
          text:
            riskType === 'Low'
              ? impact
              : riskType === 'Low-Med'
              ? impactLowMed
              : riskType === 'Medium'
              ? impactMedium
              : riskType === 'Med-High'
              ? impactMedHigh
              : riskType === 'High'
              ? impactHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setImpact(e);
            } else if (riskType === 'Low-Med') {
              setImpactLowMed(e);
            } else if (riskType === 'Medium') {
              setImpactMedium(e);
            } else if (riskType === 'Med-High') {
              setImpactMedHigh(e);
            } else if (riskType === 'High') {
              setImpactHigh(e);
            }
          },
          impact:
            riskType === 'Low'
              ? impact
              : riskType === 'Low-Med'
              ? impactLowMed
              : riskType === 'Medium'
              ? impactMedium
              : riskType === 'Med-High'
              ? impactMedHigh
              : riskType === 'High'
              ? impactHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Financial Imapct:',
          point: false,
          plainText: true,
          text:
            riskType === 'Low'
              ? financialImpact
              : riskType === 'Low-Med'
              ? financialImpactLowMed
              : riskType === 'Medium'
              ? financialImpactMedium
              : riskType === 'Med-High'
              ? financialImpactMedHigh
              : riskType === 'High'
              ? financialImpactHigh
              : null,
          handleChange: (e: any) => { 
            if (riskType === 'Low') {
              setFinancialImpact(e.target.value);
            } else if (riskType === 'Low-Med') {
              setFinancialImpactLowMed(e.target.value);
            } else if (riskType === 'Medium') {
              setFinancialImpactMedium(e.target.value);
            } else if (riskType === 'Med-High') {
              setFinancialImpactMedHigh(e.target.value);
            } else if (riskType === 'High') {
              setFinancialImpactHigh(e.target.value);
            }
          },
          financialImpact:
            riskType === 'Low'
              ? financialImpact
              : riskType === 'Low-Med'
              ? financialImpactLowMed
              : riskType === 'Medium'
              ? financialImpactMedium
              : riskType === 'Med-High'
              ? financialImpactMedHigh
              : riskType === 'High'
              ? financialImpactHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Further Technical Information',
          point: false,
          plainText: false,
          toolbar: <QuillToolbar4 />,
          modules: modules4,
          height: 300,
          text:
            riskType === 'Low'
              ? financialImpactDetails
              : riskType === 'Low-Med'
              ? financialImpactDetailsLowMed
              : riskType === 'Medium'
              ? financialImpactDetailsMedium
              : riskType === 'Med-High'
              ? financialImpactDetailsMedHigh
              : riskType === 'High'
              ? financialImpactDetailsHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setFinancialImpactDetails(e);
            } else if (riskType === 'Low-Med') {
              setFinancialImpactDetailsLowMed(e);
            } else if (riskType === 'Medium') {
              setFinancialImpactDetailsMedium(e);
            } else if (riskType === 'Med-High') {
              setFinancialImpactDetailsMedHigh(e);
            } else if (riskType === 'High') {
              setFinancialImpactDetailsHigh(e);
            }
          },
          financialImpactDetails:
            riskType === 'Low'
              ? financialImpactDetails
              : riskType === 'Low-Med'
              ? financialImpactDetailsLowMed
              : riskType === 'Medium'
              ? financialImpactDetailsMedium
              : riskType === 'Med-High'
              ? financialImpactDetailsMedHigh
              : riskType === 'High'
              ? financialImpactDetailsHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Action to take',
          point: false,
          plainText: true,
          text:
            riskType === 'Low'
              ? actionToTake
              : riskType === 'Low-Med'
              ? actionToTakeLowMed
              : riskType === 'Medium'
              ? actionToTakeMedium
              : riskType === 'Med-High'
              ? actionToTakeMedHigh
              : riskType === 'High'
              ? actionToTakeHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setActionToTake(e.target.value);
            } else if (riskType === 'Low-Med') {
              setActionToTakeLowMed(e.target.value);
            } else if (riskType === 'Medium') {
              setActionToTakeMedium(e.target.value);
            } else if (riskType === 'Med-High') {
              setActionToTakeMedHigh(e.target.value);
            } else if (riskType === 'High') {
              setActionToTakeHigh(e.target.value);
            }
          },
          actionToTake:
            riskType === 'Low'
              ? actionToTake
              : riskType === 'Low-Med'
              ? actionToTakeLowMed
              : riskType === 'Medium'
              ? actionToTakeMedium
              : riskType === 'Med-High'
              ? actionToTakeMedHigh
              : riskType === 'High'
              ? actionToTakeHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Actions to take details',
          plainText: false,
          point: false,
          toolbar: <QuillToolbar3 />,
          modules: modules3,
          height: 300,
          actionToTakeDetails:
            riskType === 'Low'
              ? actionToTakeDetails
              : riskType === 'Low-Med'
              ? actionToTakeDetailsLowMed
              : riskType === 'Medium'
              ? actionToTakeDetailsMedium
              : riskType === 'Med-High'
              ? actionToTakeDetailsMedHigh
              : riskType === 'High'
              ? actionToTakeDetailsHigh
              : null,
          text:
            riskType === 'Low'
              ? actionToTakeDetails
              : riskType === 'Low-Med'
              ? actionToTakeDetailsLowMed
              : riskType === 'Medium'
              ? actionToTakeDetailsMedium
              : riskType === 'Med-High'
              ? actionToTakeDetailsMedHigh
              : riskType === 'High'
              ? actionToTakeDetailsHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setActionToTakeDetails(e);
            } else if (riskType === 'Low-Med') {
              setActionToTakeDetailsLowMed(e);
            } else if (riskType === 'Medium') {
              setActionToTakeDetailsMedium(e);
            } else if (riskType === 'Med-High') {
              setActionToTakeDetailsMedHigh(e);
            } else if (riskType === 'High') {
              setActionToTakeDetailsHigh(e);
            }
          },
        },
        {
          question: 'Permanent fixes',
          point: false,
          plainText: true,
          text:
            riskType === 'Low'
              ? permanentFixes
              : riskType === 'Low-Med'
              ? permanentFixesLowMed
              : riskType === 'Medium'
              ? permanentFixesMedium
              : riskType === 'Med-High'
              ? permanentFixesMedHigh
              : riskType === 'High'
              ? permanentFixesHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setPermanentFixes(e.target.value);
            } else if (riskType === 'Low-Med') {
              setPermanentFixesLowMed(e.target.value);
            } else if (riskType === 'Medium') {
              setPermanentFixesMedium(e.target.value);
            } else if (riskType === 'Med-High') {
              setPermanentFixesMedHigh(e.target.value);
            } else if (riskType === 'High') {
              setPermanentFixesHigh(e.target.value);
            }
          },
          permanentFixes:
            riskType === 'Low'
              ? permanentFixes
              : riskType === 'Low-Med'
              ? permanentFixesLowMed
              : riskType === 'Medium'
              ? permanentFixesMedium
              : riskType === 'Med-High'
              ? permanentFixesMedHigh
              : riskType === 'High'
              ? permanentFixesHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Permanent fixes details',
          plainText: false,
          point: false,
          toolbar: <QuillToolbar5 />,
          modules: modules5,
          height: 300,
          text:
            riskType === 'Low'
              ? permanentFixesDetails
              : riskType === 'Low-Med'
              ? permanentFixesDetailsLowMed
              : riskType === 'Medium'
              ? permanentFixesDetailsMedium
              : riskType === 'Med-High'
              ? permanentFixesDetailsMedHigh
              : riskType === 'High'
              ? permanentFixesDetailsHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setPermanentFixesDetails(e);
            } else if (riskType === 'Low-Med') {
              setPermanentFixesDetailsLowMed(e);
            } else if (riskType === 'Medium') {
              setPermanentFixesDetailsMedium(e);
            } else if (riskType === 'Med-High') {
              setPermanentFixesDetailsMedHigh(e);
            } else if (riskType === 'High') {
              setPermanentFixesDetailsHigh(e);
            }
          },
          permanentFixesDetails:
            riskType === 'Low'
              ? permanentFixesDetails
              : riskType === 'Low-Med'
              ? permanentFixesDetailsLowMed
              : riskType === 'Medium'
              ? permanentFixesDetailsMedium
              : riskType === 'Med-High'
              ? permanentFixesDetailsMedHigh
              : riskType === 'High'
              ? permanentFixesDetailsHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
        {
          question: 'Attribution',
          plainText: false,
          point: false,
          toolbar: <QuillToolbar7 />,
          modules: modules7,
          text:
            riskType === 'Low'
              ? attributionText
              : riskType === 'Low-Med'
              ? attributionTextLowMed
              : riskType === 'Medium'
              ? attributionTextMedium
              : riskType === 'Med-High'
              ? attributionTextMedHigh
              : riskType === 'High'
              ? attributionTextHigh
              : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setAttributionText(e);
            } else if (riskType === 'Low-Med') {
              setAttributionTextLowMed(e);
            } else if (riskType === 'Medium') {
              setAttributionTextMedium(e);
            } else if (riskType === 'Med-High') {
              setAttributionTextMedHigh(e);
            } else if (riskType === 'High') {
              setAttributionTextHigh(e);
            }
          },
          attributionText:
            riskType === 'Low'
              ? attributionText
              : riskType === 'Low-Med'
              ? attributionTextLowMed
              : riskType === 'Medium'
              ? attributionTextMedium
              : riskType === 'Med-High'
              ? attributionTextMedHigh
              : riskType === 'High'
              ? attributionTextHigh
              : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
      ];
    }
    else{
      return [
        {
          question: 'Why this risk rating',
          point: true,
          plainText: false,
          toolbar: <EditorToolbar />,
          modules: modules,
          text:
            riskType === 'Low'
              ? whyRiskTaking
              : riskType === 'Low-Med'
                ? whyRiskTakingLowMed
                : riskType === 'Medium'
                  ? whyRiskTakingMedium
                  : riskType === 'Med-High'
                    ? whyRiskTakingMedHigh
                    : riskType === 'High'
                      ? whyRiskTakingHigh
                      : null,
          handleChange: (e: any) => {
            if (riskType === 'Low') {
              setWhyRiskTaking(e);
            } else if (riskType === 'Low-Med') {
              setWhyRiskTakingLowMed(e);
            } else if (riskType === 'Medium') {
              setWhyRiskTakingMedium(e);
            } else if (riskType === 'Med-High') {
              setWhyRiskTakingMedHigh(e);
            } else if (riskType === 'High') {
              setWhyRiskTakingHigh(e);
            }
          },
          whyThisRiskRating:
            riskType === 'Low'
              ? whyRiskTaking
              : riskType === 'Low-Med'
                ? whyRiskTakingLowMed
                : riskType === 'Medium'
                  ? whyRiskTakingMedium
                  : riskType === 'Med-High'
                    ? whyRiskTakingMedHigh
                    : riskType === 'High'
                      ? whyRiskTakingHigh
                      : null,
          pointText: [
            {
              text: 'An overland flow path runs across the property and crosses over the building footprint',
            },
            {
              text: ' and/or less than 30% of the property is covered by a 1/100 yrfloodplain away from the buildings.',
            },
          ],
        },
      ]
    }
  }

  const lableList = [
    {
      id: idLow,
      name: "Low",
      displayName: "Not Assessed",
      questions: CreateQus("Low"),
      minValue: lowMin,
      maxValue: lowMax,
      setMinValue: setLowMin,
      setMaxValue: setLowMax,
    },
    {
      id: idLowMed,
      name: "Low-Med",
      displayName: "Low",
      questions: CreateQus("Low-Med"),
      minValue: lowMidMin,
      maxValue: lowMidMax,
      setMinValue: setLowMidMin,
      setMaxValue: setLowMidMax,
    },
    {
      id: idMedHigh,
      name: "Medium",
      displayName: "Low-Med",
      questions: CreateQus("Medium"),
      minValue: midMin,
      maxValue: midMax,
      setMinValue: setMidMin,
      setMaxValue: setMidMax,
    },
    {
      id: idMedium,
      name: "Med-High",
      displayName: "Med-High",
      questions: CreateQus("Med-High"),
      minValue: midHighMin,
      maxValue: midHighMax,
      setMinValue: setMidHighMin,
      setMaxValue: setMidHighMax,
    },
    {
      id: idHigh,
      name: "High",
      displayName: "High",
      questions: CreateQus("High"),
      minValue: highMin,
      maxValue: highMax,
      setMinValue: setHighMin,
      setMaxValue: setHighMax,
    },
  ]

  useEffect(() => {
    props.getRiskTabData(lableList)
  }, [
    whyRiskTakingLowMed,
    whyRiskTaking,
    whyRiskTakingMedium,
    whyRiskTakingMedHigh,
    whyRiskTakingHigh,
    likelihood,
    likelihoodLowMed,
    likelihoodMedium,
    likelihoodMedHigh,
    likelihoodHigh,
    impact,
    impactLowMed,
    impactMedium,
    impactMedHigh,
    impactHigh,
    financialImpact,
    financialImpactLowMed,
    financialImpactMedium,
    financialImpactMedHigh,
    financialImpactHigh,
    actionToTake,
    actionToTakeLowMed,
    actionToTakeMedium,
    actionToTakeMedHigh,
    actionToTakeHigh,
    permanentFixes,
    permanentFixesLowMed,
    permanentFixesMedium,
    permanentFixesMedHigh,
    financialImpactDetails,
    financialImpactDetailsLowMed,
    financialImpactDetailsMedium,
    financialImpactDetailsMedHigh,
    financialImpactDetailsHigh,
    actionToTakeDetails,
    actionToTakeDetailsLowMed,
    actionToTakeDetailsMedium,
    actionToTakeDetailsMedHigh,
    actionToTakeDetailsHigh,
    permanentFixesDetails,
    permanentFixesDetailsLowMed,
    permanentFixesDetailsMedium,
    permanentFixesDetailsMedHigh,
    permanentFixesDetailsHigh,
    attributionText,
    attributionTextLowMed,
    attributionTextMedium,
    attributionTextMedHigh,
    attributionTextHigh,
    impactRating,
    impactRatingLowMed,
    impactRatingMedium,
    impactRatingMedHigh,
    impactRatingHigh,
    lowMin,
    lowMax,
    lowMidMin,
    lowMidMax,
    midMin,
    midMax,
    midHighMin,
    midHighMax,
    highMin,
    highMax,

  ])  

  return (<div>
    <div className="admintab">
      <ul className="risk-tab-list">
        {
          lableList.map(i => {
            return (<li
              id='risk-tab'
              key={"lit-of" + i.name}
              className={`tabs ${getActiveClass(i.name, 'risk-active-tabs')}`}
              onClick={() => toggleTab(i.name)}
            >
              {i.displayName}
            </li>)
          })
        }
      </ul>
    </div>
    <div className="content-container">
      {
        lableList.map(i => <div
          key={"list-of-content" + i.name}
          className={`content ${getActiveClass(i.name, 'active-content')}`}>
          {toggleState === i.name && (
            <RiskTabContentBox
              item={i}
            />
          )}
        </div>)
      }
    </div>
  </div>
  );

};

export default RiskTabItem;
