import * as React from 'react';
import './risk.scss';
const RiskReport: React.FC<any> = ({ reportData }) => {
  const [loadingReportData, setLoadingReportData] = React.useState(true);
  const [risks, setRisks] = React.useState([]);

  enum RiskTypes {
    'High' = 'highrisk',
    'Med-High' = 'highmedrisk',
    'Medium' = 'mediumrisk',
    'Low-Med' = 'lowmedrisk',
    'Low-M' = 'lowmedrisk',
    'Low' = 'lowrisk',
    'Very Low' = 'lowrisk',
    'Unlikely' = 'lowrisk',
    'Not assessed' = 'notAssessedRisk',
    'Undetermined' = 'notAssessedRisk',
    'Possible' = 'mediumrisk',
  }

  React.useEffect(() => {
    reportData && getRisks(reportData[0].risks);
  }, [reportData]);

  const bindRisks = () => {
    if (risks.length > 0) {
      return (
        <div className="row">
          {risks.map((i) => (
            <HalfWidth reportData={i} />
          ))}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getRisks = (data: any) => {
    const array = data.map((i: any) => ({
      riskName: i.riskName,
      riskType: i.riskType,
      name:
        i.riskValue.rating.name === 'Low-M' ? 'Low' : i.riskValue.rating.name,
      class: RiskTypes[i.riskValue.rating.name as keyof typeof RiskTypes],
    }));

    const riskTypes = [
      'High',
      'Med-High',
      'Medium',
      'Possible',
      'Low-Med',
      'Low',
      'Very Low',
      'Unlikely',
      'Not assessed',
      'Undetermined',
    ];
    array.sort((a: any, b: any) => {
      const firstPrio = riskTypes.indexOf(a.name);
      const secPrio = riskTypes.indexOf(b.name);
      return firstPrio - secPrio;
    });

    pushFloodingToBeginningOfArray(array);

    setRisks(array);

    setLoadingReportData(false);
    return (
      <>
        <p>Risks</p>
      </>
    );
  };

  const pushFloodingToBeginningOfArray = (array: any[]) => {
    const floodingRiskIndex = array.findIndex(
      (risk) =>
        (risk.riskName as string)
          .trim()
          .toLowerCase()
          .localeCompare('flooding') === 0
    );

    if (floodingRiskIndex !== -1) {
      const floodingRisk = array[floodingRiskIndex];

      array.splice(floodingRiskIndex, 1);
      array.unshift(floodingRisk);
    }
  };

  return (
    <div id="Summary">
      <div id="riskpg">
        <div className="wrap">{!loadingReportData && <>{bindRisks()}</>}</div>
      </div>
    </div>
  );
};

export default RiskReport;

const HalfWidth: React.FC<any> = ({ reportData }) => {
  return (
    <div className="half-width">
      <div className={reportData.class}>
        <span> {reportData.name}</span>
        <p>{reportData.riskName}</p>
      </div>
    </div>
  );
};
