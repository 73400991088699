import * as React from 'react';
import MetaTags from 'react-meta-tags';
import RiskDefinitions from '../../components/risk-definitions';
import FlushData from '../FlushData';
import './index.scss';

const RiskDefinitionspage: React.FC<any> = () => {
  return (
    <div>
      <MetaTags>
        <title>Landcheck – Natural hazard</title>
        <meta name="description" content="" />
      </MetaTags>
      <FlushData />
      <div id="riskdefinition">
        <div className="wrap">
          <div className="innerwrap">
            <RiskDefinitions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskDefinitionspage;
