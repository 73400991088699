import * as React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import './style.scss';

const EmailVerified: React.FC<any> = () => {
  const history = useHistory();
  return (
    <>
      <div id="emailverified">
        <div className="wrap">
          <div className="innerwrap">
            <div className="emailverified-inner">
              <h2>Your email address has now been verified </h2>
              <p>Thank you for signing up for Landcheck. Your account is now active. Please use the link below to sign in to your account.</p>

              <Button onClick={() => history.push('/landcheck-login')} className="button-primary">
                Sign in to your account
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailVerified;


