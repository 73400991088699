import React from 'react';
import { Modal } from 'react-bootstrap';
import Loader from '../../../shared/ui/components/loader';
import { getTermsAndCondition } from '../../../shared/utils/services/hooks/terms';

const TermsAndConditionsModal: React.FC<any> = () => {
    const [value, setValue] = React.useState<string>('');

    const {
        data,
        isLoading,
        isError,
    } = getTermsAndCondition();

    React.useEffect(() => {
        if (data) {
            setValue(data?.terms);
        }
    }, [data]);

    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <div>
                        <Loader title=" " isLoading={isLoading} />
                    </div>
                ) : (
                    <div
                        className="termpera"
                        dangerouslySetInnerHTML={{ __html: value }}
                    ></div>
                )}
            </Modal.Body>
        </div>
    );
};

export default TermsAndConditionsModal;
