import * as React from 'react';
import { Link } from 'react-router-dom';
import Lock from '../auth/lock';
import './login.scss';

const LoginPage: React.FC<any> = () => {
  return (
    <>
      <div id="loginpage">
        <div className="wrap">
          <div className="innerwrap">
            <div className="login-inner">
              <h1 className="login-header">Log in </h1>
              <Lock />
              <div className="loginbottom">
                <Link to="/forgot-password">Forgot password?</Link>
                <p>
                  Don’t have an account? <Link to="/signup">Sign up</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
