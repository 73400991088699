
import React, { useEffect } from 'react';

export default (props: any) => {
  const bodyRef: any = React.createRef();
  // const createPdf = () => props.createPdf(bodyRef.current);
  // useEffect(() => {
  // setTimeout(() => {
  // createPdf()
  // window.close()
  // }, 200);
  // },[])
  return (
    <section className="pdf-container">
      <section className="pdf-body" ref={bodyRef}>
        {props.children}
      </section>
    </section>
  )
}