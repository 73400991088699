import {  useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Login from '../../components/Login';
const LoginPage: React.FC<any> = () => {
  useEffect(() => {    
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
 }, []);

  return (
    <div>
      <MetaTags>
        <title>Landcheck – Log in</title>
        <meta name="description" content="Log in to purchase a natural hazard risk report or to view any of your previously generated reports in one place." />
      </MetaTags>
      <Login />
    </div>
  );
};

export default LoginPage;
