import React, { useState } from 'react';
import Tab from '../../../common/Tab';
import './index.scss';

const TabsMain: React.FC<any> = ({ regionId, ...rest }) => { 
  return (
    <div >
      <Tab regionId={regionId}  {...rest}/>
    </div>
  );
};

export default TabsMain;
