// eslint-disable-next-line
const saveIsLoggedIn = (status: boolean) => ({
  type: 'SAVE_IS_LOGGED_IN',
  payload: status,
});
// eslint-disable-next-line
const saveToken = (token: any) => ({
  type: 'SAVE_TOKEN',
  payload: token,
});
// eslint-disable-next-line
const saveAccessToken = (token:any)=>({
  type: 'SAVE_ACCESS_TOKEN',
  payload: token,
});

// eslint-disable-next-line
const saveAuthUserName = (userName:any)=>({
  type: 'SAVE_AUTH_NAME',
  payload: userName,
});

// eslint-disable-next-line
const saveUserData = (useData:any)=>({
  type: 'SAVE_USER_INFO',
  payload: useData,
});

// eslint-disable-next-line
const saveIsAnonymousUser = (value: boolean)=>({
  type: 'SAVE_IS_ANONYMOUS_USER',
  payload: value,
});

// eslint-disable-next-line
const saveGuestEmail = (email: string)=>({
  type: 'SAVE_GUEST_EMAIL',
  payload: email,
});

export { saveToken, saveIsLoggedIn, saveAccessToken, saveAuthUserName,saveUserData, saveIsAnonymousUser, saveGuestEmail };
