import { useMutation, useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const getRisks = (regionId: string) => {
  const adminCallApi = adminUseAxios();
  return useMutation(() =>
    adminCallApi({
      method: 'GET',
      url: `/Risks/all/${regionId}`,
    }).then(async (response: any) => {
      return response;
    }));
};

export const getRiskType = (riskType: string, regionId: string) => {
  const adminCallApi = adminUseAxios();
  return useMutation(() =>
    adminCallApi({
      method: 'GET',
      url: `/Risks/${riskType}/attribute?regionId=${regionId}`,
    }).then(async (response: any) => {
      return response;
    })
  );
};

export const getRiskSummary = (regionId: string) => {
  const adminCallApi = adminUseAxios();
  return useMutation(() =>
    adminCallApi({
      method: 'GET',
      url: `/Risks/summary?regionId=${regionId}`,
    }).then(async (response: any) => {
      return response;
    })
  );
};
