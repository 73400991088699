import React, { FC } from "react"
interface IButton {
    handleSubmit: React.MouseEventHandler<HTMLButtonElement>
}

const Button: FC<IButton> = ({
    handleSubmit
}) => {
    return (
        <button onClick={handleSubmit} className="button-primary">
            <img src="/images/reload.svg" />
            <span className="button-text">Update</span>
        </button>
    )
}

export default Button