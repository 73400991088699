import { Modal } from 'react-bootstrap';
import ActionModal from './action-modal';
import FinancialModal from './financial-modal';
import MapModal from './map-modal';
import PermantFixesModal from './permant-fixes-modal';

const ReportModal: React.FC<any> = ({ type, show, handleModal, data, riskNumber}) => {
  const riskData = data &&
  data[0].risks &&
  data[0].risks.length > 0 ? data[0] : null
  const riskCategoryData = riskData && riskData.risks && riskData.risks.length > 0 ? riskData.risks[riskNumber] : null

  return (
    <Modal show={show} onHide={() => handleModal(false)} className={type}>
      {type === 'actionsModal' && <ActionModal actionDetails={riskCategoryData ? riskCategoryData.riskValue : null}/>}
      {type === 'mapModal' && <MapModal />}
      {type === 'financialModal' && <FinancialModal financialImpact={riskCategoryData ? riskCategoryData.riskValue : null} />}
      {type === 'permantfixesModal' && <PermantFixesModal permanentDetails={riskCategoryData ? riskCategoryData.riskValue : null} />}
    </Modal>
  );
};
export default ReportModal;
