import * as React from 'react';
import './index.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import EditorToolbar, { modules, formats } from '../../common/EditorToolbar';
import Button from '../../../shared/ui/components/button/index';
import {
  getTermsAndCondition,
  postTermsAndConditions,
} from '../../../shared/utils/services/hooks/terms';
import Loader from '../../../shared/ui/components/loader';
import Toast from '../../../shared/ui/components/toast';
import { toast } from 'react-toastify';

const notifySuccessTerms = () =>
  toast.success('Terms and Conditions updated successfully!');
const notifyErrorTerms = () =>
  toast.error('Something went wrong. Please try again later!');
const AdminTermsAndConditions: React.FC<any> = () => {
  const [submitForm, setSubmitForm] = React.useState(false);
  const [value, setValue] = React.useState<string>('');
  const [formValue, setFormValue] = React.useState<string>('');
  const {
    data: postData,
    isLoading: loading,
    isSuccess: postDataSuccess,
    isError: postDataError,
    status,
  } = postTermsAndConditions(formValue, submitForm);
  const {
    data,
    isLoading: getTermsIsLoading,
    isError: getTermsIsError,
  } = getTermsAndCondition();

  const handleUpdate = async () => {
    setFormValue(value);
    setSubmitForm(true);
  };

  const handleChange = (e: any) => {
    setValue(e);
    if (submitForm) {
      setSubmitForm(false);
    }
  };

  React.useEffect(() => {
    if (data) {
      setValue(data?.terms);
    }
  }, [data]);

  React.useEffect(() => {
    if (postDataSuccess) {
      notifySuccessTerms();
    } else if (postDataError) {
      notifyErrorTerms();
    }
  }, [postDataSuccess, postDataError]);

  return (
    <div>
      <Toast />
      <div id="Terms_condition">
        <div className="wrap">
          <div className="admin-termspage">
            {getTermsIsLoading ? (
              <Loader title="Loading Terms and conditions..." />
            ) : (
              <>
                <div className="title-wrapper">
                  <h2>Terms and conditions</h2>
                  <EditorToolbar />
                </div>
                <div className="text-editor">
                  <ReactQuill
                    className="editor-wrapper"
                    theme="snow"
                    value={value}
                    modules={modules}
                    formats={formats}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <Button
                  className="button-primary"
                  onClick={() => handleUpdate()}
                >
                  <img src="/images/reload.svg" />
                  <span className="button-text">Update</span>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTermsAndConditions;
