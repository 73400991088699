import * as React from 'react';

const SideBar: React.FC<any> = ({ data, scroll }) => {
  
  return (
    <>
      <a href={'#Summary'}>
        <div className={`report-icon ${'Summary' === scroll ? 'active' : ''}`}  >
          <p>
            <Icon name="homeicon" /> Summary
          </p>
        </div>
      </a>
      {data &&
        data[0].risks &&
        data[0].risks.length > 0 &&
        data[0].risks.map((item: any) => {
          return (
            <a href={'#' + item.riskType.replace(" ", "")}>
              <div
                className={`report-icon ${item.riskType.replace(" ", "") === scroll ? 'active' : ''
                  }`}
              >
                <p>
                  {item.riskType === 'Summary' && <Icon name="homeicon" />}
                  {item.riskType === 'Flooding' && <Icon name="homeflot" />}
                  {item.riskType === 'Volcano' && <Icon name="volcano" />}
                  {item.riskType === 'Liquefaction' && (
                    <Icon name="liquefaction" />
                  )}
                  {item.riskType === 'Tsunami' && <Icon name="tsunami" />}
                  {item.riskType === 'Earthquake' && <Icon name="earthquake" />}
                  {item.riskType === 'Coastal inundation' && (
                    <Icon name="inicon" />
                  )}
                  {item.riskType === 'Severe wind' && <Icon name="wind" />}
                  {item.riskType === 'Landslip' && <Icon name="Landslip1" />}
                  {item.riskType === 'Coastal erosion' && <Icon name="erosion" />}
                  {item.riskType === 'Active faults' && <Icon name="activeFaults" />}
                  
                  {item.riskName}
                </p>
              </div>
            </a>
          );
        })}
    </>
  );
};

export default SideBar;

const Icon: React.FC<any> = ({ name }) => <img src={`/images/${name}.svg`} />;
