import { useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { config } from '../../../config';
import { useSelector } from 'react-redux';
/**
 * Returns a function that can be used to call an API.
 * This function wraps the axios instance.
 */
export default () => {
  const history = useHistory();
  const { token }: any = useSelector<any>((state: any) => state.user);
  const adminCallApi = useCallback(async ({ headers, ...rest }) => {
    try {
      const { data } = await axios({
        baseURL: config._adminAPI,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        ...rest,
        validateStatus: (status) => status >= 200 && status <= 299,
      });
      return data;
    } catch (err: any) {
      if (err && err.response && err.response.status === 401) {
        history.push('/landcheck-login');
      } else if (err && err.response && err.response.status === 503) {
        history.push('/404');
      }
      throw err;
    }
  }, []);

  return adminCallApi;
};
