import { useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const getRegion = () => {
  const adminCallApi = adminUseAxios();
  return useQuery(
    ['GET_REGIONS'],
    () =>
      adminCallApi({
        method: 'GET',
        url: '/Regions',
      }).then((data: any) => (data ? data.lists : [])),
    {}
  );
};
