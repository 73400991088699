import useAxios from '../useAxios';
import { useQuery } from 'react-query';

export const getCustomerReports = () => {
  const callApi = useAxios();
  return useQuery(['USER_REPORTS'], () =>
    callApi({
      method: 'GET',
      url: '/Reports/AccountReports',
    }).then((data: any) => (data ? data.lists : []))
  );
};

const pushFloodingToBeginningOfArray = (array: any[]) => {
  const floodingRiskIndex = array.findIndex(
    (risk) =>
      (risk.riskName as string)
        .trim()
        .toLowerCase()
        .localeCompare('flooding') === 0
  );

  if (floodingRiskIndex !== -1) {
    const floodingRisk = array[floodingRiskIndex];

    array.splice(floodingRiskIndex, 1);
    array.unshift(floodingRisk);
  }
};

export const getReportsDataByID = (id: string, authkey?: string) => {
  const callApi = useAxios();
  if (!authkey) {
    return useQuery(
      ['REPORT_DATA', id],
      () =>
        callApi({
          method: 'GET',
          url: `/Reports/${id}`,
        })
          .then((data: any) => (data ? data.lists : []))
          .then((data) => {
            pushFloodingToBeginningOfArray(data?.[0]?.risks ?? []);
            return data ?? [];
          }),
      {
        refetchInterval: (data) =>
          data && data[0]?.status !== 3 ? 30000 : false,
        enabled: Boolean(id),
      }
    );
  } else {
    return useQuery(
      ['REPORT_DATA', id],
      () =>
        callApi({
          method: 'GET',
          url: `/Reports/details?reportId=${id}`,
          headers: {
            'X-Api-Key': authkey,
          },
        })
          .then((data: any) => {
            console.log(data);
            return data ? data.lists : [];
          })
          .then((data) => {
            pushFloodingToBeginningOfArray(data?.[0]?.risks ?? []);
            return data ?? [];
          }),
      {
        refetchInterval: (data) =>
          data && data[0]?.status !== 3 ? 30000 : false,
        enabled: Boolean(id),
      }
    );
  }
};

export const getReportImage = (data: any) => {
  const callApi = useAxios();
  return useQuery(
    ['REPORT_IMAGE'],
    () =>
      callApi({
        method: 'POST',
        url: `/Reports/image`,
        data,
      }).then((data: any) => data),
    {
      enabled: Boolean(data),
      cacheTime: 0,
    }
  );
};

export const buyReport = (buyReport: boolean, data: any, paymentId: string) => {
  const callApi = useAxios();
  return useQuery(
    ['BUY_REPORT'],
    () =>
      callApi({
        method: 'POST',
        url: `/Reports/buy`,
        data,
      }).then((data: any) => data),
    {
      enabled: Boolean(buyReport) && Boolean(paymentId),
      cacheTime: 0,
    }
  );
};

export const downloadReportByID = (
  reportId: string,
  downloadReport: boolean,
  authkey?: string
) => {
  const callApi = useAxios();
  return useQuery(
    ['REPORT_BY_ID', reportId],
    () =>
      callApi({
        method: 'GET',
        url: `/Reports/${reportId}/download`,
        ...(authkey && {
          headers: {
            'X-Api-Key': authkey,
          },
        }),
      }).then((data: any) => data),
    {
      enabled: Boolean(reportId) && Boolean(downloadReport),
      cacheTime: 0,
    }
  );
};

export const downloadSampleReport = (downloadSample: boolean) => {
  const callApi = useAxios();
  return useQuery(
    ['SAMPLE_REPORT_DOWNLOAD'],
    () =>
      callApi({
        method: 'GET',
        url: `/Reports/sample`,
      }).then((data: any) => data),
    {
      enabled: Boolean(downloadSample),
      cacheTime: 0,
    }
  );
};

export const generateReportById = (reportId: string) => {
  const callApi = useAxios();
  return useQuery(
    ['GENERATE_REPORT_BY_ID', reportId],
    () =>
      callApi({
        method: 'GET',
        url: `/Reports/${reportId}/generate`,
      }).then((data: any) => data),
    {
      enabled: !!reportId,
      cacheTime: 0,
    }
  );
};
