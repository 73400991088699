import * as React from 'react';
import './index.scss';
import Search from '../../../shared/ui/components/search';
import Button from '../../../shared/ui/components/button/index';
import { Location } from '../../../shared/utils/types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  flushLocationSearch,
  saveLocation,
} from '../../../store/actions/location-action';
import { toast } from 'react-toastify';
import { flushReportImage } from '../../../store/actions/common-actions';
import { useState } from 'react';
import { getLatLong } from '../../../shared/utils/helper';

const notifyError = () => toast.error('Please select a valid address!');

const BannerHome: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { location }: any = useSelector<Location>(
    (state: any) => state.location
  );
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<Location[]>();
  console.log(searchResults, searchText);
  const handleSearchClick = async (e: any) => {
    e.preventDefault();
    if (
      searchResults?.length === 1 &&
      searchResults[0].formatted_address?.toLowerCase().trim() ===
        searchText.toLowerCase().trim()
    ) {
      if (searchResults[0].coveredLocation) {
        const addressLatLong = await getLatLong(
          searchResults[0].formatted_address
        );
        dispatch(saveLocation(searchResults[0], addressLatLong));
        history.push(`/view-report`);
      } else {
        history.push(`/not-found-page`);
      }
    } else {
      notifyError();
    }
  };
  React.useEffect(() => {
    if (!history.location.hash.includes('access_token')) {
      dispatch(flushLocationSearch());
      dispatch(flushReportImage());
    }
  }, []);
  return (
    <div id="banner">
      <div className="wrap">
        <div className="row">
          {/* banner content */}
          <div className="half-width batchBg">
            <h1 className="home-header">
              We know natural hazards.
              <br />
              You should too.
            </h1>
            <p className="bannerContent">
              The affordable way to better understand the natural hazards that a
              property is exposed to in the Auckland region.
            </p>
            <div className="search-home">
              <Search
                page="home"
                className="main-banner-search"
                searchText={searchText}
                setSearchText={setSearchText}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                id="homeSearch1"
              />
              <Button
                onClick={(e) => handleSearchClick(e)}
                className="button-primary"
                disabled={!searchText}
              >
                Search
              </Button>
            </div>
            <div className="report-disclaimer">
              <p>
                Please note: Occasionally, Auckland Council, and our other sources
                will update their data. We regularly check that the data Landcheck
                uses is up to date and carry out a comprehensive review every 6
                months. Any report that was purchased prior to an update may
                include outdated information.
              </p>
            </div>
          </div>
          {/* image banner */}
          <div className="half-width-img"></div>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
