import * as React from 'react';
import MetaTags from 'react-meta-tags';
import ContactPage  from '../../components/contact';
import FlushData from '../FlushData';
const Contact: React.FC<any> = () => {
  return (
    <div>
      <MetaTags>
        <title>Landcheck - Contact us</title>
        <meta name="description" content="Got any issues or questions? Contact us using this online form or by email and we’ll get back to you as soon as we can." />
      </MetaTags>
      <FlushData />
      <ContactPage />
    </div>
  );
};

export default Contact;
