import React from 'react';
import IssuedReports from '../../components/IssuedReports';
import ProtectRoute from '../../shared/wrapper';

const IssuedReportsPage: React.FC<any> = () => {
  return (
    <div>
      <ProtectRoute>
        <IssuedReports />
      </ProtectRoute>
    </div>
  );
};

export default IssuedReportsPage;
