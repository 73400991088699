import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../shared/ui/components/loader';
import {
  createAccountafterSignup,
  getAccount,
} from '../../shared/utils/services/hooks/account';
import { saveSelectedReport } from '../../store/actions/reports-actions';
const Success: React.FC<any> = () => {
  const history = useHistory();
  const { authUser, isAnonymousUser }: any = useSelector<any>((state: any) => state.user);
  const { isReportPage, reportLink }: any = useSelector<any>((state: any) => state.common);

  const [userdata, setUserData] = React.useState(null);
  const [createNewAccount, setCreateNewAccount] =
    React.useState<boolean>(false);

  const {
    data: createAccounTData,
    isLoading: createAccounbtLoading,
    isError: createAccountError,
  } = createAccountafterSignup(createNewAccount && !isAnonymousUser, userdata);
  const {
    data: getAccounTData,
    isLoading: getAccounbtLoading,
    isError: getAccountError,
  } = getAccount(authUser, !isAnonymousUser);

  React.useEffect(() => {
    if (authUser && !isAnonymousUser) {
      if (authUser.firstName && authUser.lastName) {
        setUserData(authUser);
      } else {
        const tempData = authUser;
        tempData.lastName = authUser.firstName;
        setUserData(tempData);
      }
    } 
  }, [authUser, isAnonymousUser]);

  React.useEffect(() => {
    if (createAccounTData || isAnonymousUser) {
      handleRedirect();
    } else if (createAccountError) {
      // alert('error');
    }
  }, [createAccounTData, isAnonymousUser]);

  React.useEffect(() => {
    if ((getAccounTData && getAccounTData.user) || isAnonymousUser) {
      handleRedirect();
    } else if (getAccountError) {
      // alert('Alert fetching data error');
    } else {
      if (authUser && authUser.firstName && !getAccounbtLoading) {
        setCreateNewAccount(true);
      }
    }
  }, [getAccounTData, getAccounbtLoading, getAccountError, authUser, isAnonymousUser]);
  const dispatch = useDispatch();

  const handleRedirect = () => {

    if (getAccounTData?.user?.isAdmin) {
      history.push('/admin/search');
    }
    else {
      if (reportLink) {
        dispatch(saveSelectedReport({ id: reportLink }))
        history.push("/view-report");
      }
      else {
        if (isReportPage) {
          history.push('/payment');
        } else {
          history.push('/my-reports');
        }
      }
    }
  };
  return (
    <div className='minmumHeightBox'>
      <Loader title="Loading data..." isLoading={true} />
    </div>
  );
};

export default Success;
