import * as React from 'react';
import BrandIcon from '../../assets/images/Logo.png';
import './footer.scss';
import { config } from '../../config';
import { Container, Row, Col } from 'react-bootstrap';

const Footer: React.FC<any> = () => {
  return (
    <>
      <div className="footer-container">
        <Container>
          <Row className="row-container">
            <Col lg className="footer-mission-col">
              <Container>
                <Row className="footer-mission-row">
                  <Col md className="footer-logo-col col-detail">
                    <div className="footer-logo">
                      <a href={config.landcheckWebApp}>
                        <img className="logo-img" src={BrandIcon} alt="logo" />
                      </a>
                    </div>
                  </Col>
                  <Col md className="col-detail">
                    <div className="footer-section mission">
                      <p>
                        Our mission is to empower property owners with the
                        knowledge and insights needed to make informed decisions
                        about protecting their assets and families.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg className="links-social-media-col">
              <Container className="links-social-media-container">
                <Row>
                  <Col lg className="col-detail">
                    <div className="footer-section links">
                      <h4>Customer Care</h4>
                      <ul>
                        <li>
                          <a
                            href={config.landcheckWebApp+ "privacy-policy"}
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href={config.landcheckWebApp+ "terms-and-conditions"}
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg className="col-detail">
                    <div className="footer-section social-media">
                      <h4>Follow Us</h4>
                      <a href="https://www.facebook.com/Landcheck/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1.5em"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="#fff"
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                          />
                        </svg>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
