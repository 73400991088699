
import TabContent from '../admin/search/tabs/tabContent';
import './Tab.scss';
import Loader from 'react-loader-spinner';
import SummaryTabContent from '../admin/search/tabs/summaryTabContent';


const Tab = ({ regionId, isLoading, getActiveClass, data, toggleState }: any) => {





  return (
    <div className="tab-container">
      {isLoading ? (
        <div className="map-loader">
          <Loader type="Puff" color="#2f2f60" height={50} width={50} />
        </div>
      ) : <></>}
      <div className="content-container">
        <div
          className={`content ${getActiveClass('summary', 'active-content')}`}>
          {toggleState === 'summary' && <SummaryTabContent heading='Summary' regionId={regionId} />}
        </div>
        {
          data && data.length && data.map((i: any, index: number) => (<div
            className={`content ${getActiveClass(index, 'active-content')}`}>          
            {toggleState === index && <TabContent heading={i} regionId={regionId} />}
          </div>))
        }
      </div>
    </div>
  );
};

export default Tab;
