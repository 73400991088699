import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { routes } from './routes/routes';
import Layout from './components/layout';

import queryClient from './QueryClient';

import { QueryClientProvider } from 'react-query';
import React from 'react';

import ScrollToTop from './shared/ui/components/scroll-to-top';

const App: React.FC<any> = () => {
  return (
    <Router>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Switch>
            {routes.staticroutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={() => <route.component />}
              />
            ))}
          </Switch>
        </Layout>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
