import * as React from 'react';
import BrandIcon from '../../assets/images/BrandIcon.svg';
import Button from '../../shared/ui/components/button/index';
import { useLocation } from 'react-router';
import { mainMenu } from '../../shared/utils/constants';
import HeaderMenuItem from './nav-items';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenuDropDown } from '../../store/actions/screen-action';
import { AppState } from '@auth0/auth0-react';
import './header.scss';
import { lock } from '../../shared/utils/helper';
import {
  saveAccessToken,
  saveIsAnonymousUser,
  saveGuestEmail,
  saveIsLoggedIn,
  saveToken,
  saveUserData,
} from '../../store/actions/user-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { SearchBar } from './search-bar/search-bar';
import { config } from '../../config';
import { useEffect } from 'react';

const Header: React.FC<any> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn }: any = useSelector<any>((state: any) => state.user);
  const [isMenu, setIsMenu] = React.useState<boolean>(false);
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] =
    React.useState<boolean>(false);
  const { userInfo }: any = useSelector<any>((state: any) => state.user);
  const menu = useSelector<AppState>((s) => s.screen.menu);
  const dropDownMenu = () => {
    dispatch(toggleMenuDropDown(Boolean(!menu)));
  };

  const handleLogout = () => {
    setIsMenu(false);
    dispatch(saveIsLoggedIn(false));
    dispatch(saveToken(''));
    dispatch(saveAccessToken(''));
    dispatch(saveUserData(null));
    dispatch(saveIsAnonymousUser(false));
    dispatch(saveGuestEmail(''));
    lock.logout({
      returnTo: window.location.origin,
    });
  };

  const toggleGlobalSearchBar = () => {
    setIsGlobalSearchOpen(!isGlobalSearchOpen);
  };

  // useEffect(() => {
  //   const onScroll = () => {
  //     if (
  //       document.body.scrollTop > 10 ||
  //       document.documentElement.scrollTop > 10
  //     ) {
  //       document.getElementById('header')?.classList.add('solid');
  //       document.getElementById('header')?.classList.remove('transparent');
  //     } else {
  //       document.getElementById('header')?.classList.remove('solid');
  //       document.getElementById('header')?.classList.add('transparent');
  //     }
  //   };
  //   // clean up code
  //   window.removeEventListener('scroll', onScroll);
  //   window.addEventListener('scroll', onScroll, { passive: true });
  //   return () => window.removeEventListener('scroll', onScroll);
  // }, []);

  return (
    <div id="header">
      <SearchBar
        isDisplay={isGlobalSearchOpen}
        setIsDisplay={setIsGlobalSearchOpen}
      />
      <div className="wrap">
        <div className="row1">
          <div className="logo">
            <a href={config.landcheckWebApp}>
              <img height={40} width={240} src={BrandIcon} alt="logo" />
            </a>
          </div>
          <div className="menutoggle" onClick={() => setIsMenu(!isMenu)}>
            {<img src="/images/menu.svg" />}
          </div>
          <div className={`nav hide ${isMenu ? 'show' : ''}`}>
            <ul onClick={() => setIsMenu(false)}>
              {mainMenu.map((item, index) => (
                <HeaderMenuItem
                  key={`main-menu-${index}`}
                  path={item.path}
                  label={item.label}
                  currentPath={location.pathname}
                />
              ))}
              {isLoggedIn && location.pathname === '/view-report' && (
                <HeaderMenuItem
                  key={`main-menu-3`}
                  path="/my-reports"
                  label="My reports"
                  currentPath={location.pathname}
                />
              )}

              {!isLoggedIn ? (
                <>
                  <li onClick={() => setIsMenu(false)}>
                    <a
                      href="/landcheck-login"
                      className={
                        location.pathname === '/landcheck-login' ? 'active' : ''
                      }
                    >
                      Login
                    </a>
                  </li>
                  <li
                    className="signup-list-item"
                    onClick={() => setIsMenu(false)}
                  >
                    <a
                      href="/signup"
                      id="signup-link"
                      className={
                        (location.pathname === '/signup' ? 'active' : '') +
                        ' landcheck-nav-btn-link'
                      }
                    >
                      Sign up
                    </a>
                  </li>
                  <li>
                    <Button
                      className="button-primary landcheck-nav-btn"
                      onClick={() => (window.location.href = '/view-report')}
                    >
                      Get Report
                    </Button>
                  </li>
                  <li className="nav-search-li">
                    <Button
                      onClick={() => toggleGlobalSearchBar()}
                      className="nav-search-btn"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </li>
                </>
              ) : (
                <div className="table_center">
                  <div className="drop-down">
                    <div
                      id="dropDown"
                      className="drop-down__button"
                      onClick={dropDownMenu}
                    >
                      <span className="drop-down__name">
                        {userInfo?.user?.isAdmin ? 'Admin' : 'Account'}
                      </span>
                    </div>

                    <div
                      className={`drop-down__menu-box nav-vertical-list-menu ${
                        menu ? 'showdrp' : ''
                      }`}
                    >
                      {userInfo?.user?.isAdmin ? (
                        <ul className="drop-down__menu">
                          <Link to="/admin/terms-and-condition">
                            <li
                              className="drop-down__item"
                              onClick={() => setIsMenu(false)}
                            >
                              Terms & Conditions
                            </li>
                          </Link>
                          <Link to="/admin/issuedReports">
                            <li
                              className="drop-down__item"
                              onClick={() => setIsMenu(false)}
                            >
                              Reports
                            </li>
                          </Link>
                          <Link to="/admin/searchReports">
                            <li
                              className="drop-down__item"
                              onClick={() => setIsMenu(false)}
                            >
                              Address List
                            </li>
                          </Link>
                          <Link to="/admin/search">
                            <li
                              className="drop-down__item"
                              onClick={() => setIsMenu(false)}
                            >
                              Risk Management
                            </li>
                          </Link>
                          <li
                            className="drop-down__item"
                            onClick={() => handleLogout()}
                          >
                            Log out
                          </li>
                          <li>
                            <Button
                              className="button-primary landcheck-nav-btn"
                              onClick={() =>
                                (window.location.href = '/view-report')
                              }
                            >
                              Get Report
                            </Button>
                          </li>
                          <li className="nav-search-li dropdown-nav-search-li">
                            <Button
                              onClick={() => toggleGlobalSearchBar()}
                              className="nav-search-btn"
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </Button>
                          </li>
                        </ul>
                      ) : (
                        <ul className="drop-down__menu">
                          <Link to="/my-reports">
                            <li
                              className="drop-down__item"
                              onClick={() => setIsMenu(false)}
                            >
                              My reports
                            </li>
                          </Link>
                          <Link to="/setting">
                            <li
                              className="drop-down__item"
                              onClick={() => setIsMenu(false)}
                            >
                              Settings
                            </li>
                          </Link>
                          <li
                            className="drop-down__item"
                            onClick={() => handleLogout()}
                          >
                            Log out
                          </li>
                          <li>
                            <Button
                              className="button-primary landcheck-nav-btn"
                              onClick={() =>
                                (window.location.href = '/view-report')
                              }
                            >
                              Get Report
                            </Button>
                          </li>
                          <li className="nav-search-li dropdown-nav-search-li">
                            <Button
                              onClick={() => toggleGlobalSearchBar()}
                              className="nav-search-btn"
                            >
                              <FontAwesomeIcon icon={faSearch} />
                            </Button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
