import * as React from 'react';
import AdminSearchMain from '../../../components/admin/search';
import RestrictedRoute from '../../../shared/wrapper/restricted';

const AdminSearch: React.FC<any> = () => {
  return (
    <div>
      <RestrictedRoute>
        <AdminSearchMain />
      </RestrictedRoute>
    </div>
  );
};

export default AdminSearch;
