import React, { useEffect, useState } from 'react';
import './index.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Logs from '../../ui/Logs';
import RiskTakingQuestion from '../../ui/RiskTakingQuestion';
import RiskLabel from '../../ui/RiskLabel';

interface IRiskTabContentBox {
  loading?: boolean;
  item: any
}

const RiskTabContentBox: React.FC<IRiskTabContentBox> = ({ item, loading }) => {

  const [submitForm, setSubmitForm] = React.useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
 
  useEffect(() => {
    if (submitForm) {
      setSubmitForm(false);
    }
  }, [submitForm]);

  return (
    <div className="risk-tab-container" >
      {loading ? (
        <Loader type="Puff" color="#fff" />
      ) : (
        <>
          <div className="risk-scale">
            <RiskLabel
              minVlaue={item.minValue}
              maxVlaue={item.maxValue}
              handleChangeMin={item.setMinValue}
              handleChangeMax={item.setMaxValue} 
            />
            <div>
              <button onClick={handleShow} className="logs-button">
                <img src="/images/logs.svg" />
                <span className="button-text">LOGS</span>
              </button>
            </div>
          </div>
          <RiskTakingQuestion question={item.questions} />
          <Logs handleClose={handleClose} show={show} />
        </>
      )}

    </div>
  );
};

export default RiskTabContentBox; 