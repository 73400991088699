const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
  reportImage: '',
  propertyId: '',
  placeName: null,
  reportLink: ''
};

export default function commonReducer(
  state = initialState,
  // eslint-disable-next-line
  { type, payload }: any
): any {
  switch (type) {
    case 'LOADING': {
      return { ...state, loading: payload };
    }
    case 'CONTACT_SAVE_SUCCESS': {
      return { ...state, success: payload };
    }
    case 'CONTACT_SAVE_ERROR': {
      return { ...state, error: true, message: payload };
    }
    case 'SAVE_REPORT_IMAGE': {
      return { ...state, isReportPage: true, reportImage: payload };
    }
    case 'SAVE_PROPERTY_ID': {
      return { ...state, propertyId: payload };
    }
    case 'FLUSH_REPORT_PAGE': {
      return { ...state, isReportPage: false, reportImage: initialState.reportImage, placeName: initialState.placeName };
    }
    case 'SAVE_PLACENAME': {
      return { ...state, placeName: payload };
    }
    case 'SAVE_REPORT_LINK': { 
      return { ...state, reportLink: payload };
    }
    case 'SAVE_SEARCH_ADD_INFO': {
      return {...state, searchAddress: payload}
    }
    default:
      return state;
  }
}
