import MainBanner from './banner';
import RiskPreview from './report-preview';
import RiskReport from './risk-reports';

const ReportView: React.FC<any> = () => {
  return (
    <div>
      <MainBanner />
      <div className="innerwrap">
        <RiskReport />
        <RiskPreview />
      </div>
    </div>
  );
};

export default ReportView;
