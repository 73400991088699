import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ActionModal: React.FC<any> = ({ actionDetails, isPdf }) => {
  return (
    <div>
      {isPdf ? (
        <>
          <div className='risk-header'>
            <img src="/images/icon2.svg" alt=" icon" />
            <h2>Actions to take</h2>
          </div>
          <ActionModalBody actionDetails={actionDetails}></ActionModalBody>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Actions to take</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ActionModalBody actionDetails={actionDetails}></ActionModalBody>
          </Modal.Body>
        </>
      )}
    </div>
  );
};

const ActionModalBody: React.FC<any> = ({ actionDetails }) => {
  return (
    <>
      {actionDetails && actionDetails.actionToTakeDetails ? (
        <div
          dangerouslySetInnerHTML={{ __html: actionDetails.actionToTakeDetails }}
        ></div>
      ) : (
        <>
          <h2>Suggested </h2>
          <div>
            <ul>
              <li>
                Site visit with expert. A geotechnical engineer can provide extra
                insight. <Link to="">List of geotech engineers </Link>
              </li>
              <li>
                Ask the agent about any issues that have previously come up.
              </li>
              <li>
                Check the LIM and/or <Link to=""> property files </Link> for
                repair or preventative work that has been carried out.
              </li>
              <li>
                Talk to the neighbours about any experiences they’ve had with
                flooding
              </li>
            </ul>
          </div>
          <h2>Things to check on site</h2>
          <ul>
            <li>
              Water stains on external wall cladding, fencing and foundations.
              <div className="modalimg">
                <img src="/images/mdimage.jpg" alt="" />
                <img src="/images/mdimage.jpg" alt="" />
              </div>
            </li>
            <li>
              Cracks in the walls and at stress points, for example, doorways and
              windows.
              <div className="modalimg">
                <img src="/images/mdimage.jpg" alt="" />
              </div>
            </li>
            <li>Soggy skirting boards and crumbling interior linings.</li>
            <li>
              Sticking doors and windows and gaps around frames can indicate
              building movement.
            </li>
            <li>
              Visible water level lines near any waterways that flow through the
              property.
            </li>
            <li>Stains or buckling on the floors and walls.</li>
          </ul>
        </>
      )}
    </>
  )
}

export default ActionModal;
