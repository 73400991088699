import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { config } from "../../config";
import Loader from "../../shared/ui/components/loader";
import { generateFile } from "../../shared/utils/helper";
import './style.scss'

const DownloadPDF = () => {
    const [Error, setError] = useState<any>("");
    const [Download, setDownload] = useState<boolean>(false);
    const { reportId, authkey } = useParams<any>()

    //const { data: reportData, isLoading, isError } = getReportsDataByID(reportId);

    useEffect(() => {
        //if (reportData) {
            downloadReport()
        //} else if (isError) {
        //    setDownload(false);
        //}
    }, []);

    const downloadReport = () => {
        axios.get(`${config._API}/Reports/pdf?reportId=${reportId}`, {
            headers: {
                "X-Api-Key": authkey,
            }
        }).then(res => res.data).then((data: any) => {
            if (data) {
                generateFile(data.url, data.fileName);
                setDownload(true)
            }
        })
            .catch(err => {
                setError("Invalid Report Link")
            })
    }

    return (<div className="minmumHeightBox">
        <div>
            {Download ?
                <p style={{ color: 'green' }}>  Report file downloaded successfully  </p>
                : <Loader isLoading={true} />}
            <p style={{ color: 'red' }}>
                {Error && Error}
            </p>
        </div>
    </div>)

}

export default DownloadPDF

