import * as React from 'react';
import Assessment from './assessment';
import Rpbanner from './banner/index';
import './report.scss';
import RiskPreview from './report-preview';
import RiskReport from './risk-reports';
import SideBar from './sidebar';
import ReportModal from './modals';
import { getReportsDataByID } from '../../shared/utils/services/hooks/reports';
import { useSelector } from 'react-redux';
import Loader from '../../shared/ui/components/loader';
import PrintComponent from './print-report/print-component';
import RiskRating from '../rating/riskrating';

const ReportPageLoggedIn: React.FC<any> = () => {
  const componentRef = React.useRef(null);
  const [reportId, setReportId] = React.useState('');
  const [authKey, setAuthKey] = React.useState('');
  const { selectedReport }: any = useSelector<any>(
    (state: any) => state.reports
  );
  const { isAnonymousUser }: any = useSelector<any>((state: any) => state.user);
  const [modalType, setModalType] = React.useState<any>('');
  const [modal, setModal] = React.useState(false);
  const [riskNumber, setRiskNumber] = React.useState(0);
  const [scroll, setScroll] = React.useState('Summary');

  const { data, isLoading, isError } = getReportsDataByID(reportId, authKey);

  React.useEffect(() => {
    if (selectedReport && (selectedReport.id || selectedReport.reportId)) {
      if (isAnonymousUser) {
        setAuthKey(selectedReport.authKey);
      }
      setReportId(selectedReport.id || selectedReport.reportId);
    }
  }, [selectedReport]);

  function handleModal(
    value: boolean,
    modalType?: string,
    riskNumber?: number
  ) {
    setModal(value);
    setModalType(modalType);
    setRiskNumber(riskNumber ? riskNumber : 0);
  }
  return (
    <>
      {isLoading ? (
        <div className="minmumHeightBox">
          <Loader title="Loading Report..." isLoading={true} />
        </div>
      ) : isError ? (
        <div className="minmumHeightBox">
          <Loader title="Error fetching Data!" />
        </div>
      ) : (
        <>
          <div className="repor_page">
            <div className="wrap">
              <div className="row">
                <div className="side-nav md-helf-width">
                  <div className="stickysidebar">
                    <SideBar data={data} scroll={scroll} />
                  </div>
                </div>
                <div className="half-width-right">
                  <Rpbanner
                    showDownload={true}
                    componentRef={componentRef}
                    bannerData={data}
                    isDownloadDisabled={data && data[0]?.status !== 3}
                  />
                  {/* <Assessment data={data} /> */}
                  <RiskRating
                    ratingSummary={data?.[0]?.riskRatingSummary}
                    cityRiskSummary={data?.[0]?.cityRiskSummaryDetails}
                    showRatingButton={true}
                  />
                  <RiskReport reportData={data} />
                  <RiskPreview
                    handleModal={handleModal}
                    data={data}
                    setScroll={setScroll}
                  />
                  {modal && (
                    <ReportModal
                      type={modalType}
                      show={modal}
                      handleModal={handleModal}
                      data={data}
                      riskNumber={riskNumber}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="printabel-component">
            <PrintComponent componentRef={componentRef} data={data} />
          </div>
        </>
      )}
    </>
  );
};

export default ReportPageLoggedIn;
