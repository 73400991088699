import * as React from 'react';
import './signup.scss';
import { Link, useHistory } from 'react-router-dom';
import Lock from '../auth/lock';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../shared/ui/components/toast';
import { toast } from 'react-toastify';

import TestimonialClient from '../../assets/images/TestimonialClient.png';
import Button from '../../shared/ui/components/button';
import { validEmailRegex } from '../../shared/utils/constants';
import { saveIsAnonymousUser, saveGuestEmail } from '../../store/actions/user-actions';

const notifySuccess = () => toast.success('Signup successfully. We have  send you a verification link. Please verify your email!');
const notifyError = () => toast.error('Something went wrong. Please try again later!');

declare const window: any;

const SignUp: React.FC<any> = () => {
  const { reportImage, placeName }: any = useSelector<any>(
    (state: any) => state.common
  ); 

  const dispatch = useDispatch();
  const history = useHistory();


  const [guestEmail, setGuestEmail] = React.useState('');
  const [isInvalidEmail, setInvalidEmail] = React.useState(false);
  const [emptyEmail, setEmptyEmail] = React.useState(false);


  React.useEffect(() => {
    window.xhook.after(function (request: any, response: any) {
      if (request.url.match(/signup/)) {
        if (response.status === 200)
          notifySuccess()
        else
          notifyError()
      }
    });
  }, []);

  const handleLoginAsGuest = async (e: any) => {
    if (!guestEmail) {
      setEmptyEmail(true);
    } else if (!validEmailRegex.test(guestEmail)) {
      setInvalidEmail(true);
    } else {
      console.log('success login');
      dispatch(saveIsAnonymousUser(true));
      dispatch(saveGuestEmail(guestEmail));
      history.push('/success');
    }
    
  }

  const handleGuestEmailChange = (value: string) => {
    setGuestEmail(value);
    setEmptyEmail(false);
    setInvalidEmail(false);
  }

  return (
    <>
      <Toast />
      <div id="signup">
        <div className="wrap">
          <div className="innerwrap">
            <div className="login-inner">
              <h1>Buy report </h1>
              <div className="row">
                <div className="half-width">
                  <div className="sign-frm">
                    <div className="guestcontainer">
                      <div className='guestfield'>
                        <label>Purchase report as guest</label>
                        <input
                          className="guestemail"
                          placeholder="yours@example.com"
                          name="email"
                          value={guestEmail}
                          onChange={(e) => handleGuestEmailChange(e.target.value)}
                        />
                        {emptyEmail && <div className="guest-email-invalid-hint">Email can't be blank</div>}
                        {isInvalidEmail && <div className="guest-email-invalid-hint">Email is invalid</div>}
                      </div>

                      <Button
                        className="button-primary ml-10 guestbtn"
                        onClick={handleLoginAsGuest}
                      // disabled={!searchText}
                      >
                        CONTINUE AS GUEST
                      </Button>
                    </div>
                    <hr className="guest-separator" />
                    <h2> Sign up </h2>
                    <p>
                      Sign up to purchase and access your report. We’ll also
                      send you an email with your report.
                    </p>
                    <Lock />
                    <div className="loginfeild">
                      <div className="loginbottom">
                        <p>
                          Already have an account?{' '}
                          <Link to="/landcheck-login">Log in</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="half-width-img">
                  <div className="sign-img">
                    {reportImage ? (
                      <img src={reportImage} />
                    ) : (
                      <img src="/images/signup.jpg" />
                    )}
                    <div className="sign-img-contant">
                      <p>Purchase report</p>
                      <div className="b-img-contant">
                        {placeName ? (
                          <h3>{placeName.formatted_address}</h3>
                        ) : (
                          <h3>17 De Luen Street</h3>
                        )}
                        <p>$49</p>
                      </div>
                    </div>
                  </div>
                  <div className="sign-client">
                    <div className="client-simg">
                      <img className="client-img" src={TestimonialClient}/>
                    </div>
                    <p>
                      “Excellent report which was easy to read and helped me to make 
                      up my mind on the purchase of my first property. Never thought 
                      it would be so affordable to get a report of this nature.”
                    </p>
                    <span>Jack, Auckland </span>
                  </div>
                </div>
              </div>
            </div>
            <p className="sing-f">
              By clicking “Continue with Google/Microsoft/Email/” above, you
              acknowledge that you have read and understood, and agree to
              Landcheck’s <a href='https://www.landcheck.co.nz/terms-and-conditions'>Terms and Conditions</a> and Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
