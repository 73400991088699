
const initialState = {
  selectedReport:{}
};
// eslint-disable-next-line
export default function reportsReducer(
  state = initialState,
  // eslint-disable-next-line
  { type, payload }: any
) {
  switch (type) {
    case 'SAVE_SELECTED_REPORT': {
      return { ...state, selectedReport: payload };
    }
    default:
      return state;
  }
}
