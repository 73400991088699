import { useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const getCities = () => {
  const adminCallApi = adminUseAxios();
  return useQuery(['GET_CITIES'], () =>
    adminCallApi({
      method: 'GET',
      url: '/Cities',
    }).then((data: any) => (data ? data.lists : []))
  );
};
