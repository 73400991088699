import * as React from 'react';
import './index.scss';

const AboutYourReport: React.FC<any> = () => {
  return (
    <div>
      <div id="about">
            <h2>About your report</h2>

            <ul>
                <li>This report is prepared using data sourced from local councils, regional authorities and other third parties (Source Data). We do not undertake any site-specific investigation nor use any site-specific information in preparing reports.</li>
                <li>Depending on the extent of the Source Data and/or how long ago it was collected, this report may not present an accurate picture of the current natural hazards risks at the site.</li>
                <li>For these reasons this report should only be used as an initial site-screening tool. It is not intended to replace professional advice based on a site-specific investigation.</li>
                <li>This report is issued in accordance with the Landcheck <a href='https://www.landcheck.co.nz/terms-and-conditions'>terms and conditions</a>.</li>
            </ul>

          </div>
    </div>
  );
};

export default AboutYourReport;
