import React from 'react';
import { Modal } from 'react-bootstrap';

const MapModal: React.FC<any> = () => {
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Hazard map</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src="/images/reportbanner.jpg" />
      </Modal.Body>
    </div>
  );
};

export default MapModal;
