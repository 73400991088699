import { useMutation, useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const postRisks = (data: any,) => {
  const adminCallApi = adminUseAxios(); 
  return useMutation( () =>
        adminCallApi({
        method: 'POST',
        url: '/Risks',
        data: data,
      }).then((data: any) => data),

  );
};

export const postRisksSummary = (data: any, regionId: string) => {
  const adminCallApi = adminUseAxios(); 
  return useMutation( () =>
        adminCallApi({
        method: 'POST',
        url: `/Risks/summary?regionId=${regionId}`,
        data: data,
      }).then((data: any) => data),

  );
};
