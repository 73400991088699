const LogTable = () => <table className="table-wrapper">
  <thead>
    <tr
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: 12,
        textAlign: 'center',
        alignItems: 'center',
        width: '95%',
      }}
    >
      <th>Created On</th>
      <th>User ID</th>
      <th>Last Updated</th>
      <th>Version</th>
      <th>Ver Accepted</th>
      <th>Comments</th>
    </tr>
  </thead>
  <tbody>
    {tableData.map((item, index) => (
      <tr
        style={{
          width: '98%',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 4,
          paddingRight: 12,
          margin: 12,
        }}
      >
        <td>{item.createdOn}</td>
        <td>{item.userId}</td>
        <td>{item.lastUpdated}</td>
        <td>{item.version}</td>
        <td>{item.verAccepted}</td>
        <td>{item.comments}</td>
      </tr>
    ))}
  </tbody>
</table>

export default LogTable

const tableData = [
  {
    createdOn: 'Apr 29,2021 5:00 PM',
    userId: 'abc@gmail.com',
    lastUpdated: 'Apr 29,2021 5:00 PM',
    version: 31,
    verAccepted: 31,
    comments: 'New version updated',
  },
  {
    createdOn: 'Apr 29,2021 5:00 PM',
    userId: 'abc@gmail.com',
    lastUpdated: 'Apr 29,2021 5:00 PM',
    version: 31,
    verAccepted: 31,
    comments: 'New version updated',
  },
  {
    createdOn: 'Apr 29,2021 5:00 PM',
    userId: 'abc@gmail.com',
    lastUpdated: 'Apr 29,2021 5:00 PM',
    version: 31,
    verAccepted: 31,
    comments: 'New version updated',
  },
  {
    createdOn: 'Apr 29,2021 5:00 PM',
    userId: 'abc@gmail.com',
    lastUpdated: 'Apr 29,2021 5:00 PM',
    version: 31,
    verAccepted: 31,
    comments: 'New version updated',
  },
  {
    createdOn: 'Apr 29,2021 5:00 PM',
    userId: 'abc@gmail.com',
    lastUpdated: 'Apr 29,2021 5:00 PM',
    version: 31,
    verAccepted: 31,
    comments: 'New version updated',
  },
];