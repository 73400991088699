import { faSort, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

import './index.scss';

type Option = {
  label: string;
  name: string;
  version: string;
};
type Props = {
  className?: string;
  listItems: any;
  label: string;
  city: boolean;
  setSelectedValue: any;
  selectedValue: string;
  setSelectedId: any;
};

const DropDownItem: React.FC<any> = ({
  listItems,
  label,
  city,
  setSelectedValue,
  selectedValue,
  setSelectedId,
}) => {
  const onChangeDropDown = (e: any) => {
    setSelectedId(e.id);
    city ? setSelectedValue(e.name) : setSelectedValue(e.version);
  };
  return (
    <div id="menu-item-wrapper">
      <div className="menu-item">
        <label>{label}</label>

        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {selectedValue}
            <img src="/images/down_arrow.svg" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-item-wrapper">
            {listItems?.map((item: any, index: any) =>
              !city ? (
                <Dropdown.Item
                  onSelect={() => onChangeDropDown(item)}
                  className="dropdown-item"
                >
                  {item?.version}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onSelect={() => onChangeDropDown(item)}
                  className="dropdown-item"
                >
                  {item?.name}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default DropDownItem;
