export const riskRangeClassNames = [
    {
      className: "notassessed",
      key: "Undetermined"
    },
    {
      className: "notassessed",
      key: "Not assessed"
    },
    {
      className: "lowf",
      key: "Very Low"
    },
    {
      className: "lowf",
      key: "Low"
    },
    {
      className: "lowMf",
      key: "Unlikely"
    },
    {
      className: "lowMf",
      key: "Low-Med"
    },
    {
      className: "MediHf",
      key: "Medium"
    },
    {
      className: "MediHf",
      key: "Possible"
    },
    {
      className: "MedHf",
      key: "Med-High"
    },
    {
      className: "medlowf",
      key: "High"
    }, 
  ] 