import * as React from 'react';
import { getTermsAndCondition } from '../../shared/utils/services/hooks/terms';
import './index.scss';

const TermsAndConditions: React.FC<any> = () => {
  const [value, setValue] = React.useState<string>('');
  const {
    data,
    isLoading: getTermsIsLoading,
    isError: getTermsIsError,
  } = getTermsAndCondition();

  React.useEffect(() => {
    if (data) {
      setValue(data?.terms);
    }
  }, [data]);
  return (
    <div>
      <div id="Terms_condition">
        <div className="wrap">
          <div className="termspage">
            <h1>Terms and conditions</h1>
            <div
              className="termpera"
              dangerouslySetInnerHTML={{ __html: value }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
