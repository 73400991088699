import { useQuery } from 'react-query';
import useAxios from '../useAxios';

export const createAccountafterSignup = (createNewAccount: boolean, userData: any) => {
  const callApi = useAxios();
  return useQuery(
    ['ACCOUNT_CREATE_WITH_NAME'],
    () =>
      callApi({
        method: 'POST',
        url: `/Accounts`,
        data:JSON.stringify(userData),
      }).then((data: any) => (data ? data : [])),
    {
      enabled: Boolean(createNewAccount) && Boolean(userData),
      cacheTime:0
    }
  );
};
export const getAccount = (getAccountDetails: boolean, fetchUserData = true) => {
  const callApi = useAxios();
  return useQuery(
    ['ACCOUNT_GET'],
    () =>
      callApi({
        method: 'GET',
        url: `/Accounts`,
      }).then((data: any) => (data ? data : [])),
    {
      enabled: Boolean(getAccountDetails) && Boolean(fetchUserData),
    }
  );
};

export const emailUpdate = (id: boolean, userData: any) => {
  const callApi = useAxios();
  return useQuery(
    ['ACCOUNT_EMAIL_UPDATE'],
    () =>
      callApi({
        method: 'PUT',
        url: `/Accounts/ChangeEmail`,
        data:JSON.stringify(userData),
      }).then((data: any) => (data ? data : null)),
    {
      enabled: Boolean(id) && Boolean(userData),
      cacheTime:0
    }
  );
};
export const passwordUpdate = (id: boolean, userData: any) => {
  const callApi = useAxios();
  return useQuery(
    ['ACCOUNT_PASSWORD_UPDATE'],
    () =>
      callApi({
        method: 'PUT',
        url: `/Accounts/ChangePassword`,
        data:JSON.stringify(userData),
      }).then((data: any) => (data ? data : null)),
    {
      enabled: Boolean(id) && Boolean(userData),
      cacheTime:0
    }
  );
};
