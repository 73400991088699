import { useQuery } from 'react-query';
import adminUseAxios from '../adminUseAxios';

export const getVersion = (cityId: any, regionId: any) => {
  const adminCallApi = adminUseAxios();
  return useQuery(
    ['GET_VERSIONS'],
    () =>
      adminCallApi({
        method: 'GET',
        url: `${cityId}/${regionId}/Versions`,
      }).then((data: any) => (data ? data.lists : [])),
    {
      enabled: Boolean(cityId) && Boolean(regionId),
    }
  );
};
