import { useEffect, useState } from 'react';
import {
    modules2,
    modules3,
    modules4,
    modules5,
    QuillToolbar2,
    QuillToolbar3,
    QuillToolbar4,
    QuillToolbar5,
} from '../../../../common/EditorToolbar';
import RiskTakingQuestion from '../../ui/RiskTakingQuestion';
import './index.scss';

const SummaryRiskTabItem: React.FC<any> = (props) => {

    const [lowRisk, setLowRisk] = useState<string>('');
    const [lowMedRisk, setLowMedRisk] = useState<string>('');
    const [medHighRisk, setMedHighRisk] = useState<string>('');
    const [highRisk, setHighRisk] = useState<string>('');

    useEffect(() => {
        const { riskTypeData } = props;
        if (riskTypeData) {
            setLowRisk(riskTypeData.lowRatingDescription);
            setLowMedRisk(riskTypeData.lowMedRatingDescription);
            setMedHighRisk(riskTypeData.medHighRatingDescription);
            setHighRisk(riskTypeData.highRatingDescription);

        }
    }, [props.riskTypeData])


    const questions = [
        {
            name: 'Low',
            question: 'Low Risk',
            point: true,
            plainText: false,
            toolbar: <QuillToolbar2 />,
            modules: modules2,
            text: lowRisk,
            handleChange: (e: any) => {
                setLowRisk(e)
            },
            lowRatingDescription: lowRisk
        },
        {
            name: 'Low-Med',
            question: 'Low-Medium Risk',
            point: true,
            plainText: false,
            toolbar: <QuillToolbar3 />,
            modules: modules3,
            text: lowMedRisk,
            handleChange: (e: any) => {
                setLowMedRisk(e)
            },
            lowMedRatingDescription: lowMedRisk
        },
        {
            name: 'Med-High',
            question: 'Medium-High Risk',
            point: true,
            plainText: false,
            toolbar: <QuillToolbar4 />,
            modules: modules4,
            text: medHighRisk,
            handleChange: (e: any) => {
                setMedHighRisk(e)
            },
            medHighRatingDescription: medHighRisk
        },
        {
            name: 'High',
            question: 'High Risk',
            point: true,
            plainText: false,
            toolbar: <QuillToolbar5 />,
            modules: modules5,
            text: highRisk,
            handleChange: (e: any) => {
                setHighRisk(e)
            },
            highRatingDescription: highRisk
        },
    ];

    useEffect(() => {
        props.getRiskTabData(questions)
    }, [lowRisk, lowMedRisk, medHighRisk, highRisk])

    return (<div>

        <div className="content-container">
            {
                <RiskTakingQuestion question={questions} />
            }
        </div>
    </div>
    );

};

export default SummaryRiskTabItem;
