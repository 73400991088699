import * as React from 'react';
import FlushData from '../FlushData';
import './index.scss';

const About: React.FC<any> = () => {
  return (
    <div>
      <FlushData />
      <div id="Version">
      <div className="wrap">Build Version: {process.env.REACT_APP_BUILD_VERSION}</div>
      </div>
    </div>
  );
};

export default About;
