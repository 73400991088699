import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './index.scss';

const RiskRating: React.FC<any> = ({ ratingSummary, cityRiskSummary, showRatingButton }) => {
  const history = useHistory()

  function handleRoute(route: any) {
    history.push({
      pathname: route,
      state: cityRiskSummary
    });
  }

  return (
    <>
      {ratingSummary &&
        <div>
          <div className="impactrating">
            <div className="impactinner">
              <h2>The overall hazard impact rating of this property is</h2>

              <div className="impactinnersection">
                <div className={`impactinnercircle riskRating${ratingSummary?.riskRatingName}`}>
                  <p>{ratingSummary?.riskRating}</p>
                  {/* <div>{(ratingSummary?.riskRatingName + ' risk').toUpperCase()}</div> */}
                </div>
              </div>
              <p className='rating-desc'>
                This means the property is at  <strong>{ratingSummary?.riskRatingName} risk</strong> <br /> of future potential natural hazard impact.
              </p>

              {
                showRatingButton &&
                <Button className="button-secondary" onClick={() => handleRoute("/riskdefinitionspage")}>
                  What this hazard rating means
                </Button>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default RiskRating;
