import { Location, Geomatry } from '../../shared/utils/types';

// eslint-disable-next-line
export const saveLocation = (location: Location, geometry: Geomatry) => ({
  type: 'SAVE_LOCATION',
  payload: { location, geometry },
});

// eslint-disable-next-line
export const flushLocationSearch = () => ({
  type: 'SAVE_LOCATION',
  payload: {},
});
