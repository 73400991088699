const initialState = {
  token: '',
  accessToken: '',
  userInfo: {},
  isLoggedIn: false,
  authUser: {},
  isAnonymousUser: false,
  guestEmail: ''
};

export default function userReducer(
  state = initialState,
  // eslint-disable-next-line
  { type, payload }: any
): any {
  switch (type) {
    case 'SAVE_TOKEN': {
      return { ...state, token: payload };
    }
    case 'SAVE_USER_INFO': {
      return { ...state, userInfo: payload };
    }
    case 'SAVE_IS_LOGGED_IN': {
      return { ...state, isLoggedIn: payload };
    }
    case 'SAVE_ACCESS_TOKEN': {
      return { ...state, accessToken: payload };
    }
    case 'SAVE_AUTH_NAME': {
      return { ...state, authUser: payload };
    }
    case 'SAVE_IS_ANONYMOUS_USER': {
      return { ...state, isAnonymousUser: payload };
    }
    case 'SAVE_GUEST_EMAIL': {
      return { ...state, guestEmail: payload };
    }

    default:
      return state;
  }
}
