import * as React from 'react';
import FlushData from '../FlushData';

const Support: React.FC<any> = () => {
  return (
    <>
      <FlushData />
      <div>Support</div>
    </>
  );
};

export default Support;
