import { Button } from 'react-bootstrap';
import './search-bar.scss';
import { FormEvent } from 'react';
import React from 'react';
import { config } from '../../../config';
export const SearchBar: React.FC<any> = ({ isDisplay, setIsDisplay }) => {
  const [globalSearchVal, setGlobalSearchVal] = React.useState('');
  const onSearchSubmit = (submitEvt: FormEvent) => {
    submitEvt.preventDefault();
    submitEvt.stopPropagation();
    const url = config.landcheckWebApp + '?s=' + globalSearchVal;
    window.open(url, '_self');
  };
  return (
    <>
      <div
        id="search-outer"
        className={isDisplay ? 'global-search show' : 'global-search'}
      >
        <div id="search">
          <div className="container">
            <div id="search-box">
              <div className="inner-wrap">
                <div className="col span_12">
                  <form onSubmit={onSearchSubmit}>
                    <input
                      type="text"
                      name="search-landcheck"
                      id="search-landcheck"
                      aria-label="Search"
                      placeholder="Type what you're looking for"
                      onChange={(evt) => setGlobalSearchVal(evt.target.value)}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div id="close">
              <Button
                className="nav-search-btn"
                onClick={() => setIsDisplay(false)}
              >
                X
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
