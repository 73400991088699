import { FC } from "react";

interface IRiskInput {
    value: any;
    onTextChange: any;
    type?:any
}
const RiskInput: FC<IRiskInput> = ({
    value,
    onTextChange,
    type
}) => {
    return (
        <input
            className="min-text"
            value={value}
            onChange={onTextChange}
            type={type}
        />
    )

}
export default RiskInput