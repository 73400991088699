import * as React from 'react';
import './index.scss';
import Slider from 'react-slick';

import TestimonialClient from '../../../assets/images/TestimonialClient.png';


const settings = {
  dots: true,
  slidesToShow: 1,
  infinite: false,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        variableWidth: false,
      },
    },
  ],
};
const TestimonialsProperty: React.FC<any> = () => {
  return (
    <div id="testimonials-property">
      <div className="wrap">
        <div className="row">
          {/* Left content */}
          <div className="half-width">
            {/* <Slider {...settings}> */}
              <div>
                <h3>Why homebuyers love Landcheck</h3>
                <div className="hide_n">
                  <img src={TestimonialClient} alt="testimonial"/>
                </div>
                <p>
                  “Excellent report which was easy to read and helped me to make 
                      up my mind on the purchase of my first property. Never thought 
                      it would be so affordable to get a report of this nature.”
                </p>
                <div className="name">Jack, Auckland </div>
              </div>
            {/* </Slider> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsProperty;
