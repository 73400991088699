import * as React from 'react';
import TermsAndConditions from '../../components/TermsAndConditions';
import FlushData from '../FlushData';

const TermsAndCondition: React.FC<any> = () => {
  return (
    <div>
      <FlushData />
      <TermsAndConditions />
    </div>
  );
};

export default TermsAndCondition;
