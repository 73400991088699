// eslint-disable-next-line
const saveReportImage = (image: any) => ({
  type: 'SAVE_REPORT_IMAGE',
  payload: image,
});
// eslint-disable-next-line
const savePlaceName = (placeName?: any) => ({
  type: 'SAVE_PLACENAME',
  payload: placeName,
});
// eslint-disable-next-line
const savePropertyId = (propertyId?: any) => ({
  type: 'SAVE_PROPERTY_ID',
  payload: propertyId,
});
// eslint-disable-next-line
const flushReportImage = () => ({
  type: 'FLUSH_REPORT_PAGE',
  payload: '',
});
// eslint-disable-next-line
const saveReportLink = (link: any) => ({
  type: 'SAVE_REPORT_LINK',
  payload: link,
});

const saveSearchAddressInfo = (payload:any) => ({
  type: 'SAVE_SEARCH_ADD_INFO',
  payload: payload
}
)
export { saveReportImage, flushReportImage, savePlaceName, savePropertyId, saveReportLink, saveSearchAddressInfo };
