import * as React from 'react';
import { FC } from 'react';
import AboutYourReport from '../../components/about-your-report';
import RiskPreview from '../../components/view-report-logged-in/report-preview';
import RiskReport from '../../components/view-report-logged-in/risk-reports';
import Doc from './docServices';
import PdfContainer from './pdfcon';
import './download.scss'
import RiskRating from '../../components/rating/riskrating';
import RiskDefinitions from '../../components/risk-definitions';

const pageBreak = {
  'page-break-after': 'always'
} as React.CSSProperties;

const Download: FC<any> = ({ report }) => {
  const createPdf = (html: any) => Doc.createPdf(html);
  return (
    <div className="downloadpdfmain">
      <PdfContainer createPdf={createPdf} >
        <div id="divToPrint">
          <div className='riskRate'>
            <div className='risk-info'>
              <h1>Natural hazard summary  </h1>
              <p>
                Landcheck provides a clear, concise summary of natural hazards that
                could affect your selected property. Natural hazard experts collated
                the information across the region for ten natural hazards from various
                sources including regulatory authorities such as Councils.
                An algorithm is then applied
                to the data to establish a hazard impact rating for the property.
                To provide context to the assessment, Landcheck reviews each natural
                hazard on a suburb basis to provide comparison between the
                selected property and the typical risk exposure within the suburb.
              </p>
              <p>
                Landcheck makes natural hazard data accessible, easy
                to understand and saves time needed to collect and digest the
                information. Landcheck also provides recommendations in terms of next
                steps and possible solutions to mitigate the effects of natural
                hazard risk on the selected property.
              </p>
            </div>
            <div className="rp-banner" style={{ marginBottom: '1.5rem' }}>
              <img
                src={report ? report[0].coverImage : '/images/reportbanner.jpg'}
              />
            </div>
            <RiskRating ratingSummary={report?.[0]?.riskRatingSummary} cityRiskSummary={report?.[0]?.cityRiskSummaryDetails} />
            <div style={{ marginTop: 32 }}>
              <RiskReport reportData={report} />
            </div>

            <div style={pageBreak}> </div>
            <div style={pageBreak}>
              <div id='riskp'>
                <div className='wrap'>
                  <RiskDefinitions cityRiskSummary={report?.[0]?.cityRiskSummaryDetails} />
                </div>
              </div>
            </div>

            <div style={pageBreak}> </div>
          </div>
          <RiskPreview data={report} isPdf={true} />
          <div style={pageBreak}> </div>
          <div className="aboutReport">
            <AboutYourReport />
          </div>

        </div>
      </PdfContainer>
    </div>
  )
}

export default Download
