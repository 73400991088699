import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Table } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import './SearchedAddressReports.scss';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { fetchSearchAddressReports } from '../../shared/utils/services/hooks/search-add-reports';
import { downloadBlob } from '../../shared/utils/helper';

const SearchAddressReports: React.FC<any> = () => {
  const [address, setAddress] = useState('');
  const clientQuery = useQueryClient();
  const { data, isLoading, isError, refetch } =
    fetchSearchAddressReports(address);
  const initialValues = {
    Address: '',
  };

  const onAddSearch = async (values: any) => {
    setAddress(values.Address);
  };

  useEffect(() => {
    clientQuery.invalidateQueries('FETCH_ADDRESS_SEARCH_ISSUED_REPORTS');
  }, [(address as any)?.Address]);

  const handleDownloadinCSV = () => {
    const items = data.lists;
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row: any) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      ),
    ].join('\r\n');

    downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;');
  };

  return (
    <div className="wrap issued-reports" id="issued-reports">
      <div className="ir-form-container">
        <div className="form-wrapper">
          <Formik initialValues={initialValues} onSubmit={onAddSearch}>
            {({ handleSubmit, handleChange }) => (
              <Form className="ir-form" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} sm={6} md={8} lg={8}>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      className="ir-form-input"
                      name="Address"
                      type="text"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} sm={3} md={2} lg={2}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="button-primary search-btn"
                    >
                      Search
                    </Button>
                  </Col>
                  <Col xs={12} sm={3} md={2} lg={2}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="button-primary search-btn"
                      onClick={handleDownloadinCSV}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <h3 className="heading">Search History</h3>
      <div>
        {data?.lists?.length > 0 && (
          <Table className="search-add-table" striped bordered hover>
            <thead>
              <tr>
                <th>Search Address</th>
                <th className="date-col">Search Date</th>
                <th className="action-col">Action</th>
                <th className="action-col">User</th>
              </tr>
            </thead>
            <tbody>
              {data?.lists?.map(
                ({ searchDate, emailId, address, stage, id }: any) => (
                  <tr key={id}>
                    <td>{address}</td>
                    <td>{new Date(searchDate).toDateString()}</td>
                    <td>{stage}</td>
                    <td>{emailId}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default SearchAddressReports;
