import * as React from 'react';
import Button from '../../../shared/ui/components/button/index';

const RiskReport: React.FC<any> = () => {
  return (
    <>
      <div id="riskRp">
        <div className="wrap">
          <h2>Hazard included in report</h2>
          <Button className="">
            <img src="/images/lock.svg" />
            Unlock full version of your report to see each hazard level and
            details
          </Button>
          <div className="row">
            <div className="risk-report-cards">
              <div>
                <p>Flooding</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Severe Wind</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Liquefaction</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Volcanic activity</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Tsunami</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Active faults</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Earthquake</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Coastal inundation</p>
              </div>
            </div>
            <div className="risk-report-cards">
              <div>
                <p>Coastal erosion</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskReport;
