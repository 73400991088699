import {
  Appearance,
  loadStripe,
  StripeCardCvcElementOptions,
  StripeCardElementOptions,
  StripeElementClasses,
} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import './style.scss';
import Button from '../../../ui/components/button';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { flushReportImage } from '../../../../store/actions/common-actions';
import { useState } from 'react';
import { config } from '../../../../config';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import TermsAndConditionsModal from '../../../../components/view-report-logged-in/modals/terms-conditions-modal';
import { UserSearchAddressID } from '../../../utils/services/hooks/user';

const notifyError = () =>
  toast.error('Something went wrong. Please try again!');

const CheckoutForm: React.FC<any> = ({ setLoadingReport, setPaymentId }) => {
  const [saveReport, setSaveReport] = useState(false);
  const [buyNowTouched, setBuyNowTouched] = useState(false);
  const {location, common} : any = useSelector<any>((state) => state)
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { isSuccess: selectedSearchAddSuc } = UserSearchAddressID(location?.location?.location?.formatted_address, common?.searchAddress?.addressSearchId, 'Paid', buyNowTouched)

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    if (stripe && CardElement && CardNumberElement) {
      const number: any = elements.getElement(CardNumberElement);
      const cvc: any = elements.getElement(CardCvcElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: number,
      });
      if (error) {
        notifyError();
      } else {
        setPaymentId(paymentMethod ? paymentMethod.id : '');
        setBuyNowTouched(true);
        setSaveReport(true);
        setLoadingReport(true);
      }
    }
  };

  const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: {
      base: {
        iconColor: 'rgb(240, 57, 122)',
        color: '#151147',
        fontSize: '16px',
        '::placeholder': {
          color: '#151147',
        },
      },
      complete: {},
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#151147',
        },
      },
    },
  };

  const classes: StripeElementClasses = {
    base: 'base',
  };

  const options: StripeCardCvcElementOptions = {
    classes: classes,
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>Name on card</label>
        <input name="name" type="text" placeholder="N M Askable" required />

        <label> Card number </label>
        <div className={'paymentinput'}>
          <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div className="paymentsection">
          <div className={'paymentsectionname'}>
            <label>Expiry date (MM/YY)</label>
            <div className={'paymentinput'}>
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
          <div className={'paymentsectionname'}>
            <label>CVC</label>
            <div className={'paymentinput'}>
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>
        <div id='tccontainer'>
          <input type="checkbox" id='tandc' name="tandc" value="true" required />
          <label htmlFor='tandc'>I accept the &nbsp;
            <Link to="#" onClick={() => setModal(true)}>
              Landcheck Terms and Conditions
            </Link>
          </label>
        </div>
        <Button type="submit" disabled={!stripe || !elements} className="sbmbtn">
          Make payment
        </Button>
        <Button
          type="button"
          onClick={() => {
            dispatch(flushReportImage());
            history.push('/view-report');
          }}
          className="canbtn"
        >
          Cancel
        </Button>
      </form>
      <Modal show={modal} onHide={() => setModal(false)}>
        <TermsAndConditionsModal />
      </Modal>
    </>
  );
};

// const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
const stripePromise = loadStripe(config.stripKey!);

const StripCard: React.FC<any> = ({
  setLoadingReport,
  placeName,
  reportImage,
  setPaymentId,
}) => {
  const appearance: Appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    },
  };

  return (
    <>
      <Elements stripe={stripePromise} options={{ appearance }}>
        <CheckoutForm
          setPaymentId={setPaymentId}
          setLoadingReport={setLoadingReport}
          placeName={placeName}
          reportImage={reportImage}
        />
      </Elements>
    </>
  );
};
export default StripCard;
