import { useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { config } from '../../../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
/**
 * Returns a function that can be used to call an API.
 * This function wraps the axios instance.
 */

export default () => {
  const history = useHistory();
  const { token }: any = useSelector<any>((state: any) => state.user);
  const callApi = useCallback(async ({ headers, ...rest }) => {
    try {
     
      const { data } = await axios({
        baseURL: config._API,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        ...rest,
        validateStatus: (status) => status >= 200 && status <= 299,
      }); 
      return data;
    } catch (err: any) {
      console.log(err.response);
      if (err.response && err.response.data.message) {
        toast.error(err.response.data.message);
      }
      if (err && err.response && err.response.status === 401) {
        history.push('/landcheck-login');
      }
      if (err && err.response && err.response.status === 400) {
        if (err.response.data && err.response.data.errors.NewPassword) {
          toast.error(err.response.data.errors.NewPassword[0]);
        }
        if (err.response.data && err.response.data.errors.NewEmail) {
          toast.error(err.response.data.errors.NewEmail[0]);
        }
        if (err.response.data && err.response.data.errors.ExistingEmail) {
          toast.error(err.response.data.errors.ExistingEmail[0]);
        }
        if (err.response.data && err.response.data.errors.Email) {
          toast.error(err.response.data.errors.Email[0]);
        }
        
      }
      if (err && err.response && err.response.status === 404) {
        if (err.response.data && err.response.data.detail) {
          toast.error(err.response.data.detail);
        }
      }
      throw err;
    }
  }, []);

  return callApi;
};
