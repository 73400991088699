import * as React from 'react';
import ForgotPage from '../../components/Forgot-password';
const ForgotPassword: React.FC<any> = () => {
  return (
    <div>
      <ForgotPage />
    </div>
  );
};

export default ForgotPassword;
