import React, { useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveAccessToken,
  saveAuthUserName,
  saveGuestEmail,
  saveIsAnonymousUser,
  saveIsLoggedIn,
  saveToken,
} from '../../store/actions/user-actions';
import { AUTH_CONFIG, lock } from '../../shared/utils/helper';

const Lock: React.FC<any> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isReportPage }: any = useSelector<any>((state: any) => state.common);
  const { isLoggedIn, userInfo }: any = useSelector<any>(
    (state: any) => state.user
  );

  useEffect(() => {
    if (document && document.getElementById(AUTH_CONFIG.container)) {
      loginFn();
    }
    return () => {
      lock.hide();
    };
  }, []);

  const loginFn = () => {
    lock.on('authenticated', (authResult: any) => { 
      dispatch(saveAccessToken(authResult.accessToken));
      dispatch(saveToken(authResult.idToken));
      const nickName =
        authResult && authResult.idTokenPayload
          ? authResult.idTokenPayload.nickname
          : '';
      if (nickName) {
        const useData = {
          firstName: nickName.split(' ')[0],
          lastName: nickName.split(' ')[1] || '',
        };
        dispatch(saveAuthUserName(useData));
      }
      dispatch(saveIsLoggedIn(true));
      dispatch(saveIsAnonymousUser(false));
      dispatch(saveGuestEmail(''));
      history.push('/success');
    });

    lock.on('authorization_error', (authError: any) => {
      if (authError.error === 'unauthorized') {
        history.push('/verify-email')
      }
    })

    if (!/access_token|id_token|error/.test(location.hash)) {
      const initialScreen = location.pathname.startsWith('/forgot-password')
        ? 'forgotPassword'
        : location.pathname.startsWith('/landcheck-login')
        ? 'login'
        : 'signUp';
      lock.show({
        allowSignUp: location.pathname.startsWith('/landcheck-login') ? false : true,
        allowLogin: location.pathname.startsWith('/signup') ? false : true,
        allowForgotPassword: location.pathname.startsWith('/forgot-password')
          ? true
          : false,
        initialScreen: initialScreen,
      });
    }

    const emailLabel = '<label class="labeltag" >Email</label>';
    const passwordLabel = '<label class="labeltag" >Password</label>';

    lock.on('signin ready', () => {
      document.querySelectorAll('.labeltag').forEach(function (a) {
        a.remove();
      });
      const email = document.getElementsByClassName(
        'auth0-lock-input-email'
      )[0];
      const password = document.getElementsByClassName(
        'auth0-lock-input-password'
      )[0];
      email.insertAdjacentHTML('afterbegin', emailLabel);
      password.insertAdjacentHTML('afterbegin', passwordLabel);
    });
    lock.on('forgot_password ready', () => {
      document.querySelectorAll('.labeltag').forEach(function (a) {
        a.remove();
      });
      const email = document.getElementsByClassName(
        'auth0-lock-input-email'
      )[0];
      email.insertAdjacentHTML('afterbegin', emailLabel);
    });
    lock.on('signup ready', () => {
      document.querySelectorAll('.labeltag').forEach(function (a) {
        a.remove();
      });
      const email = document.getElementsByClassName(
        'auth0-lock-input-email'
      )[0];
      const password = document.getElementsByClassName(
        'auth0-lock-input-password'
      )[0];
      email.insertAdjacentHTML('afterbegin', emailLabel);
      password.insertAdjacentHTML('afterbegin', passwordLabel);
    });
  };

  return !isLoggedIn ? (
    <div style={{ position: 'relative' }}>
      <div id={AUTH_CONFIG.container} />
    </div>
  ) : (
    <Redirect to="/success" />
  );
};
export default Lock;
