import axios from "axios";
import Download from "./dowload";
import Loader from "../../shared/ui/components/loader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { config } from "../../config";
import './style.scss'

const WithOutHeaderReport = () => {
  const [report, setReport] = useState<any>(null);
  const [Error, setError] = useState<any>("");
  const { reportId, authkey } = useParams<any>()

  const downloadReport = () => {
    axios.get(`${config._API}/Reports/details?reportId=${reportId}`, {
      headers: {
        "X-Api-Key": authkey
      }
    }).then(res => res.data).then((data: any) => {
      if (data) {
        setReport(data.lists)
        console.log(data.lists)
      }
    })
      .catch(err => {
        setError("Invalid Report Link")
      })
  }

  useEffect(() => {
    downloadReport()
  }, [])

  if (report === null) {
    return (<div className="minmumHeightBox">
      <div>
        <Loader isLoading={true} />
        <p style={{ color: 'red' }}>
          {Error && Error}
        </p>
      </div>
    </div>)
  }
  return (
    <Download report={report} />
  )

}

export default WithOutHeaderReport
