import Header from '../header';
import Footer from '../footer';
import { Container } from 'react-bootstrap';
import { toggleMenuDropDown } from '../../store/actions/screen-action';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  saveAccessToken,
  saveAuthUserName,
  saveGuestEmail,
  saveIsAnonymousUser,
  saveIsLoggedIn,
  saveToken,
  saveUserData,
} from '../../store/actions/user-actions';
import { lock } from '../../shared/utils/helper';
import Toast from '../../shared/ui/components/toast';
import { getAccount } from '../../shared/utils/services/hooks/account';

const Layout: React.FC<any> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { menu }: any = useSelector<any>((state: any) => state.screen);
  const { accessToken, userInfo, isAnonymousUser }: any = useSelector<any>(
    (state: any) => state.user
  );
  const [fetchUserData, setFetchUSerData] = useState<boolean>(false);
  const [autUserData, setAuthUserData] = useState<any>(null);
  const {
    data: getAccounTData,
    isLoading: getAccounbtLoading,
    isError: getAccountError,
  } = getAccount(autUserData, fetchUserData && !isAnonymousUser);
  useEffect(() => {
    if (accessToken) {
      checkIfUserLoggedIn();
    } else {
      dispatch(saveIsLoggedIn(false));
    }
  }, [history, accessToken]);

  useEffect(() => {
    if (getAccounTData) {
      if (getAccounTData.user) dispatch(saveUserData(getAccounTData));
      dispatch(saveIsAnonymousUser(false));
      dispatch(saveGuestEmail(''));
    }
  }, [getAccounTData]);

  const checkIfUserLoggedIn = () => {
    lock.getUserInfo(accessToken, function (error: any, profile: any) {
      if (!error && profile) {
        if (!userInfo) {
          const useData = {
            firstName: profile.nickname.split(' ')[0],
            lastName: profile.nickname.split(' ')[1] || '',
          };
          setAuthUserData(useData);
          setFetchUSerData(true);
        }
        dispatch(saveIsLoggedIn(true));
        dispatch(saveIsAnonymousUser(false));
        dispatch(saveGuestEmail(''));
      } else {
        dispatch(saveIsLoggedIn(false));
        dispatch(saveToken(''));
        dispatch(saveAccessToken(''));
      }
    });
  };

  useEffect(() => {
    lock.on('authenticated', (authResult: any) => {
      dispatch(saveAccessToken(authResult.accessToken));
      dispatch(saveToken(authResult.idToken));
      const nickName =
        authResult && authResult.idTokenPayload
          ? authResult.idTokenPayload.nickname
          : '';
      if (nickName) {
        const useData = {
          firstName: nickName.split(' ')[0],
          lastName: nickName.split(' ')[1] || '',
        };
        dispatch(saveAuthUserName(useData));
      }
      dispatch(saveIsLoggedIn(true));
      dispatch(saveIsAnonymousUser(false));
      dispatch(saveGuestEmail(''));
      history.push('/success');
    });
  }, []);
  const path: any = history.location.pathname;

  const routesWithoutHeaderAndFooter = ['/reports/', '/suburb-rating'];
  const isHeaderFooterDisplayed = routesWithoutHeaderAndFooter.every(
    (route) => !path.includes(route)
  );

  return (
    <Container onClick={() => menu && dispatch(toggleMenuDropDown(false))}>
      <div className="layout">
        <Toast />
        {isHeaderFooterDisplayed && <Header />}
        <div style={{paddingTop: isHeaderFooterDisplayed ? '4.5rem' : '0' }}>
          {children}
        </div>
        {isHeaderFooterDisplayed && <Footer />}
      </div>
    </Container>
  );
};

export default Layout;
