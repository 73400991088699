import Auth0Lock from 'auth0-lock';
import axios from 'axios';
import { config } from '../../../config';
import { googleMapAPiKey, locationCovered } from '../constants';
import { toast } from 'react-toastify';

export const AUTH_CONFIG = {
  domain: config.auth0_domain,
  clientId: config.auth0_clientId,
  container: 'auth0-login-container',
};
export const getToken = () => {
  return '';
};

export const getLatLong = async (address: any) => {
  try {
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleMapAPiKey}`
    );
    const result = res.data['results'][0]['geometry']['location'];
    return result;
  } catch (err) {
    console.log('error', err);
    throw err;
  }
};

export const lock = new Auth0Lock(AUTH_CONFIG.clientId!, AUTH_CONFIG.domain!, {
  loginAfterSignUp: true,
  auth: {
    responseType: 'token id_token',
    sso: true,
    redirect: true,
  },
  container: AUTH_CONFIG.container,
});

export const getCityNameFromAddress = (address: string) => {
  let cityName = '';
  locationCovered.map((item, index) => {
    if (address.indexOf(item) >= 0) {
      cityName = item;
    }
  });

  return cityName;
};

export const generateFile = (url: string, fileName: string) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
  })
  .catch(() => {
    toast.error(
      'Something went wrong while downloading. Please try again later!'
    );
  });
};

export function downloadBlob(content: any, filename: any, contentType: any) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}
