import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
// import NotAUthorized from './not-autorized';
const ProtectRoute: React.FC<any> = ({ children }) => {
  const { isLoggedIn, isAnonymousUser }: any = useSelector<any>((state: any) => state.user);
  return <>{isLoggedIn || isAnonymousUser ? children : <Redirect to="/landcheck-login" />}</>;
};

export default ProtectRoute;
