import { Dropdown } from 'react-bootstrap';

const version = [
  { label: 'Ver: 1.0', value: '1.0' },
  { label: 'Ver: 2.0', value: '2.0' },
  { label: 'Ver: 3.0', value: '3.0' },
  { label: 'Ver: 4.0', value: '4.0' },
  { label: 'Ver: 5.0', value: '5.0' },
  { label: 'Ver: 6.0', value: '6.0' },
];

const VersionDropdown = () => {
  return (
    <div className="version-first">
      <span className="add-title">+ Add New Version</span>
      <Dropdown id="dropdown-wrapper">
        <Dropdown.Toggle id="dropdown-basic">
          Ver:{version[0].value}
          <img src="/images/downarrow.svg" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-item-wrapper">
          {version?.map((item, index) => (
            <Dropdown.Item className="dropdown-item">
              {item.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default VersionDropdown