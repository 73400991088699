import * as React from 'react';
import Setting from '../../components/setting';
import FlushData from '../FlushData';
import ProtectRoute from '../../shared/wrapper';

const SettingPage: React.FC<any> = () => {
  return (
    <div>
      <ProtectRoute>
        <FlushData />
        <Setting />
      </ProtectRoute>
    </div>
  );
};

export default SettingPage;
