import * as React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../shared/ui/components/loader';
import useAxios from '../../shared/utils/services/useAxios';
import { saveSelectedReport } from '../../store/actions/reports-actions';
import './reportspage.scss';

const ReportsPage: React.FC<any> = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location: any = useLocation();
  const isNewReport = location?.state?.isNewReport;

  const [refetchInterval, setRefetchInterval] = useState(5000);
  const [initialReportCount, setInitialReportsCount] = useState(0);
  const [timer, setTimer] = useState(null);

  const setApiTimer = () => {
    const timer = setTimeout(() => {
      setRefetchInterval(0);
      clearTimeout(timer);
    }, 180 * 1000)
    setTimer(timer as any)
  }

  const getCustomerReports = () => {
    const callApi = useAxios();
    return useQuery(['USER_REPORTS'],
      async () => {
        const data: any = await callApi({
          method: 'GET',
          url: '/Reports/AccountReports',
        })
        if ((!initialReportCount || initialReportCount === data?.lists?.length) && isNewReport) {
          setInitialReportsCount(data?.lists?.length);
          if (!timer) {
            setApiTimer();
          }
        } else {
          setRefetchInterval(0);
        }
        return data ? data.lists : [];
      },
      {
        refetchInterval: refetchInterval
      }
    );
  };

  const { data, isLoading, isError } = getCustomerReports();
  const handleReportClick = (item: any) => {
    dispatch(saveSelectedReport(item))
    history.push('/view-report')
  }

  const Loading = () => {

    const dis = () => (<span>We are loading your report, it may take up to 5 minutes</span>)

    return (
      <div className="loaderCenter">
        <Loader
          isLoading={isNewReport && !initialReportCount}
          title={' '}
          subTitle={'Loading report...'}
          description={dis()}
        >
        </Loader>
      </div>
    )

  }

  return (
    <>
      {isNewReport && !initialReportCount
        ? (
          <Loading />
        ) : (
          <div id="reportpage" >
            <div className="wrap ">
              <div className="innerwrap ">
                <h2>My Reports </h2>
                {isLoading ? (
                  <div className="minmumHeightBox">
                    <Loader title=" " isLoading={isLoading} />
                  </div>
                ) : isError ? (
                  <div className="minmumHeightBox">
                    <Loader title="Error fetching Data!" />
                  </div>
                ) : (
                  <div>
                    {data.length ?
                      (<div className="reportpage-inner">
                        {data?.map((item: any, index: number) => (
                          <>
                            <div onClick={() => handleReportClick(item)} key={`reprots-${index}`} className="rep-main">
                              <p>{item.streetAddress}</p>
                              <span>
                                View
                                <img src="/images/leftarrow.svg" alt="icon" />
                              </span>
                            </div>
                            <div className="divder"></div>
                          </>
                        ))}
                      </div>) : (
                        <div className="reportNotFoundBox">
                          <p className='not-found'>No reports found, please <a href="/">search</a> for an address</p>
                        </div>
                      )
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default ReportsPage;
