import { Location } from '../../shared/utils/types';

export interface ILocation {
  location: Location;
}

const initialState = {
  location: {},
};

export default function locationReducer(
  state = initialState,
  // eslint-disable-next-line
  { type, payload }: any
): any {
  switch (type) {
    case 'SAVE_LOCATION': {
      return { ...state, location: payload };
    }
    default:
      return state;
  }
}
