import React, { FC } from "react"
import CustomModal from "../../../../common/Modal"
import LogTable from "../LogTable"

interface Ilog {
  handleClose: React.MouseEventHandler<HTMLDivElement>
  show: boolean
}
const Logs: FC<Ilog> = ({
  handleClose,
  show
}) => {

  return (
    <CustomModal
      modalStyle={{ width: '90%', padding: 16, height: '80vh' }}
      show={show}
      onClose={handleClose}
      backdropStyle={{ color: '#000' }}
    >
      <div className="modal-header">
        <span>Logs</span>
        <div className="cross-icon" onClick={handleClose}>
          <img
            src="/images/cross.svg"
            style={{ width: 18, height: 18 }}
          />
        </div>
      </div>

      <LogTable />
    </CustomModal>
  )
}

export default Logs