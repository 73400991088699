import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { config } from '../../config';
import Loader from '../../shared/ui/components/loader';
import './style.scss';
import wave from '../../assets/images/Contours.png';

const ReportHome = () => {
  const [report, setReport] = useState<any>(null);
  const [Error, setError] = useState<any>('');
  const { reportId, authkey } = useParams<any>();

  const downloadReport = () => {
    axios
      .get(`${config._API}/Reports/details?reportId=${reportId}`, {
        headers: {
          'X-Api-Key': authkey,
        },
      })
      .then((res) => res.data)
      .then((data: any) => {
        if (data) {
          setReport(data.lists);
          console.log(data.lists);
        }
      })
      .catch((err) => {
        setError('Invalid Report Link');
      });
  };

  useEffect(() => {
    downloadReport();
  }, []);

  if (report === null) {
    return (
      <div className="minmumHeightBox">
        <div>
          <Loader isLoading={true} />
          <p style={{ color: 'red' }}>{Error && Error}</p>
        </div>
      </div>
    );
  }
  return (
    <div
      className="page pagehight"
      style={{
        height: '100vh',
        maxWidth: 720,
        padding: 10,
        margin: '0 auto',
        position: 'relative',
      }}
    >
      <div className="page-index-header">
        <p>
          Residential Property <br />
          Review
        </p>
      </div>
      <div className="page-index-footer">
        <div className="footerpdf">
          <div className="pdffooterleft">
            <img src="/images/footrpdf.svg" />
          </div>
          <div className="pdffooterright">
            <p>
              © Copyright 2023 Landcheck Ltd. <br /> All rights reserved.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <img
          src={wave}
          alt="wave"
          style={{
            width: 600,
            height: 452,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
        <div className="reporttitlepdf">
          <div className="pdfmrg-1">
            <h2
              className="report-title1"
              style={{ fontSize: 40, fontWeight: 700, color: '#343473' }}
            >
              {report && report[0] && report[0].address.streetAddress}
            </h2>
            <h3 style={{ fontSize: 32, fontWeight: 500, color: '#343473' }}>
              {report && report[0] && report[0].address.city}
            </h3>
            <p>
              <strong>Report date:</strong>{' '}
              {report && report[0] && report[0].assessmentDate}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHome;
